import "react-phone-input-2/lib/style.css";

import { Checkbox, Col, Form, Input, Row, Select } from "antd";
import { useEffect, useState } from "react";

import { BrazilianStates } from "../../../enums/brazilianStates";

const Coren = ({ setData, setEmpty, defaultData }) => {
  const [formValues, setFormValues] = useState({
    coren_number: defaultData.coren_number || "",
    coren_state: defaultData.coren_state || "",
    coren_status: defaultData.coren_status || "",
    coren_license_issue_date: defaultData.coren_license_issue_date || "",
    coren_revoked: defaultData.coren_revoked || false,
    coren_license_expiration_date: defaultData.coren_license_expiration_date || "",
    coren_revoked_reason: defaultData.coren_revoked_reason || "",
  });

  useEffect(() => {
    const hasEmpty =
      formValues?.coren_number?.length > 0
        ? formValues.coren_number === "" ||
          formValues.coren_state === "" ||
          formValues.coren_status === "" ||
          formValues.coren_license_issue_date === "" ||
          formValues.coren_license_expiration_date === "" ||
          formValues.coren_revoked_reason === "" 
        : false;

    setEmpty(hasEmpty);
    setData(formValues);
  }, [formValues, setData, setEmpty]);

  const UFOptions = BrazilianStates.states.map((item) => (
    <Select.Option key={item.uf}>{item.name}</Select.Option>
  ));

  return (
    <>
      <Row justify="space-between" gutter={{ sm: 16, xs: 10 }}>
        <Col xs={24} sm={12}>
          <Form.Item
            label="Número do Coren"
            name="coren_number"
            initialValue={formValues.coren_number}
            onChange={(e) =>
              setFormValues({ ...formValues, coren_number: e.target.value })
            }
            rules={[
              {
                required: formValues?.coren_number?.length > 0,
                message: "Campo obrigatorio!",
              },
            ]}
          >
            <Input size="small"/>
          </Form.Item>
        </Col>
        <Col xs={24} sm={12}>
          <Form.Item
            label="Estado do Coren "
            name="coren_state"
            initialValue={formValues.coren_state}
            rules={[
              {
                required: formValues?.coren_number?.length > 0,
                message: "Campo obrigatorio!",
              },
            ]}
          >
            <Select
              showSearch
              allowClear
              size="small"
              placeholder="Estado do Coren"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children
                  .toLowerCase()
                  .normalize("NFD")
                  .replace(/[\u0300-\u036f]/g, "")
                  .indexOf(
                    input
                      .toLowerCase()
                      .normalize("NFD")
                      .replace(/[\u0300-\u036f]/g, "")
                  ) >= 0
              }
              onChange={(value) =>
                setFormValues({
                  ...formValues,
                  coren_state: value,
                })
              }
            >
              {UFOptions}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row justify="space-between" gutter={{ sm: 16, xs: 10 }}>
        <Col xs={24} sm={12}>
          <Form.Item
            label="Status do Coren"
            name="coren_status"
            initialValue={formValues.coren_status}
            rules={[
              {
                required: formValues?.coren_number?.length > 0,
                message: "Campo obrigatorio!",
              },
            ]}
          >
            <Select
              showSearch
              size="small"
              allowClear
              placeholder="Status do Coren"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
              onChange={(value) =>
                setFormValues({
                  ...formValues,
                  coren_status: value,
                })
              }
            >
              <Select.Option key="ENA">Ativo</Select.Option>
              <Select.Option key="CAN">Cancelado</Select.Option>
              <Select.Option key="REV">Revogado</Select.Option>
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} sm={12}>
          <Form.Item
            label="Data da Emissão do Coren"
            name="coren_license_issue_date"
            initialValue={formValues.coren_license_issue_date}
            help="Aproximadamente"
            onChange={(e) =>
              setFormValues({
                ...formValues,
                coren_license_issue_date: e.target.value,
              })
            }
            rules={[
              {
                required: formValues?.coren_number?.length > 0,
                message: "Campo obrigatorio!",
              },
            ]}
          >
            <Input type="date" size="small"/>
          </Form.Item>
        </Col>
      </Row>

      <Row justify="space-between" gutter={{ sm: 16, xs: 10 }}>
        <Col xs={24} sm={12}>
          <Form.Item>
            <Checkbox
              onChange={(e) =>
                setFormValues({
                  ...formValues,
                  coren_revoked: e.target.checked,
                })
              }
            >
              Já foi revogada?
            </Checkbox>
          </Form.Item>
        </Col>
      </Row>

      { formValues?.coren_revoked && (
      <Row justify="space-between" gutter={{ sm: 16, xs: 10 }}>
        <Col xs={24} sm={12}>
          <Form.Item
            label="Data de Revogação do Coren" // Add label
            name="coren_license_expiration_date" // Add name
            initialValue={formValues.coren_license_expiration_date}
            help="Aproximadamente"
            onChange={(e) =>
              setFormValues({
                ...formValues,
                coren_license_expiration_date: e.target.value,
              })
            }
            rules={[
              {
                required: formValues?.coren_number?.length > 0,
                message: "Campo obrigatório!",
              },
            ]}
          >
            <Input type="date" size="small" />
          </Form.Item>


          
        </Col>

        <Col xs={24} sm={12}>
            <Form.Item
              label="Motivo da Revogação"
              name="coren_revoked_reason"
              initialValue={formValues.coren_revoked_reason}
              onChange={(e) =>
                setFormValues({
                  ...formValues,
                  coren_revoked_reason: e.target.value,
                })
              }
            >
              <Input size="small" />
            </Form.Item>
          </Col>
      </Row>
      )}
    </>
  );
};

export default Coren;
