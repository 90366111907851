/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import Wrapper from "../../components/layout";
import styled from "./styled.module.scss";
import { useRest } from "../../services/http";
import { Popconfirm, message, Table, Card } from "antd";
import { Link } from "react-router-dom";
import { useAuth } from "../../providers/auth";
import { Permissions, hasPermission } from "../../router/permissions";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import { showEmbededHTML } from "../../util/ckeditor";

const ListInstructions = () => {
  const { get: instructionsList, loading, data, status } = useRest();

  const { user } = useAuth();

  const {
    remove: removeInstruction,
    status: removeStatus,
    message: removeMessage,
  } = useRest();

  const [tablePagination, setTablePagination] = useState({
    current: 1,
    pageSize: 15,
  });

  useEffect(() => {
    instructionsList("instructionsList", { page: tablePagination.current });
  }, [tablePagination?.current]);

  useEffect(() => {
    if (data && status.success) {
      setTablePagination({
        ...tablePagination,
        pageSize: data?.per_page,
        total: data?.total,
      });
    }
  }, [data]);

  const confirm = (id) => {
    removeInstruction("removeInstruction", { id: id });
  };

  const handleTableChange = (pagination) => {
    setTablePagination({ ...tablePagination, current: pagination.current });
  };

  useEffect(() => {
    if (removeStatus.success) {
      instructionsList("instructionsList", { page: tablePagination.current });
      message.success(removeMessage);
    }
    if (removeStatus.error) {
      message.error(removeMessage);
    }
  }, [removeStatus]);

  const columns = [
    {
      title: "Titulo",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Ações",
      dataIndex: "",
      render: (record) => (
        <>
          {hasPermission(user, Permissions.cadastrar_instrucoes) && (
            <Link to={"/view-instruction/" + record.id} className={styled.icon}>
              <i className="ri-edit-fill" title="Editar Instrução" />
            </Link>
          )}

          {hasPermission(user, Permissions.remover_instrucoes) && (
            <Popconfirm
              title={
                "Tem certeza que deseja deletar a instrução?"
              }
              onConfirm={() => confirm(record.id)}
              okText="Confirmar"
              cancelText="Cancelar"
            >
              <a className={styled.icon}>
                <i
                  className={styled.iconDelete + " ri-delete-bin-fill"}
                  title="Deletar Instrução"
                />
              </a>
            </Popconfirm>
          )}
        </>
      ),
    },
  ];

  return (
    <Wrapper>
      <Card> 
        <Table
          className={styled.table}
          columns={columns}
          rowKey={(record) => record?.id}
          dataSource={data?.data}
          pagination={tablePagination}
          expandable={{
            expandedRowRender: (record) => (
              <div className="editorDisabled">
                <CKEditor
                  editor={Editor}
                  data={record.description}
                  disabled={true}
                />
              </div>
            ),
            onExpand: () => showEmbededHTML(),
          }}
          loading={loading}
          onChange={handleTableChange}
          scroll={{ x: 800 }}
          size="small"
        />
      </Card>
    </Wrapper>
  );
};

export default ListInstructions;
