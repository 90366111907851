import {
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Input,
  Popconfirm,
  Row,
  Select,
  message,
} from "antd";
import { Permissions, hasPermission } from "../../router/permissions";
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import { StatusType } from "./type";
import { Table } from "antd";
import Wrapper from "../../components/layout";
import moment from "moment-timezone";
import styled from "./styled.module.scss";
import { useAuth } from "../../providers/auth";
import { useForm } from "antd/es/form/Form";
import { useRest } from "../../services/http";

const ProspectList = () => {
  const { user } = useAuth();
  const { get, data, status } = useRest();
  const [form] = useForm();
  const [optionsResponsable, setOptionsResponsable] = useState([]);
  const [optionsService, setOptionsService] = useState([]);
  const [filterParams, setFilterParams] = useState({
    name: "",
    service: "",
    status: "",
    assignee: "",
    follow_up: "",
    hide_closed: true,
  });
  // const [hideClosed, setHideClosed] = useState(true);

  const {
    get: fullResponsableList,
    data: fullDataResponsableList,
    status: fullStatusResponsable,
    loading: responsableListLoading,
  } = useRest();
  const {
    get: fullServiceList,
    data: fullServiceListData,
    status: fullServiceListStatus,
    loading: fullServiceListLoading,
  } = useRest();

  const [tablePagination, setTablePagination] = useState({
    current: 1,
    pageSize: 15,
  });

  useEffect(() => {
    console.log('useeffect', filterParams);
    get("prospectList", {
      page: tablePagination.current,
      per_page: tablePagination.pageSize,
      sort_by: "created_at",  // Adiciona o parâmetro de ordenação
      sort_order: "desc",     // Define a ordem decrescente
      ...filterParams,
    });
  }, [tablePagination?.current, tablePagination?.pageSize, filterParams]);

  const {
    remove: removeProspect,
    status: removeStatus,
    message: removeMessage,
  } = useRest();

  useEffect(() => {
    fullResponsableList("fullResponsableList");
    fullServiceList("fullServiceList");
  }, []);

  useEffect(() => {
    let responsableArr = fullDataResponsableList?.map((item) => (
      <Select.Option key={item.id}>{item.description}</Select.Option>
    ));

    setOptionsResponsable(responsableArr);
  }, [fullDataResponsableList]);

  useEffect(() => {
    let serviceArr = fullServiceListData?.map((item) => (
      <Select.Option key={item.id}>{item.title}</Select.Option>
    ));
    setOptionsService(serviceArr);
  }, [fullServiceListData]);

  useEffect(() => {
    if (data && status.success) {
      setTablePagination({
        ...tablePagination,
        total: data?.total,
      });
    }
  }, [data]);

  const confirm = (id) => {
    removeProspect("removeProspect", { id: id });
  };

  useEffect(() => {
    if (removeStatus.success) {
      get("prospectList", {
        page: tablePagination.current,
        ...filterParams,
      });
      message.success("Prospecção de Cliente excluida com sucesso!");
      form.resetFields();
      resetFilter();
    }
    if (removeStatus.error) {
      message.error(removeMessage);
    }
  }, [removeStatus]);

  const filter = () => {
    const queryParams = {
      page: 1,
      ...filterParams,
    };

    // if (hideClosed) {
    // Garantir que status é um array
    // queryParams.status = Array.isArray(queryParams.status) ? queryParams.status.filter((s) => s !== "CLO") : [];
    // }

    get("prospectList", queryParams);

    setTablePagination({
      ...tablePagination,
      current: 1,
    });
  };




  // const filteredData = hideClosed
  // ? data?.data.filter(prospect => prospect.status !== 'CLO')
  // : data?.data;


  const resetFilter = () => {
    const params = {
      name: "",
      service: "",
      status: "",
      assignee: "",
      follow_up: "",
      hide_closed: true,
    };
  
    setFilterParams(params);
    setTablePagination({
      ...tablePagination,
      current: 1,
    });
    form.resetFields();
    get("prospectList", {
      page: 1,
      per_page: tablePagination.pageSize,
      sort_by: "created_at",
      sort_order: "desc",
      ...params,
    });
  };

  const handleTableChange = (pagination) => {
    setTablePagination({ ...tablePagination, current: pagination.current, pageSize: pagination.pageSize });

      // Chamar a API para atualizar os dados ao mudar o número de itens por página
  get("prospectList", {
    page: pagination.current,
    per_page: pagination.pageSize,
    sort_by: "created_at",
    sort_order: "desc",
    ...filterParams,
  });
  };

  const columns = [
    {
      title: "Data de Cadastro",
      dataIndex: "created_at", // Adjust the dataIndex to match your data source
      key: "created_at",
      sorter: (a, b) => new Date(b.created_at) - new Date(a.created_at),
      sortDirections: ["descend", "ascend"],
      defaultSortOrder: "ascend",
      render: (record) => (
        <span>
          {moment(record).format("DD/MM/YYYY")}
        </span>
      ),
    },
    {
      title: "Nome",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortDirections: ["ascend"],
      defaultSortOrder: "ascend",
    },
    // {
    //   title: "E-mail",
    //   dataIndex: "email",
    //   key: "email",
    //   sorter: (a, b) => a.email.localeCompare(b.email),
    //   sortDirections: ["ascend"],
    //   defaultSortOrder: "ascend",
    // },
    // {
    //   title: "Telefone",
    //   dataIndex: "phone_number",
    //   key: "phone_number",
    //   sorter: (a, b) => a.phone_number.localeCompare(b.phone_number),
    //   sortDirections: ["ascend"],
    //   defaultSortOrder: "ascend",
    // },





    {
      title: "Serviço",
      dataIndex: "interesting_service_id",
      key: "interesting_service_id",
      sorter: (a, b) =>
        String(a.interesting_service_id)?.localeCompare(
          String(b.interesting_service_id)
        ),
      sortDirections: ["ascend"],
      defaultSortOrder: "ascend",
      render: (record) =>
        fullServiceListStatus.success
          ? fullServiceListData.filter((item) => item?.id === record)[0]
            ? fullServiceListData.filter((item) => item?.id === record)[0].title
            : ""
          : "",
    },
    {
      title: "Responsável",
      dataIndex: "assignee_id",
      key: "assignee_id",
      sorter: (a, b) =>
        String(a.assignee_id)?.localeCompare(String(b.assignee_id)),
      sortDirections: ["ascend"],
      defaultSortOrder: "ascend",
      render: (record) =>
        fullStatusResponsable.success
          ? fullDataResponsableList.filter((item) => item?.id === record)[0]
            ? fullDataResponsableList.filter((item) => item?.id === record)[0]
              .description
            : ""
          : "",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      sorter: (a, b) => a.status.localeCompare(b.status),
      sortDirections: ["ascend"],
      defaultSortOrder: "ascend",
      render: (record) => StatusType[record],
    },
    {
      title: "Ações",
      dataIndex: "",
      render: (record) => (
        <>
          {hasPermission(user, Permissions.cadastrar_prospeccoes) && (
            <Link to={"/view-prospect/" + record.id} className={styled.icon}>
              <i className="ri-edit-fill" title="Editar Prospecção" />
            </Link>
          )}
          {hasPermission(user, Permissions.remover_prospeccoes) && (
            <Popconfirm
              title={
                "Tem certeza que deseja deletar a prospecção: " + record.name
              }
              onConfirm={() => confirm(record.id)}
              okText="Confirmar"
              cancelText="Cancelar"
            >
              <a className={styled.icon}>
                <i
                  className={styled.iconDelete + " ri-delete-bin-fill"}
                  title="Deletar Prospecção"
                />
              </a>
            </Popconfirm>
          )}
        </>
      ),
    },
  ];

  return (
    <Wrapper>
      <Card>
        <Form layout="vertical" autoComplete="off" form={form}>
          <Row
            justify="space-between"
            align="center"
            gutter={{ sm: 16, xs: 10 }}
          >
            <Col xs={24} sm={12}>
              <Form.Item
                label="Nome"
                name="name"
                value={filterParams.name}
                onChange={(e) =>
                  setFilterParams({ ...filterParams, name: e.target.value })
                }
              >
                <Input size="small" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item label="Responsável" name="responsavel">
                <Select
                  showSearch
                  allowClear
                  size="small"
                  placeholder="Selecionar responsável"
                  optionFilterProp="children"
                  disabled={responsableListLoading}
                  onChange={(value) =>
                    setFilterParams({ ...filterParams, assignee: value })
                  }
                  loading={responsableListLoading}
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                >
                  {optionsResponsable}
                </Select>
              </Form.Item>
            </Col>



            <Col xs={24} sm={12}>
              <Form.Item label="Status" name="status">
                <Select
                  mode="multiple"
                  showSearch
                  size="small"
                  allowClear
                  placeholder="Selecionar Status"
                  optionFilterProp="children"
                  onChange={(value) =>
                    setFilterParams({ ...filterParams, status: value })
                  }
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                >
                  <Select.Option key="NEW">Novo</Select.Option>
                  <Select.Option key="NEG">Negociação</Select.Option>
                  <Select.Option key="WAI">Aguardando</Select.Option>
                  <Select.Option key="CLO">Encerrado</Select.Option>
                </Select>
              </Form.Item>
            </Col>

            <Col xs={24} sm={12}>
              <Form.Item label="Serviços" name="service">
                <Select
                  mode="multiple"
                  size="small"
                  showSearch
                  allowClear
                  placeholder="Selecionar Serviços"
                  optionFilterProp="children"
                  disabled={fullServiceListLoading}
                  onChange={(value) =>
                    setFilterParams({ ...filterParams, service: value })
                  }
                  loading={fullServiceListLoading}
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                >
                  {optionsService}
                </Select>
              </Form.Item>
            </Col>

            <Col xs={24} sm={12}>
              <Form.Item>
                <Checkbox
                  checked={filterParams?.follow_up}
                  onChange={(e) =>
                    setFilterParams({
                      ...filterParams,
                      follow_up: e.target.checked,
                    })
                  }
                >
                  Pendente de follow UP?
                </Checkbox>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item>
                <Checkbox
                  checked={filterParams.hide_closed}
                  onChange={(e) => setFilterParams({ ...filterParams, hide_closed: e.target.checked })}
                  name="hideClosed"
                >
                  Ocultar Encerrados
                </Checkbox>
              </Form.Item>
            </Col>


            <Col xs={24} sm={12} style={{ marginRight: "auto" }}>
              <Button
                type="primary"
                htmlType="submit"
                onClick={() => filter()}
              >
                Buscar
              </Button>
              <br />
              <a href="#" onClick={() => resetFilter()}>
                Limpar busca
              </a>
            </Col>
          </Row>
        </Form>
        <br />
        <Table
          className={styled.table}
          columns={columns}
          rowKey={(record) => record?.id}
          dataSource={data?.data} // Usar dados filtrados aqui
          pagination={{
            ...tablePagination,
            total: data?.total,  // Total de resultados
            showSizeChanger: true,  // Mostrar opção de mudar a quantidade de itens por página
          }}
          loading={fullServiceListLoading}
          onChange={handleTableChange}
          scroll={{ x: 800 }}
          size="small"
        />
      </Card>
    </Wrapper>
  );
};

export default ProspectList;
