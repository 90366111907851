import "react-phone-input-2/lib/style.css";

/* eslint-disable react-hooks/exhaustive-deps */
import {
  Alert,
  Button,
  Card,
  Col,
  Divider,
  Form,
  Input,
  Layout,
  Modal,
  Radio,
  Row,
  Select,
  Space,
  message,
} from "antd";
import { useEffect, useMemo, useState } from "react";

import { BrazilianStates } from "../../enums/brazilianStates";
import { Country } from "../../enums/country";
import InputMask from "react-input-mask";
import Logo from "../../assets/images/logo-pequena.png";
import PhoneInput from "react-phone-input-2";
import { SearchOutlined } from "@ant-design/icons";
import USStates from "../../util/USStates";
import cepPromise from "cep-promise";
import { notification } from "antd";
import pt from "react-phone-input-2/lang/pt.json";
import styled from "./styled.module.scss";
import { useRest } from "../../services/http";
import { validate } from "gerador-validador-cpf";

const { Content } = Layout;

export default function PublicProspect() {
  const [form] = Form.useForm();
  const [formValues, setFormValues] = useState({ country: "Brasil" });
  const [isModalVisible, setModalVisible] = useState(false);
  const [empty, setEmpty] = useState(true);
  const [optionsService, setOptionsService] = useState([]);
  const [isVerified, setVerified] = useState(false);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  const {
    post: createPublicProspect,
    status: createPublicProspectStatus,
    loading: createPublicProspectLoading,
    message: createPublicProspectMessage,
    data: createPublicProspectData,
  } = useRest();

  const { get: getVerify, status: statusVerify, data: dataVerify } = useRest();

  const {
    get: fullServiceList,
    data: fullServiceListData,
    loading: fullServiceListLoading,
  } = useRest();
  const submit = async () => {
    await createPublicProspect("publicProspect", { ...formValues });
  };

  useEffect(() => {
    if (createPublicProspectStatus?.success) {
      setIsFormSubmitted(true);
      // notification.success({
      //   message: 'Sucesso',
      //   description: 'Sua mensagem foi enviada com sucesso. Entraremos em contato em breve.',
      //   duration: 7, // Duração em segundos
      //   closeIcon: true,
      // });

      form.resetFields();
      setFormValues({ country: "Brasil" });
      form.setFieldValue("phone_number", "+55");
      setVerified(false);
    } else if (createPublicProspectStatus?.error) {
      notification.error({
        message: "Erro",
        description: "Ocorreu um erro. Tente novamente.",
        duration: 4.5, // Duração em segundos
      });
    }
  }, [createPublicProspectStatus]);

  const handleConfirmationClick = () => {
    // Aqui você pode redirecionar o usuário ou simplesmente esconder a mensagem de confirmação
    setIsFormSubmitted(false); // Reseta o estado se necessário
  };

  // useEffect(() => {
  //   if (createPublicProspectStatus?.success) {
  //     message.success(
  //       "Obrigado pelo interesse em nosso serviço. Entraremos em contato em breve."
  //     );
  //     form.resetFields();
  //     setFormValues({ country: "Brasil" });
  //     form.setFieldValue("phone_number", "+55");
  //     setVerified(false);
  //   } else if (createPublicProspectStatus?.error) {
  //     message.error("Ocorreu um erro. Tente novamente.");
  //   }
  // }, [createPublicProspectStatus]);

  useEffect(() => {
    fullServiceList("fullServicePublicList", {});
  }, []);
  useEffect(() => {
    fullServiceListData?.map((item) => {
      setOptionsService((oldOptions) => [
        ...oldOptions,
        <Select.Option key={item.id}>{item.title}</Select.Option>,
      ]);
    });
  }, [fullServiceListData]);
  const CountryOptions = Country.map((item) => (
    <Select.Option key={item.nome_pais}>{item.nome_pais}</Select.Option>
  ));
  const UFBirthIndex = BrazilianStates.states.findIndex(
    (x) => x.uf === formValues.uf_of_birth
  );
  const UFIndex = BrazilianStates.states.findIndex(
    (x) => x.uf === formValues.uf
  );
  const UFOptions = BrazilianStates.states.map((item) => (
    <Select.Option key={item.uf}>{item.name}</Select.Option>
  ));
  const CityBirthOptions = BrazilianStates?.states[UFBirthIndex]?.citys.map(
    (item) => <Select.Option key={item}>{item}</Select.Option>
  );
  const CityOptions = BrazilianStates?.states[UFIndex]?.citys.map((item) => (
    <Select.Option key={item}>{item}</Select.Option>
  ));
  const isBrasil = useMemo(() => {
    return formValues.country === "Brasil";
  }, [formValues]);
  const getAddress = async () => {
    try {
      const address = await cepPromise(formValues.cep);
      setFormValues({
        ...formValues,
        uf: address.state,
        city: address.city,
        street: address.street,
      });
      form.setFieldValue("uf-address", address.state);
      form.setFieldValue("city-address", address.city);
      form.setFieldValue("street-address", address.street);
    } catch (e) {
      message.error("CEP não encontrado!");
    }
  };
  useEffect(() => {
    const hasEmpty =
      !formValues.birthdate ||
      !formValues.name ||
      !formValues.phone_number ||
      !formValues.email ||
      !formValues.uf_of_birth ||
      !formValues.city_of_birth ||
      !formValues.rg ||
      !formValues.issued_by ||
      !formValues.cpf ||
      !formValues.marital_status ||
      !formValues.country ||
      !formValues.cep ||
      !formValues.uf ||
      !formValues.city ||
      !formValues.street ||
      !formValues.interesting_service_id ||
      !formValues.where_meet_us ||
      !formValues.payment_method;

    setEmpty(hasEmpty);
  }, [formValues, setEmpty]);
  const errorMessage = useMemo(() => {
    if (createPublicProspectData) {
      if (Object.values(createPublicProspectData)?.length > 0) {
        return Object.values(createPublicProspectData).map((item) => (
          <>
            {item}
            <br />
          </>
        ));
      } else {
        return createPublicProspectMessage;
      }
    }
  }, [createPublicProspectData]);

  const verify = () => {
    if (isVerified || !formValues.cpf) return;
    const { cpf } = formValues;
    getVerify("verifyCustomer", { cpf });
  };

  useEffect(() => {
    if (statusVerify.success && dataVerify && !isVerified) {
      setVerified(true);

      setModalVisible(true);

      const {
        user: { name, email },
        birthdate,
        cpf,
        phone_number,
        rg,
        issued_by,
        marital_status,
        uf_of_birth,
        city_of_birth,
        country,
        cep,
        uf,
        city,
        street,
        street_number,
        street_complement,
      } = dataVerify;

      setFormValues({
        ...formValues,
        name,
        email,
        birthdate,
        cpf,
        phone_number,
        rg,
        issued_by,
        marital_status,
        uf_of_birth,
        city_of_birth,
        country,
        cep,
        uf,
        city,
        street,
        street_number,
        street_complement,
      });

      form.setFieldValue("name", name);
      form.setFieldValue("email", email);
      form.setFieldValue("birthdate", birthdate);
      form.setFieldValue("cpf", cpf);
      form.setFieldValue("phone_number", phone_number);
      form.setFieldValue("rg", rg);
      form.setFieldValue("org-rg", issued_by);
      form.setFieldValue("marital_status", marital_status);
      form.setFieldValue("uf", uf_of_birth);
      form.setFieldValue("city", city_of_birth);
      form.setFieldValue("country", country);
      form.setFieldValue("cep", cep);
      form.setFieldValue("uf-address", uf);
      form.setFieldValue("city-address", city);
      form.setFieldValue("street-address", street);
      form.setFieldValue("street_number-address", street_number);
      form.setFieldValue("street_complement-address", street_complement);
    }
  }, [statusVerify]);

  return (
    <Content className={"hp-content-main " + styled.content}>
      <div className={styled.header_content}>
        <div className={styled.header_form}>
          <div className={styled.logo}>
            <a href="https://braziliannurseabroad.com.br/">
              <img src={Logo} alt="Logo" />
            </a>
          </div>
          <h3>Contratação de Serviço</h3>
        </div>
      </div>
      <Row justify="center">
        <Col xxl={17} xl={22} span={24}>
          <Card>
            {createPublicProspectStatus?.error && (
              <Alert
                message="Dados incorretos:"
                description={errorMessage}
                type="error"
              />
            )}
            <Form
              layout="vertical"
              name="basic"
              initialValues={{ remember: true }}
              form={form}
              autoComplete="off"
            >
              <Row justify="space-between" gutter={{ sm: 16, xs: 10 }}>
                <Col xs={24} sm={12}>
                  <Form.Item
                    label="Nome Completo"
                    name="name"
                    onChange={(e) =>
                      setFormValues({ ...formValues, name: e.target.value })
                    }
                    rules={[
                      {
                        required: true,
                        message: "Campo obrigatório!",
                      },
                      {
                        pattern: /^([\W\w]{2,})+\s+([\W\w\s]{1,})+$/i,
                        message:
                          "Informe o nome e o sobrenome sem caracteres especiais.",
                      },
                    ]}
                  >
                    <Input size="small" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item
                    label="Email"
                    name="email"
                    onChange={(e) =>
                      setFormValues({ ...formValues, email: e.target.value })
                    }
                    rules={[
                      {
                        required: true,
                        message: "Campo obrigatório!",
                      },
                      {
                        pattern: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g,
                        message: "Email inválido!",
                      },
                    ]}
                  >
                    <Input size="small" />
                  </Form.Item>
                </Col>
              </Row>
              <Row justify="space-between" gutter={{ sm: 16, xs: 10 }}>
                <Col xs={24} sm={8}>
                  <Form.Item
                    label="Telefone"
                    name="phone_number"
                    initialValue={formValues.phone_number}
                    rules={[
                      {
                        required: true,
                        message: "Campo obrigatório!",
                      },
                    ]}
                  >
                    <PhoneInput
                      className={styled.phoneNumber}
                      country={"br"}
                      value={formValues.phone_number}
                      onChange={(value) =>
                        setFormValues({
                          ...formValues,
                          phone_number: "+" + value,
                        })
                      }
                      localization={pt}
                      enableSearch
                      inputClass={styled.phoneNumber}
                      defaultErrorMessage="Campo obrigatório!"
                      isValid={(inputNumber) =>
                        inputNumber.length > 0 ? true : false
                      }
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={8}>
                  <Form.Item
                    label="Data de nascimento"
                    name="birthdate"
                    onChange={(e) =>
                      setFormValues({
                        ...formValues,
                        birthdate: e.target.value,
                      })
                    }
                    rules={[
                      {
                        required: true,
                        message: "Campo obrigatório!",
                      },
                    ]}
                  >
                    <Input type="date" size="small" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={8}>
                  <Form.Item
                    label="Estado Civil"
                    name="marital_status"
                    rules={[
                      {
                        required: true,
                        message: "Campo obrigatório!",
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      size="small"
                      allowClear
                      placeholder="Estado Civil"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      onChange={(value) =>
                        setFormValues({
                          ...formValues,
                          marital_status: value,
                        })
                      }
                    >
                      <Select.Option key="SNG">Solteiro(a)</Select.Option>
                      <Select.Option key="MAR">Casado(a)</Select.Option>
                      <Select.Option key="SEP">Separado(a)</Select.Option>
                      <Select.Option key="DIV">Divorciado(a)</Select.Option>
                      <Select.Option key="WID">Viúvo(a)</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row justify="space-between" gutter={{ sm: 16, xs: 10 }}>
                <Col xs={24} sm={8}>
                  <Form.Item
                    label="CPF"
                    name="cpf"
                    onBlur={() => verify()}
                    rules={[
                      {
                        required: true,
                        message: "Campo obrigatório!",
                      },
                      {
                        validator(_, value) {
                          if (!validate(value)) {
                            return Promise.reject(new Error("CPF inválido!"));
                          }
                        },
                      },
                    ]}
                  >
                    <InputMask
                      size="small"
                      mask="999.999.999-99"
                      onChange={(e) =>
                        setFormValues({
                          ...formValues,
                          cpf: e.target.value.replace(/[^\d]/g, ""),
                        })
                      }
                    >
                      <Input />
                    </InputMask>
                  </Form.Item>
                </Col>

                <Col xs={24} sm={8}>
                  <Form.Item
                    label="RG"
                    name="rg"
                    onChange={(e) =>
                      setFormValues({ ...formValues, rg: e.target.value })
                    }
                    rules={[
                      {
                        required: true,
                        message: "Campo obrigatório!",
                      },
                    ]}
                  >
                    <Input size="small" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={8}>
                  <Form.Item
                    label="Órgão Emissor / UF"
                    name="org-rg"
                    onChange={(e) =>
                      setFormValues({
                        ...formValues,
                        issued_by: e.target.value,
                      })
                    }
                    rules={[
                      {
                        required: true,
                        message: "Campo obrigatório!",
                      },
                    ]}
                  >
                    <Input size="small" />
                  </Form.Item>
                </Col>
              </Row>
              <Row justify="space-between" gutter={{ sm: 16, xs: 10 }}>
                <Col xs={24} sm={12}>
                  <Form.Item
                    label="UF de Nascimento"
                    name="uf"
                    rules={[
                      {
                        required: true,
                        message: "Campo obrigatório!",
                      },
                    ]}
                  >
                    <Select
                      size="small"
                      showSearch
                      allowClear
                      placeholder="UF de Nascimento"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .normalize("NFD")
                          .replace(/[\u0300-\u036f]/g, "")
                          .indexOf(
                            input
                              .toLowerCase()
                              .normalize("NFD")
                              .replace(/[\u0300-\u036f]/g, "")
                          ) >= 0
                      }
                      onChange={(value) =>
                        setFormValues({
                          ...formValues,
                          uf_of_birth: value,
                          city_of_birth: "",
                        })
                      }
                    >
                      {UFOptions}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item
                    label="Cidade de Nascimento"
                    name="city"
                    rules={[
                      {
                        required: true,
                        message: "Campo obrigatório!",
                      },
                    ]}
                  >
                    <Select
                      size="small"
                      showSearch
                      allowClear
                      placeholder="Cidade de Nascimento"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .normalize("NFD")
                          .replace(/[\u0300-\u036f]/g, "")
                          .indexOf(
                            input
                              .toLowerCase()
                              .normalize("NFD")
                              .replace(/[\u0300-\u036f]/g, "")
                          ) >= 0
                      }
                      disabled={!formValues.uf_of_birth}
                      onChange={(value) =>
                        setFormValues({ ...formValues, city_of_birth: value })
                      }
                    >
                      {CityBirthOptions || []}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Divider
                dashed
                style={{ marginTop: "5px", marginBottom: "5px" }}
              />
              <Row justify="space-between" gutter={{ sm: 16, xs: 10 }}>
                <Col xs={24} sm={6}>
                  <Form.Item
                    label="País de Residência"
                    name="country"
                    initialValue={formValues.country}
                    rules={[
                      {
                        required: true,
                        message: "Campo obrigatório!",
                      },
                    ]}
                  >
                    <Select
                      size="small"
                      showSearch
                      allowClear
                      placeholder="País"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      onChange={(value) =>
                        setFormValues({ ...formValues, country: value })
                      }
                    >
                      {CountryOptions}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={6}>
                  <Form.Item>
                    <Space>
                      <Form.Item
                        label={isBrasil ? "CEP" : "Código Postal"}
                        name="cep"
                        rules={[
                          {
                            required: true,
                            message: "Campo obrigatorio!",
                          },
                        ]}
                      >
                        <InputMask
                          mask={isBrasil ? "99999-999" : null}
                          onChange={(e) =>
                            setFormValues({
                              ...formValues,
                              cep: e.target.value.replace(/[^\d]/g, ""),
                            })
                          }
                        >
                          <Input size="small" />
                        </InputMask>
                      </Form.Item>
                      {isBrasil && (
                        <Button
                          style={{ marginTop: "9px" }}
                          size="small"
                          type="primary"
                          icon={<SearchOutlined />}
                          onClick={getAddress}
                        />
                      )}
                    </Space>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={6}>
                  {isBrasil ? (
                    <Form.Item
                      label="Estado"
                      name="uf-address"
                      rules={[
                        {
                          required: true,
                          message: "Campo obrigatório!",
                        },
                      ]}
                    >
                      <Select
                        size="small"
                        showSearch
                        allowClear
                        placeholder="Estado"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .normalize("NFD")
                            .replace(/[\u0300-\u036f]/g, "")
                            .indexOf(
                              input
                                .toLowerCase()
                                .normalize("NFD")
                                .replace(/[\u0300-\u036f]/g, "")
                            ) >= 0
                        }
                        onChange={(value) =>
                          setFormValues({
                            ...formValues,
                            uf: value,
                            city: "",
                          })
                        }
                      >
                        {UFOptions}
                      </Select>
                    </Form.Item>
                  ) : (
                    <Form.Item
                      label="Estado"
                      name="uf-address"
                      onChange={(e) =>
                        setFormValues({ ...formValues, uf: e.target.value })
                      }
                      rules={[
                        {
                          required: true,
                          message: "Campo obrigatório!",
                        },
                      ]}
                    >
                      <Input size="small" />
                    </Form.Item>
                  )}
                </Col>
                <Col xs={24} sm={6}>
                  {isBrasil ? (
                    <Form.Item
                      label="Cidade"
                      name="city-address"
                      rules={[
                        {
                          required: true,
                          message: "Campo obrigatório!",
                        },
                      ]}
                    >
                      <Select
                        size="small"
                        showSearch
                        allowClear
                        placeholder="Cidade"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .normalize("NFD")
                            .replace(/[\u0300-\u036f]/g, "")
                            .indexOf(
                              input
                                .toLowerCase()
                                .normalize("NFD")
                                .replace(/[\u0300-\u036f]/g, "")
                            ) >= 0
                        }
                        disabled={!formValues.uf}
                        onChange={(value) =>
                          setFormValues({ ...formValues, city: value })
                        }
                      >
                        {CityOptions || []}
                      </Select>
                    </Form.Item>
                  ) : (
                    <Form.Item
                      label="Cidade"
                      name="city-address"
                      onChange={(e) =>
                        setFormValues({ ...formValues, city: e.target.value })
                      }
                      rules={[
                        {
                          required: true,
                          message: "Campo obrigatório!",
                        },
                      ]}
                    >
                      <Input size="small" />
                    </Form.Item>
                  )}
                </Col>
              </Row>
              <Row justify="space-between" gutter={{ sm: 16, xs: 10 }}>
                <Col xs={24} sm={8}>
                  <Form.Item
                    label="Rua"
                    name="street-address"
                    onChange={(e) =>
                      setFormValues({ ...formValues, street: e.target.value })
                    }
                    rules={[
                      {
                        required: true,
                        message: "Campo obrigatório!",
                      },
                    ]}
                  >
                    <Input size="small" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={8}>
                  <Form.Item
                    label="Número"
                    name="street_number-address"
                    onChange={(e) =>
                      setFormValues({
                        ...formValues,
                        street_number: e.target.value,
                      })
                    }
                  >
                    <Input size="small" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={8}>
                  <Form.Item
                    label="Complemento"
                    name="street_complement-address"
                    onChange={(e) =>
                      setFormValues({
                        ...formValues,
                        street_complement: e.target.value,
                      })
                    }
                  >
                    <Input size="small" />
                  </Form.Item>
                </Col>
              </Row>

              <Divider
                dashed
                style={{ marginTop: "5px", marginBottom: "5px" }}
              />
              <Row justify="space-between" gutter={{ sm: 16, xs: 10 }}>
                <Col xs={24} sm={12}>
                  <Form.Item
                    label="Serviço Interessado"
                    name="service"
                    rules={[
                      {
                        required: true,
                        message: "Campo obrigatório!",
                      },
                    ]}
                  >
                    <Select
                      size="small"
                      showSearch
                      allowClear
                      placeholder="Selecionar Serviço"
                      optionFilterProp="children"
                      disabled={fullServiceListLoading}
                      onChange={(value) =>
                        setFormValues({
                          ...formValues,
                          interesting_service_id: value,
                        })
                      }
                      loading={fullServiceListLoading}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                    >
                      {optionsService}
                    </Select>
                  </Form.Item>
                </Col>

                <Col xs={24} sm={12}>
                  {/* 
                <Form.Item
                    label="Board of Nursing"
                    name="state-bon"
                    rules={[
                      {
                        required: true,
                        message: "Campo obrigatório!",
                      },
                    ]}
                    onChange={(e) =>
                      setFormValues({
                        ...formValues,
                        state_bon: e.target.value,
                      })
                    }
                  >
                    <Input size="small" />
                  </Form.Item>
                  */}

                  <Form.Item
                    name="state_bon"
                    label="Board of Nursing"
                    rules={[
                      {
                        required: true,
                        message: "Campo obrigatório!",
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      placeholder="Selecione o Estado"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      onChange={(value) =>
                        setFormValues({ ...formValues, state_bon: value })
                      }
                    >
                      {USStates.map((state) => (
                        <Select.Option key={state.key} value={state.key}>
                          {state.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row justify="space-between" gutter={{ sm: 16, xs: 10 }}>
                <Col xs={24} sm={12}>
                  <Form.Item
                    label="Método de Pagamento"
                    name="payment_method"
                    rules={[
                      {
                        required: true,
                        message: "Campo obrigatório!",
                      },
                    ]}
                    initialValue={formValues.payment_method}
                  >
                    <Select
                      showSearch
                      label="Método de Pagamento"
                      allowClear
                      size="small"
                      placeholder="Método de Pagamento"
                      name="payment_method"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      onChange={(value) =>
                        setFormValues({ ...formValues, payment_method: value })
                      }
                    >
                       <Select.Option key="DCC">
                    Dólar - Cartão de Crédito/Débito
                  </Select.Option>
                  <Select.Option key="DTZ">
                    Dólar - Transferência (Zelle)
                  </Select.Option>
                  <Select.Option key="RAV">Real - Á Vista (PIX)</Select.Option>
                  <Select.Option key="RCC">
                    Real - Cartão de Crédito (Parcelados com Juros)
                  </Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item
                    label="Objetivo do Serviço"
                    name="goal"
                    onChange={(e) =>
                      setFormValues({
                        ...formValues,
                        goal: e.target.value,
                      })
                    }
                  >
                    <Input size="small" />
                  </Form.Item>
                </Col>
              </Row>

              <Row justify="space-between" gutter={{ sm: 16, xs: 10 }}>
                <Col xs={24} sm={5}>
                  <Form.Item
                    label="Proficiência em Inglês"
                    name="english_proficiency"
                  >
                    <Radio.Group
                      onChange={(e) =>
                        setFormValues({
                          ...formValues,
                          english_proficiency: e.target.value,
                        })
                      }
                      defaultValue={false}
                    >
                      <Radio value={true}>Sim</Radio>
                      <Radio value={false}>Não</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>

                <Col xs={24} sm={5}>
                  <Form.Item label="Possui Green Card" name="has_green_card">
                    <Radio.Group
                      onChange={(e) =>
                        setFormValues({
                          ...formValues,
                          has_green_card: e.target.value,
                        })
                      }
                      defaultValue={false}
                    >
                      <Radio value={true}>Sim</Radio>
                      <Radio value={false}>Não</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>

                <Col xs={24} sm={12}>
                  <Form.Item
                    label="Onde nos conheceu"
                    name="where-meet-us"
                    onChange={(e) =>
                      setFormValues({
                        ...formValues,
                        where_meet_us: e.target.value,
                      })
                    }
                    rules={[
                      {
                        required: true,
                        message: "Campo obrigatório!",
                      },
                    ]}
                  >
                    <Input size="small" />
                  </Form.Item>
                </Col>
              </Row>

              <Form.Item className="hp-mt-5 hp-mb-8">
                <Button
                  type="primary"
                  onClick={submit}
                  loading={createPublicProspectLoading}
                  disabled={empty}
                  htmlType="submit"
                >
                  Enviar
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>

      <Modal open={isModalVisible} footer={null} width="50%">
        <h4>
          Parece que você já é/foi um cliente. Alguns dados serão preenchidos
          automaticamente.
        </h4>
        <br />
        <Button type="primary" onClick={() => setModalVisible(false)}>
          Ok
        </Button>
        <br />
      </Modal>

      <Modal
        title="Confirmação"
        visible={isFormSubmitted}
        onOk={() => setIsFormSubmitted(false)}
        onCancel={() => setIsFormSubmitted(false)}
        footer={[
          <Button
            key="submit"
            type="success"
            onClick={() => setIsFormSubmitted(false)}
          >
            OK
          </Button>,
        ]}
      >
        <p>Obrigado! Seu formulário foi enviado com sucesso.</p>
      </Modal>
    </Content>
  );
}
