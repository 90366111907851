/* eslint-disable react-hooks/exhaustive-deps */
import Wrapper from "../../components/layout";
import {
  Input,
  Form,
  Button,
  message,
  Row,
  Col,
  Checkbox,
  Alert,
  Card,
} from "antd";
import { useRest } from "../../services/http";
import { useState, useEffect, useMemo } from "react";

const CreateRole = () => {
  const [form] = Form.useForm();
  const [permissions, setPermissions] = useState([]);
  const [formValues, setFormValues] = useState({
    title: "",
    description: "",
    permissions: [],
  });
  const { get, data, status } = useRest();
  const {
    post,
    data: dataPost,
    message: messagePost,
    status: statusPost,
  } = useRest();

  useEffect(() => {
    get("viewPermissions");
  }, []);

  useEffect(() => {
    if (status.success && data) {
      const result = data.reduce((group, item) => {
        const { module } = item;
        group[module] = group[module] ?? [];
        group[module].push(item);
        return group;
      }, {});
      setPermissions(result);
    }
  }, [data, status]);

  const onChangeCheckbox = (checked, id) => {
    const { permissions } = formValues;
    let newCheckedPermissions = permissions;

    if (checked) {
      newCheckedPermissions.push(id);
    } else {
      newCheckedPermissions = newCheckedPermissions.filter((e) => e !== id);
    }

    setFormValues({ ...formValues, permissions: newCheckedPermissions });
  };

  const submit = () => {
    if (formValues.title === "" || formValues.description === "") {
      message.error("Preencha todos os campos obrigatórios.");
    } else {
      post("createRole", formValues);
    }
  };

  useEffect(() => {
    if (statusPost.success) {
      message.success("Perfil criado com sucesso!");
      setFormValues({
        title: "",
        description: "",
        permissions: [],
      });
      form.resetFields();
    } else if (statusPost.error) {
      message.error("Ocorreu um erro. Tente novamente.");
    }
  }, [statusPost, dataPost, messagePost]);

  const errorMessage = useMemo(() => {
    if (dataPost) {
      if (Object.values(dataPost)?.length > 0) {
        return Object.values(dataPost).map((data) => (
          <>
            {data}
            <br />
          </>
        ));
      } else {
        return messagePost;
      }
    }
  }, [dataPost]);

  return (
    <Wrapper>
      <Card>
        <Form layout="vertical" form={form} autoComplete="off" name="aee">
          {statusPost.error && (
            <Alert
              message="Dados incorretos:"
              description={errorMessage}
              type="error"
            />
          )}

          <Row gutter={{ sm: 16, xs: 10 }}>
            <Col xs={24} sm={12}>
              <Form.Item
                label="Título"
                name="titulo"
                onChange={(e) =>
                  setFormValues({ ...formValues, title: e.target.value })
                }
                rules={[
                  {
                    required: true,
                    message: "Campo obrigatório!",
                  },
                ]}
              >
                <Input size="small"/>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                label="Descrição"
                name="descricao"
                onChange={(e) =>
                  setFormValues({ ...formValues, description: e.target.value })
                }
                rules={[
                  {
                    required: true,
                    message: "Campo obrigatório!",
                  },
                ]}
              >
                <Input size="small"/>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={{ sm: 16, xs: 10 }}>
            {Object.values(permissions).map((items, index) => (
              <Col xs={24} sm={6} key={index}>
                <div style={{ margin: "10px 0" }}>
                  <h4>{Object.keys(permissions)[index]}</h4>
                  {items.map((item, index) => (
                    <div key={index}>
                      <Checkbox
                        checked={formValues.permissions.includes(item.id)}
                        onChange={(e) =>
                          onChangeCheckbox(e.target.checked, item.id)
                        }
                      >
                        {item.name}
                      </Checkbox>
                      <br />
                    </div>
                  ))}
                </div>
              </Col>
            ))}
          </Row>
        </Form>
        <Button
          style={{ float: "right", marginBottom: "10px" }}
          type="primary"
          htmlType="submit"
          onClick={submit}
        >
          Criar Perfil
        </Button>
      </Card>
    </Wrapper>
  );
};

export default CreateRole;
