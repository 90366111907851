import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";

export const AuthContext = React.createContext({});

export const AuthProvider = (props) => {
  const [cookies, setCookie] = useCookies();

  const [user, setUser] = useState({
    access_token: cookies?.user?.access_token || "",
    token_type: cookies?.user?.token_type || "",
    expires_in: cookies?.user?.expires_in || "",
    expiresDate: cookies?.user?.expiresDate || "",
    role: cookies?.user?.role || "",
    customerID: cookies?.user?.customerID,
    isEmployee: cookies?.user?.role ? cookies?.user?.role !== 1 : false,
    username: cookies?.user?.username || "",
    permissions: cookies?.user?.permissions || [],
  });

  useEffect(() => {
    setCookie("user", user, { path: "/" });
  }, [user]);

  const resetUser = () => {
    setUser({
      access_token: "",
      token_type: "",
      expires_in: 0,
      expiresDate: "",
      role: "",
      customerID: "",
      isEmployee: false,
      username: "",
      permissions: [],
    });
  };

  return (
    <AuthContext.Provider value={{ user, setUser, resetUser }}>
      {props.children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => React.useContext(AuthContext);