import { Button, Card, Col, Collapse, Descriptions, Divider, Row, Spin, Table, Tabs, Timeline, message } from "antd";
import {
  EyeColor,
  HairColor,
  InstitutionType,
  SkinColor,
  StatusCoren,
} from "./type";
import { useEffect, useState } from "react";

/* eslint-disable react-hooks/exhaustive-deps */
import Wrapper from "../../components/layout";
import moment from "moment-timezone";
import styled from "./styled.module.scss";
import { useAuth } from "../../providers/auth";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useRest } from "../../services/http";

const { TabPane } = Tabs;
const { Panel } = Collapse;
const ShowClient = () => {
  //context of auth
  const { user } = useAuth();
  const { id } = useParams();

  //get data of customer
  const {
    get: viewCustomer,
    data: viewCustomerData,   
    loading: viewCustomerLoading,
  } = useRest();

  //get data of customer
  const { get: customerListPortals, data: viewPortalData } = useRest();
  const { get: customerListEducation, data: educationList } = useRest();
  const { get: customerListEmployer, data: viewEmployerData } = useRest();

  //call function of context By Carlos
  const { get: getAttachmentsByUser, data: dataAttachmentsByUser } = useRest();


  const navigate = useNavigate();
  const goBack = () => navigate(-1);

  const [tablePagination] = useState({
    current: 1,
    pageSize: 15,
  });

  useEffect(() => {
    getAttachmentsByUser("getAttachmentsByUser", { id });
  }, []);

  useEffect(() => {
    getAttachmentsByUser("getAttachmentsByUser", { id });
  }, [id]);



  useEffect(() => {
    viewCustomer("viewCustomer", { id });
  }, [id]);

  useEffect(() => {

    customerListEducation("customerListEducation", {
      page: tablePagination.current,
      id: id,
    });
    customerListPortals("customerListPortals", {
      page: tablePagination.current,
      id: id,
    });
    customerListEmployer("customerListEmployer", {
      page: tablePagination.current,
      id: id,
    });
  }, [tablePagination?.current]);

  const downloadFile = async (filePath) => {


    const data = await fetch(`${process.env.REACT_APP_API_URL}${filePath}`, {

      headers: {
        Authorization: `${"Bearer " + user?.access_token}`,
      },
    });

    if (data?.status !== 200) {
      message.error("Não foi possível baixar o arquivo. Tente novamente!");
      return;
    }

    const response = await data.blob();
    const urlCreator = window.URL || window.webkitURL;
    const imageUrl = urlCreator.createObjectURL(response);

    const a = document.createElement("a");
    a.href = imageUrl;
    a.download = filePath.replace("attachment/", "");
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const getMaritalStatus = (status) => {
    switch (status) {
      case "SNG":
        return "Solteiro(a)";
      case "MAR":
        return "Casado(a)";
      case "SEP":
        return "Separado(a)";
      case "DIV":
        return "Divorciado(a)";
      case "WID":
        return "Viúvo(a)";
      default:
        return "Desconhecido";
    }
  };

  //columns of table
  const columns = [
    {
      title: 'Dados Pessoais',
      dataIndex: 'field',
      key: 'field',
      width: '30%',
    },
    {
      title: 'Informações',
      dataIndex: 'value',
      key: 'value',
    },
  ];
  //data of tables
  const personalData = [
    {
      key: '1',
      field: 'Nome',
      value: viewCustomerData?.user.name ? viewCustomerData?.user.name : '-',
    },
    {
      key: '2',
      field: 'Email',
      value: viewCustomerData?.user.email ? viewCustomerData?.user.email : '-',
    },
    {
      key: '3',
      field: 'Usuário',
      value: viewCustomerData?.user.username ? viewCustomerData?.user.username : '-',
    },
    {
      key: '4',
      field: 'Telefone',
      value: viewCustomerData?.phone_number ? viewCustomerData?.phone_number : '-',
    },
    {
      key: '5',
      field: 'Data de nascimento',
      value: viewCustomerData?.birthdate ? moment(viewCustomerData?.birthdate).format('DD/MM/YYYY') : '-',
    },
    {
      key: '6',
      field: 'RG',
      value: viewCustomerData?.rg ? viewCustomerData?.rg : '-',
    },
    {
      key: '7',
      field: 'Órgão Emissor / UF',
      value: viewCustomerData?.issued_by ? viewCustomerData?.issued_by : '-',
    },
    {
      key: '8',
      field: 'CPF',
      value: viewCustomerData?.cpf ? viewCustomerData?.cpf : '-',
    },
    {
      key: '9',
      field: 'Estado Civil',
      value: viewCustomerData?.marital_status ? getMaritalStatus(viewCustomerData?.marital_status) : '-',
    },
    {
      key: '10',
      field: 'Estado',
      value: viewCustomerData?.uf_of_birth ? viewCustomerData?.uf_of_birth : '-',
    },
    {
      key: '11',
      field: 'Cidade',
      value: viewCustomerData?.city_of_birth ? viewCustomerData?.city_of_birth : '-',
    },
  ];

  const addressData = [
    {
      key: '1',
      field: 'País',
      value: viewCustomerData?.country ? viewCustomerData?.country : '-',
    },
    {
      key: '2',
      field: 'CEP',
      value: viewCustomerData?.cep ? viewCustomerData?.cep : '-',
    },
    {
      key: '3',
      field: 'Estado',
      value: viewCustomerData?.uf ? viewCustomerData?.uf : '-',
    },
    {
      key: '4',
      field: 'Cidade',
      value: viewCustomerData?.city ? viewCustomerData?.city : '-',
    },
    {
      key: '5',
      field: 'Rua',
      value: viewCustomerData?.street ? viewCustomerData?.street : '-',
    },
    {
      key: '6',
      field: 'Número',
      value: viewCustomerData?.street_number ? viewCustomerData?.street_number : '-',
    },
    {
      key: '7',
      field: 'Complemento',
      value: viewCustomerData?.street_complement ? viewCustomerData?.street_complement : '-',
    },
  ];

  const complementData = [
    {
      key: '1',
      field: 'Passaporte',    
      value: viewCustomerData?.passport_number || '-',  
    },  
    {
      key: '2',
      field: 'Social Security Number - SSN',
      value: viewCustomerData?.ssn || '-',
    },  
    {
      key: '3',
      field: 'Nome de solteira da mãe',
      value: viewCustomerData?.mothers_maiden_name || '-',  
    },  
    {
      key: '4',
      field: 'Raça',
      value: viewCustomerData?.skin_color ? SkinColor[viewCustomerData?.skin_color]: '-',
    },
    {
      key: '5',
      field: 'Cor dos olhos',
      value: viewCustomerData?.eyes_color
        ? EyeColor[viewCustomerData?.eyes_color]
        : '-',
    },
    {
      key: '6',
      field: 'Cor dos cabelos',
      value: viewCustomerData?.hair_color
        ? HairColor[viewCustomerData?.hair_color]
        : '-',
    },
    {
      key: '7',
      field: 'Altura em metros',
      value: viewCustomerData?.weight_mt
        ? viewCustomerData?.weight_mt + 'm'
        : '-',
    },
    {
      key: '8',
      field: 'Peso em KG',
      value: viewCustomerData?.height_kg
        ? viewCustomerData?.height_kg + 'KG'
        : '-',
    },
    {
      key: '9',
      field: 'Nome do seu melhor amigo ou amiga na infância',
      value: viewCustomerData?.childhood_friend_name || '-',
    },
    {
      key: '10',
      field: 'Nome do meio da sua mãe',
      value: viewCustomerData?.mother_middle_name || '-',
    },
    {
      key: '11',
      field: 'Qual seu apelido na infância',
      value: viewCustomerData?.childhood_nickname || '-',
    },
    {
      key: '12',
      field: 'Data do aniversário da mãe',
      value: viewCustomerData?.mother_birthday
        ? moment(viewCustomerData?.mother_birthday).format('DD/MM/YYYY')
        : '-',
    },
    {
      key: '13',
      field: 'Data do aniversário do pai',
      value: viewCustomerData?.father_birthday
        ? moment(viewCustomerData?.father_birthday).format('DD/MM/YYYY')
        : '-',
    },
  ];

  const corenData = [
    {
      key: "1",
      field: "Número do Coren",
      value: viewCustomerData?.coren_number ? viewCustomerData.coren_number : "-",
    },
    {
      key: "2",
      field: "Estado do Coren",
      value: viewCustomerData?.coren_state ? viewCustomerData.coren_state : "-",
    },
    {
      key: "3",
      field: "Status coren",
      value: viewCustomerData?.coren_status
        ? StatusCoren[viewCustomerData.coren_status]
        : "-",
    },
    {
      key: "4",
      field: "Data da emissão do Coren",
      value: viewCustomerData?.coren_license_issue_date
        ? moment(viewCustomerData.coren_license_issue_date).format("DD/MM/YYYY")
        : "-",
    },
    {
      key: "5",
      field: "Coren já foi revogada?",
      value: viewCustomerData?.coren_revoked ? "Sim" : "Não",
    },
    {
      key: "6",
      field: "Data da revogação do Coren",
      value: viewCustomerData?.coren_license_expiration_date
        ? moment(viewCustomerData.coren_license_expiration_date).format("DD/MM/YYYY")
        : "-",
    },
  ];

  return (
    <Wrapper>
      <Card title="Dados do Cliente" style={{ width: "100%" }}> 
      {viewCustomerLoading ? (
        <Row type="flex" align="middle" justify="center">
          <Col>
            <Spin />
          </Col>
        </Row>
      ) : (
        <>
          <Tabs defaultActiveKey="1" type="card" size="small" tabPosition={"left"}>
            <TabPane tab="Dados Pessoais" key="1">
              <div className={styled.scrollable}>
                <Table
                    columns={columns}
                    dataSource={personalData}
                    pagination={false}
                    bordered
                    size="small"
                    style={{ marginBottom: 20 }}
                  />
              </div>
            </TabPane>
            
            <TabPane tab="Endereço" key="2">
              <div className={styled.scrollable}>
                <Table
                  columns={columns}
                  dataSource={addressData}
                  pagination={false}
                  bordered
                  size="small"
                  style={{ marginBottom: 20 }}
                />
              </div>
            </TabPane>
            <TabPane tab="Dados Complementares" key="3">
              <div className={styled.scrollable}>
                <Table
                    columns={columns}
                    dataSource={complementData}
                    pagination={false}
                    bordered
                    size="small"
                    style={{ marginBottom: 20 }}
                  />
                </div>
            </TabPane>
            <TabPane tab="Dados Licença COREN" key="4">
              <div className={styled.scrollable}>
                <Table
                    columns={columns}
                    dataSource={corenData}
                    pagination={false}
                    bordered
                    size="small"
                    style={{ marginBottom: 20 }}
                  />
              </div>
            </TabPane>
            <TabPane tab="Dados de Segurança" key="5">
              {viewPortalData?.data.length > 0
                ? viewPortalData?.data.map((item, index) => (
                  <div className={styled.scrollable}>

                    <Collapse style={{ marginBottom: 5 }}>
                      <Panel header={item.link} key="1">
                      <h4 style={{ textDecoration: "underline" }}>
                        Portal {index + 1}:
                      </h4>
                        <p>
                           <strong>Usuário de Acesso: </strong>
                        {item.user ? item.user : "-"}
                        </p>

                      <p>
                        <strong>Nome: </strong>
                        {item.portal_name ? item.portal_name : "-"}
                      </p>
                      <p>
                        <strong>URL: </strong>
                        {item.link ? item.link : "-"}
                      </p>
                      <p>
                        <strong>Usuário de Acesso: </strong>
                        {item.user ? item.user : "-"}
                      </p>
                      <p>
                        <strong>Senha de Acesso: </strong>
                        {item.password ? item.password : "-"}
                      </p>
                      <p>
                        <strong>Observação: </strong>
                        {item.observation ? item.observation : "-"}
                      </p>
                      </Panel>
                    </Collapse>
                    </div>
                  ))
                : "Sem dados cadastrados."}
            </TabPane>
            <TabPane tab="Dados de Ensino" key="6">
              {educationList?.data.length > 0
                ? educationList?.data.map((item, index) => (
                  <div className={styled.scrollable}>
                    <Descriptions title={`Instituição ${index + 1}`} style={{ marginBottom: 15 }}>
                      <Descriptions.Item label="Tipo">
                        {item.institution_name
                          ? InstitutionType[item.education_type]
                          : "-"}
                      </Descriptions.Item>
                      <Descriptions.Item label="Nome">
                        {item.institution_name ? item.institution_name : "-"}
                      </Descriptions.Item>
                      <Descriptions.Item label="País">
                        {item.country ? item.country : "-"}
                      </Descriptions.Item>
                      <Descriptions.Item label="CEP">
                        {item.cep ? item.cep : "-"}
                      </Descriptions.Item>
                      <Descriptions.Item label="Estado">
                        {item.uf ? item.uf : "-"}
                      </Descriptions.Item>
                      <Descriptions.Item label="Cidade">
                        {item.city ? item.city : "-"}
                      </Descriptions.Item>
                      <Descriptions.Item label="Rua">
                        {item.street ? item.street : "-"}
                      </Descriptions.Item>
                      <Descriptions.Item label="Número">
                        {item.street_number ? item.street_number : "-"}
                      </Descriptions.Item>
                      <Descriptions.Item label="Complemento">
                        {item.street_complement ? item.street_complement : "-"}
                      </Descriptions.Item>
                      <Descriptions.Item label="Data de Início">
                      {item.start_date
                          ? moment(item.start_date).format("DD/MM/YYYY")
                          : "-"}
                      </Descriptions.Item>
                      <Descriptions.Item label="Data de Conclusão">
                      {item.end_date
                          ? moment(item.end_date).format("DD/MM/YYYY")
                          : "-"}
                      </Descriptions.Item>

                      <Descriptions.Item label="Data de Conclusão">
                      {item.end_date
                          ? moment(item.end_date).format("DD/MM/YYYY")
                          : "-"}
                      </Descriptions.Item>

                      {item.education_type === "UNI" && item.university_closed && (
                        <>
                          <Descriptions.Item label="Faculdade fechou?">
                          {item.university_closed ? "Sim" : "Não"}
                          </Descriptions.Item>
                          <Descriptions.Item label="Quem assumiu?">
                          {item.university_closed_transfer
                              ? item.university_closed_transfer
                              : "-"}
                          </Descriptions.Item>
                          
                        <Descriptions.Item label="Faculdade mudou de nome ou foi transferida?">
                          {item.university_changed_name
                              ? item.university_changed_name
                              : "-"}
                          </Descriptions.Item>                      
                        </>
                      )}

                      {item.education_type === "UNI" && (
                          <Descriptions.Item label="Data de Colação de Grau">
                          {item.graduation_date
                              ? moment(item.graduation_date).format(
                                  "DD/MM/YYYY"
                                )
                              : "-"}
                          </Descriptions.Item>
                      )}
                    </Descriptions>
                    <Divider />
                    </div>
                  ))
                : "Sem dados cadastrados."}
            </TabPane>
            <TabPane tab="Dados de Empregador" key="7">
              {viewEmployerData?.data.length > 0
                ? viewEmployerData?.data.map((item, index) => (
                    <>
                      <div className={styled.scrollable}>
                        <Descriptions title={`Empregador ${index + 1}`} style={{ marginBottom: 15 }}>
                          <Descriptions.Item label="Empresa">
                            {item.company_name ? item.company_name : "-"}
                          </Descriptions.Item>
                          <Descriptions.Item label="Nome do chefe:">
                            {item.boss_name ? item.boss_name : "-"}
                          </Descriptions.Item>
                          <Descriptions.Item label="Cargo">
                            {item.job_role ? item.job_role : "-"}
                          </Descriptions.Item>
                          <Descriptions.Item label="País">
                            {item.country ? item.country : "-"}
                          </Descriptions.Item>
                          <Descriptions.Item label="CEP">
                            {item.cep ? item.cep : "-"}
                          </Descriptions.Item>
                          <Descriptions.Item label="Estado">
                            {item.uf ? item.uf : "-"}
                          </Descriptions.Item>
                          <Descriptions.Item label="Cidade">
                            {item.city ? item.city : "-"}
                          </Descriptions.Item>
                          <Descriptions.Item label="Rua">
                            {item.street ? item.street : "-"}
                          </Descriptions.Item>
                          <Descriptions.Item label="Número">
                            {item.street_number ? item.street_number : "-"}
                          </Descriptions.Item>
                          <Descriptions.Item label="Complemento">
                            {item.street_complement ? item.street_complement : "-"}
                          </Descriptions.Item>
                          <Descriptions.Item label="Data de Início">
                            {item.start_date
                              ? moment(item.start_date).format("DD/MM/YYYY")
                              : "-"}
                          </Descriptions.Item>
                          <Descriptions.Item label="Data de Saída">
                            {item.end_date
                              ? moment(item.end_date).format("DD/MM/YYYY")
                              : "-"}
                          </Descriptions.Item>

                        </Descriptions>
                      </div>
                      <Divider />
                    </>
                  ))
                : "Sem dados cadastrados."}
            </TabPane>
            <TabPane tab="Documentos" key="8">
              <div className={styled.scrollable}>
                <Timeline 
                  style={{marginTop: 20}}
                  mode="right">
                {
                dataAttachmentsByUser !== null
                    ? dataAttachmentsByUser?.map((item, index) => (
                        <Timeline.Item key={index} label={moment(item.updated_at).format("DD/MM/YYYY")}>
                          <a onClick={() => downloadFile(item.path)}>
                            {item.name}
                          </a>
                        </Timeline.Item>
                      ))
                    : "Nenhum documento encontrado"
                }
                </Timeline>
              </div>
            </TabPane>
          </Tabs>
                                
          <div style={{padding: 5}}>
            <Button onClick={goBack} type="primary">Voltar</Button>
         </div> 
        </>
      )}
      </Card>
    </Wrapper>
  );
};

export default ShowClient;
