import { Button, Card, Form, Input, Select, Switch, Upload, message } from "antd";
import React, { useEffect, useState } from "react";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import { UploadOutlined } from "@ant-design/icons";
import Wrapper from "../../components/layout";
import { showEmbededHTML } from "../../util/ckeditor";
import { useRest } from "../../services/http";

// Certifique-se de importar o hook useRest corretamente
const { Option } = Select;
const AdvantageClubCreate = () => {
    const [form] = Form.useForm();
    const { post: createAdvantageClub, status, message: createMessage } = useRest();
    const [isImageValid, setIsImageValid] = useState(true);
    const [formState, setFormState] = useState();
    const {
        get: advantageClubCategoryList,
        data: categoriesData,
    } = useRest();

    const onFinish = async (values) => {
        try {
            const formData = new FormData();
            formData.append("title", values.title);
            formData.append("description", formState.description);

            if (values.image && values.image[0] && values.image[0].originFileObj) {
                console.log("values.image", values.image);
                formData.append("image[]", values.image[0].originFileObj);
            }

            values.categories.forEach((category) => {
                formData.append("categories[]", category);
            });

            if (isImageValid) {
                await createAdvantageClub("createAdvantageClub", formData);
              } else {
                message.error("A imagem deve ser menor que 2MB!");
              }


        } catch (error) {
            console.error("Error creating advantage club:", error);
            // Check if the error response contains a message field
            if (error.response && error.response.data && error.response.data.message) {
                message.error(error.response.data.message);
            } else {
                message.error("Error creating advantage club. Please try again.");
            }

        }
    };

    useEffect(() => {
        if (status?.success) {
            message.success(createMessage);
            form.resetFields();
            setFormState({});
        }




    }, [status, form, message,]);

    const fetchCategories = async () => {
        await advantageClubCategoryList("advantageClubCategoryList", { page: 1 });
    };

    useEffect(() => {
        fetchCategories();
    }, []);

    const normFile = (e) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e && e.fileList;
    };

    return (
        <Wrapper>
            <Card>
                <Form
                    form={form}
                    name="advantageClubForm"
                    onFinish={onFinish}
                    layout="vertical"
                >
                    <Form.Item
                        label="Título"
                        name="title"
                        rules={[{ required: true, message: "Título é obrigatório!" }]}
                        onChange={(e) =>
                            setFormState({ ...formState, title: e.target.value })
                        }
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Imagem"
                        name="image"
                        valuePropName="fileList"
                        getValueFromEvent={normFile}
                        rules={[{ required: true, message: "Imagem é obrigatória!" }]}
                        onChange={(e) =>
                            setFormState({ ...formState, image: e.fileList })
                        }

                    >
                      <Upload
              beforeUpload={(file) => {
                const isJpgOrPng =
                  file.type === "image/jpeg" || file.type === "image/png";
                if (!isJpgOrPng) {
                  message.error(
                    "Você pode apenas fazer upload de arquivos JPG/PNG!"
                  );
                }

                const isLt2M = file.size / 1024 / 1024 < 2; // Check if the file is less than 2MB
                if (!isLt2M) {
                  message.error("A imagem deve ser menor que 2MB!");
                }

                // Set the image validity state
                setIsImageValid(isJpgOrPng && isLt2M);

                return isJpgOrPng && isLt2M;
              }}
              listType="picture"
              maxCount={1}
            >
                       
                            <Button>Upload Image</Button>
                        </Upload>
                    </Form.Item>

                    <Form.Item
                        name="description"
                        label="Descrição da vantagem"
                        rules={[
                            {
                                required: true,
                                message: "Campo obrigatório!",
                            },
                        ]}
                    >
                        <CKEditor
                            editor={Editor}
                            name="description"
                            onChange={(events, editor) => {
                                const data = editor.getData();
                                setFormState({
                                    ...formState,
                                    description: data,
                                });
                            }}
                        />
                    </Form.Item>

                    <Form.Item
                        label="Categorias"
                        name="categories"
                        onChange={(e) =>
                            setFormState({ ...formState, categories: Array.isArray(e) ? e : [] })
                        }
                    >
                        <Select mode="multiple" placeholder="Selecione uma ou mais categorias">
                            {/* Mapear categorias aqui */}
                            {categoriesData?.map(category => <Option key={category.id}>{category.name}</Option>)}
                        </Select>
                    </Form.Item>

                    <Form.Item name="status">
                        <Button type="primary" htmlType="submit">
                            Criar Clube de Vantagens
                        </Button>
                    </Form.Item>
                </Form>
            </Card>
        </Wrapper>
    );
};

export default AdvantageClubCreate;
