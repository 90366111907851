import { Alert, Button, Col, Form, Input, Row } from "antd";
import {
  checkFirstLogin,
  getCustomerID,
  getName,
  getPermissions,
  getRole,
  getUserName,
} from "../../util/jwtUtil";
import { useLocation, useNavigate } from "react-router-dom";

import Footer from "./footer";
import LeftContent from "./leftContent";
import { Link } from "react-router-dom";
import MenuLogo from "../../assets/images/logo.png";
import { getExpiresDate } from "../../util/expireDate";
import { useAuth } from "../../providers/auth";
import { useEffect } from "react";
import { useRest } from "../../services/http";

export default function Login() {
  const [form] = Form.useForm();
  const { post: postLogin, data, loading, status, message } = useRest();
  const navigate = useNavigate();
  const location = useLocation();
  const { setUser } = useAuth();

  const submit = async () => {
    const username = form.getFieldValue("login");
    const password = form.getFieldValue("password");

    if (username && password) {
      const loginData = {
        username,
        password,
      };

      await postLogin("login", loginData);
    }
  };

  useEffect(() => {
    if (data?.access_token) {
      const isEmployee = getRole(data?.access_token) !== 1;

      setUser({
        access_token: data?.access_token,
        token_type: data?.token_type,
        expires_in: data?.expires_in,
        expiresDate: getExpiresDate(data?.expires_in),
        role: getRole(data?.access_token),
        username: getUserName(data?.access_token),
        customerID: getCustomerID(data?.access_token) || "",
        isEmployee,
        permissions: getPermissions(data?.access_token),
      });

      if (checkFirstLogin(data?.access_token)) {
        navigate("/change-password");
      } else {
        console.log('getRole', getRole(data?.access_token));

        switch (getRole(data?.access_token)) {
          case 2: // Admin
            navigate("/dashboard");
            break;
          default:
            navigate("/flow-list");
        }
      }
    }
  }, [data]);

  return (
    <Row className="hp-authentication-page">
      
      <LeftContent />
      <Col lg={12} span={24} className="hp-auth-col hp-auth-form-wrapper">
        <Row className="hp-h-100" align="middle" justify="center">
          <Col
            xxl={11}
            xl={15}
            lg={20}
            md={20}
            sm={24}
            className="hp-auth-form hp-px-sm-8 hp-pt-24 hp-pb-48"
          >
            <img
              src={MenuLogo}
              alt="Logo"
              className="hp-logo"
              style={{
                marginBottom: 25,
                display: "block",
                margin: "0 auto",
              }}
            />
            <p className="hp-mt-sm-0 hp-mt-8 hp-text-color-black-60">
              Seja bem-vindo! Por favor faça o login para continuar.
            </p>

            <Form
              layout="vertical"
              name="basic"
              initialValues={{ remember: true }}
              className="hp-mt-sm-16 hp-mt-32"
              form={form}
            >
              <Form.Item
                label="Usuário:"
                className="hp-mb-16"
                name="login"
                rules={[
                  {
                    required: true,
                    message: "Campo obrigatório!",
                  },
                ]}
              >
                <Input id="error" />
              </Form.Item>

              <Form.Item
                label="Senha:"
                className="hp-mb-8"
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Senha não foi preenchida!",
                  },
                ]}
              >
                <Input.Password id="warning2" />
              </Form.Item>

              <Row align="middle" justify="space-between">
                <Link
                  className="hp-button hp-text-color-black-80 hp-text-color-dark-40"
                  to="/recover-password"
                >
                  Esqueceu a senha?
                </Link>
              </Row>

              {status?.error && (
                <Alert message={message || "Ocorreu um erro."} type="error" />
              )}
              <Form.Item className="hp-mt-16 hp-mb-8">
                <Button
                  block
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                  onClick={submit}
                >
                  Entrar
                </Button>
              </Form.Item>
            </Form>
            <Footer />
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
