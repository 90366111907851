/* eslint-disable react-hooks/exhaustive-deps */
import { Form, Input, Row, Col } from "antd";
import { useState, useEffect } from "react";
import "react-phone-input-2/lib/style.css";

const Security = ({ defaultData, setData }) => {
  const [form] = Form.useForm();
  const [formValues, setFormValues] = useState(defaultData);

  useEffect(() => {
    form.setFieldValue("portal_name", defaultData?.portal_name);
    form.setFieldValue("link", defaultData?.link);
    form.setFieldValue("user", defaultData?.user);
    form.setFieldValue("password", defaultData?.password);
    form.setFieldValue("observation", defaultData?.observation);
    setFormValues(defaultData);
  }, [defaultData]);

  useEffect(() => {
    setData(formValues);
  }, [formValues]);

  return (
    <Form form={form} layout="vertical">
      <Row justify="space-between" gutter={{ sm: 16, xs: 10 }}>
        <Col xs={24} sm={12}>
          <Form.Item
            label="Nome do portal"
            name="portal_name"
            onChange={(e) =>
              setFormValues({ ...formValues, portal_name: e.target.value })
            }
            rules={[
              {
                required: true,
                message: "Campo obrigatorio!",
              },
            ]}
          >
            <Input size="small"/>
          </Form.Item>
        </Col>
        <Col xs={24} sm={12}>
          <Form.Item
            label="URL do portal"
            name="link"
            onChange={(e) =>
              setFormValues({ ...formValues, link: e.target.value })
            }
          >
            <Input showCount maxLength={255} size="small"/>
          </Form.Item>
        </Col>
      </Row>
      <Row justify="space-between" gutter={{ sm: 16, xs: 10 }}>
        <Col xs={24} sm={12}>
          <Form.Item
            label="Usuário de Acesso"
            name="user"
            onChange={(e) =>
              setFormValues({ ...formValues, user: e.target.value })
            }
            rules={[
              {
                required: false,
                message: "Campo obrigatório!",
              },
            ]}
          >
            <Input size="small"/>
          </Form.Item>
        </Col>
        <Col xs={24} sm={12}>
          <Form.Item
            label="Senha de acesso"
            name="password"
            onChange={(e) =>
              setFormValues({ ...formValues, password: e.target.value })
            }
            rules={[
              {
                required: false,
                message: "Campo obrigatorio!",
              },
            ]}
          >
            <Input size="small" />
          </Form.Item>
        </Col>
      </Row>

      <Row justify="space-between" gutter={{ sm: 16, xs: 10 }}>
        <Col xs={24} sm={24}>
          <Form.Item
            label="Observação"
            name="observation"
            onChange={(e) =>
              setFormValues({ ...formValues, observation: e.target.value })
            }
            rules={[
              {
                required: false,
                message: "Campo obrigatorio!",
              },
            ]}
          >
            <Input.TextArea allowClear max={250} showCount size="small"/>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default Security;
