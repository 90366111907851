import { Card, Form, Popconfirm, Table, message } from "antd";
import { Permissions, hasPermission } from "../../router/permissions";
import React, {useEffect, useState} from "react";

import { Link } from "react-router-dom";
import Wrapper from "../../components/layout";
import styled from "./styled.module.scss";
import { useAuth } from "../../providers/auth";
import {useRest} from "../../services/http";

const AdvantageClubCategoryList = () => {
    const { user } = useAuth();
    const [loading, setLoading] = useState(false);
    const [tablePagination, setTablePagination] = useState({
        current: 1,
        pageSize: 15,
      });

    const {
        get: advantageClubCategoryList,
        data,

        status,
    } = useRest();

    const {
        remove: removeAdvantageClubCategory,
        status: removeAdvantageClubCategoryStatus,
        message: removeAdvantageClubCategoryMessage,
      } = useRest();


    const confirm = (id) => {
        removeAdvantageClubCategory("removeAdvantageClubCategory", { id: id });
      };

    useEffect(() => {
        const fetchData = async () => {
            try {
                // Chama a função de lista de categorias
                await advantageClubCategoryList("advantageClubCategoryList", { page: tablePagination.current });

                // Loga os dados depois que a requisição for concluída
                console.log("advantageClubCategoryList", data);
            } catch (error) {
                console.error("Erro ao obter a lista de categorias:", error);
            }
        };

        // Chama a função fetchData
        fetchData();
    }, []); // Adiciona advantageClubCategoryList como uma dependência para reexecutar o efeito quando ela mudar

    useEffect(() => {
        if (removeAdvantageClubCategoryStatus.success) {
            advantageClubCategoryList("advantageClubCategoryList", {
                page: tablePagination.current,
            });
            console.log("removeAdvantageClubCategoryMessage", removeAdvantageClubCategoryMessage);

            message.success(removeAdvantageClubCategoryMessage);
        }
        if (removeAdvantageClubCategoryStatus && removeAdvantageClubCategoryStatus.error) {
            message.error(removeAdvantageClubCategoryMessage);
        }
    }, [removeAdvantageClubCategoryStatus]);

    useEffect(() => {
        if (status && status.error) {
            message.error("Ocorreu um erro ao listar os responsáveis. Tente novamente!");
        }
    }, [status]);
      
    useEffect(() => {
        if (data && status.success) {
          setTablePagination({
            ...tablePagination,
            pageSize: data?.per_page,
            total: data?.total,
          });
        }
      }, [data]);

      const handleTableChange = (pagination) => {
        setTablePagination({ ...tablePagination, current: pagination.current });
      };

      
    
    const columns = [
        {
            title: "Nome",
            dataIndex: "name",
            key: "name",
        },
        {
            title: "Descrição",
            dataIndex: "description",
            key: "description",
        },
        {
            title: "Ações",
            dataIndex: "",
            render: (record) => (
              <>
                {hasPermission(user, Permissions.cadastrar_clube_vantagens_caterogia) && (
                  <Link to={"/view-advantage-club-category/" + record.id} className={styled.icon}>
                    <i className="ri-edit-fill" title="Editar Categoria" />
                  </Link>
                )}
      
                {hasPermission(user, Permissions.cadastrar_clube_vantagens_caterogia) && (
                  <Popconfirm
                    title={
                      "Tem certeza que deseja deletar a categoria: " +
                      record.description
                    }
                    onConfirm={() => confirm(record.id)}
                    okText="Confirmar"
                    cancelText="Cancelar"
                  >
                    <a className={styled.icon}>
                      <i
                        className={styled.iconDelete + " ri-delete-bin-fill"}
                        title="Deletar Categoria"
                      />
                    </a>
                  </Popconfirm>
                )}
              </>
            ),
          },
    ];
    
    
    return (
        <Wrapper>
        <Card className="custom-card">
          <Table
            className={styled.table}
            columns={columns}
            rowKey={(record) => record?.id}
            dataSource={data}
            pagination={tablePagination}
            expandable={{
              expandedRowRender: (record) => (
                <p style={{ margin: 0 }}>{record.description}</p>
              ),
              rowExpandable: (record) => record.description.length > 100,
              expandedRowClassName: "custom-expanded-row",
            }}
            loading={loading}
            onChange={handleTableChange}
            scroll={{ x: 800 }}
            size="small"
          />
          </Card>
        </Wrapper>
    )
}
export default AdvantageClubCategoryList