import { Button, Card, Form, Input, Select, Switch, Upload, message } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import Wrapper from "../../components/layout";
import { showEmbededHTML } from "../../util/ckeditor";
import styled from "./styled.module.scss";
import { useRest } from "../../services/http";

const { Option } = Select;
const ViewAdvantageClub = () => {
    const [form] = Form.useForm();
    const { id } = useParams();
    const [formState, setFormState] = useState();
    const apiUrl = process.env.REACT_APP_API_URL_IMG;
    const [image, setImage] = useState([]);
    const [isDisabled, setIsDisabled] = useState(true);
    const [imagePreview, setImagePreview] = useState([]);
    const navigate = useNavigate();

    const { get: advantageClubCategoryList, data: categoriesData } = useRest();

    const fetchCategories = async () => {
        await advantageClubCategoryList("advantageClubCategoryList", { page: 1 });
    };

    useEffect(() => {
        fetchCategories();
    }, []);

    const { get: viewAdvantageClub, data: viewAdvantageClubData } = useRest();

    useEffect(() => {
        viewAdvantageClub("viewAdvantageClub", { id: id });
    }, [id]);

    useEffect(() => {
        if (viewAdvantageClubData && viewAdvantageClubData.title) {
            form.setFieldsValue({
                title: viewAdvantageClubData.title,
                description: viewAdvantageClubData.description,
                is_active: viewAdvantageClubData.is_active,
                categories: viewAdvantageClubData.categories.map((category) => category.id),
                advantage_club_id: id
            });
            console.log("viewAdvantageClubData", viewAdvantageClubData);
            if (viewAdvantageClubData?.image_attachment?.path) {
                setImagePreview(apiUrl + viewAdvantageClubData?.image_attachment?.path);
            }
        }

        showEmbededHTML(viewAdvantageClubData?.description);


    }, [viewAdvantageClubData, form]);


    const normFile = (e) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e && e.fileList;
    };

    const {
        put: updateAdvantageClub,
        message: updateAdvantageClubMessage,
        status: updateAdvantageClubStatus,
    } = useRest();

    const {
        post: updateAdvantageClubImage,
        message: updateAdvantageClubImageMessage,
        status: updateAdvantageClubImageStatus,
    } = useRest();

    const handleImageChange = (info) => {

        setImage(info.fileList);
        console.log("info", info);
        if (info.file.status === "uploading") {
            setIsDisabled(true);
            return;
        }
        if (info.file.status === "done") {
            setIsDisabled(false);
            return;
        }
    };



    const submitImage = async (fileList) => {
        try {
            console.log("fileList", fileList);
            const formData = new FormData();
    
            if (fileList.length > 0) {
                // id
                formData.append("id", id);
                formData.append("image[]", fileList[0].originFileObj);
            }
    
            await updateAdvantageClubImage("updateAdvantageClubImage", formData);
            // Não navegue automaticamente, deixe o envio do formulário principal lidar com isso
        } catch (error) {
            console.error("Error updating advantage club image:", error);
            message.error("Error updating advantage club image. Please try again.");
        }
    }

    const submit = async (value) => {
        try {
            await updateAdvantageClub("updateAdvantageClub", { id: id }, formState);

            // Aqui, você deve chamar a função submitImage antes de chamar a função updateAdvantageClub
            
            console.log("image valeue", value);
            if (image.length > 0) {
                await submitImage(value.image);
            }

            navigate("/advantage-club-list");

        } catch (error) {
            console.error("Error creating advantage club:", error);
            message.error("Error creating advantage club. Please try again.");
        }
    };


    useEffect(() => {
        if (updateAdvantageClubStatus.success) {
            message.success(updateAdvantageClubMessage);
        }
        if (updateAdvantageClubStatus && updateAdvantageClubStatus.error) {
            message.error(updateAdvantageClubMessage);
        }
    }, [updateAdvantageClubStatus]);

    const handleFormChange = (changedValues) => {
        setFormState((prevFormState) => ({
            ...prevFormState,
            ...changedValues,
        }));
    };




    return (
        <Wrapper>
            <Card >
                <Form form={form} layout="vertical" onFinish={submit} onValuesChange={handleFormChange}>



                    <Form.Item
                        label="Imagem"
                        name="image"
                        valuePropName="fileList"
                        getValueFromEvent={normFile}
                        

                    >
                        <Upload
                            beforeUpload={() => false} // Retorna false para prevenir o upload real
                            listType="picture"
                            maxCount={1}
                            onChange={handleImageChange}

                            onPreview={(file) => setImagePreview(file.originFileObj ? URL.createObjectURL(file.originFileObj) : [])}


                        >
                            {
                                imagePreview.length > 0 ? <img src={imagePreview} alt="avatar" className={styled.banner_advantage_club} /> : <img src={viewAdvantageClubData?.image_url} alt="avatar" style={{ width: '100%' }} />
                            }
                            <Button>Upload Image</Button>
                        </Upload>
                    </Form.Item>

                    <Form.Item
                        label="Título"
                        name="title"
                        rules={[{ required: true, message: "Please enter the title!" }]}
                        onChange={(e) =>
                            setFormState({ ...formState, title: e.target.value })
                        }
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        name="description"
                        label="Descrição da Vantagem"
                        rules={[
                            {
                                required: true,
                                message: "Campo obrigatório!",
                            },
                        ]}
                    >
                        <CKEditor
                            editor={Editor}
                            name="description"
                            data={viewAdvantageClubData?.description}
                            onChange={(events, editor) => {
                                const data = editor.getData();
                                setFormState({
                                    ...formState,
                                    description: data,
                                });
                            }}
                        />
                    </Form.Item>

                    <Form.Item label="Ativo" name="is_active" valuePropName="checked"
                        onChange={(e) =>
                            setFormState({ ...formState, is_active: e.target.checked })
                        }
                    >
                        <Switch />
                    </Form.Item>

                    <Form.Item
                        label="Categories"
                        name="categories"
                        onChange={(e) =>
                            setFormState({ ...formState, categories: Array.isArray(e) ? e : [] })
                        }
                    >
                        <Select mode="multiple" placeholder="Select categories">
                            {/* Mapear categorias aqui */}
                            {categoriesData?.map(category => <Option key={category.id} value={category.id}>{category.name}</Option>)}
                        </Select>
                    </Form.Item>

                    <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                        <Button type="primary" htmlType="submit" >
                            Atualizar Clube de Vantagens
                        </Button>
                    </Form.Item>
                </Form>
            </Card>
        </Wrapper>
    );
};

export default ViewAdvantageClub;
