/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from "react";
import Wrapper from "../../components/layout";
import styled from "./styled.module.scss";
import { useRest } from "../../services/http";
import { Popconfirm, message } from "antd";
import { Link } from "react-router-dom";
import { useAuth } from "../../providers/auth";
import moment from "moment-timezone";
import { Table, Card } from "antd";
import { useEffect } from "react";
import { Permissions, hasPermission } from "../../router/permissions";
const ResponsableList = () => {
  const { get: responsableList, loading, data, status } = useRest();

  const { user } = useAuth();

  const {
    remove: removeResponsable,
    status: removeStatus,
    message: removeMessage,
  } = useRest();

  const [tablePagination, setTablePagination] = useState({
    current: 1,
    pageSize: 15,
  });

  useEffect(() => {
    responsableList("responsableList", { page: tablePagination.current });
  }, [tablePagination?.current]);

  useEffect(() => {
    if (status?.error) {
      message.error(
        "Ocorreu um erro ao listar os responsáveis. Tente novamente!"
      );
    }
  }, [status]);

  useEffect(() => {
    if (data && status.success) {
      setTablePagination({
        ...tablePagination,
        pageSize: data?.per_page,
        total: data?.total,
      });
    }
  }, [data]);

  const confirm = (id) => {
    removeResponsable("removeResponsable", { id: id });
  };

  const handleTableChange = (pagination) => {
    setTablePagination({ ...tablePagination, current: pagination.current });
  };

  useEffect(() => {
    if (removeStatus.success) {
      responsableList("responsableList", { page: tablePagination.current });
      message.success(removeMessage);
    }
    if (removeStatus.error) {
      message.error(removeMessage);
    }
  }, [removeStatus]);

  const columns = [
    {
      title: "Descrição",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Criado em",
      dataIndex: "created_at",
      key: "created_at",
      render: (record) =>
        moment(record).tz("America/Sao_Paulo").format("DD/MM/YYYY - HH:mm"),
    },
    {
      title: "Atualizado em",
      dataIndex: "updated_at",
      key: "updated_at",
      render: (record) =>
        moment(record).tz("America/Sao_Paulo").format("DD/MM/YYYY - HH:mm"),
    },
    {
      title: "Ações",
      dataIndex: "",
      render: (record) => (
        <>
          {hasPermission(user, Permissions.cadastrar_responsaveis) && (
            <Link to={"/view-responsable/" + record.id} className={styled.icon}>
              <i className="ri-edit-fill" title="Editar Responsável" />
            </Link>
          )}

          {hasPermission(user, Permissions.remover_responsaveis) && (
            <Popconfirm
              title={
                "Tem certeza que deseja deletar o responsável: " +
                record.description
              }
              onConfirm={() => confirm(record.id)}
              okText="Confirmar"
              cancelText="Cancelar"
            >
              <a className={styled.icon}>
                <i
                  className={styled.iconDelete + " ri-delete-bin-fill"}
                  title="Deletar Responsável"
                />
              </a>
            </Popconfirm>
          )}
        </>
      ),
    },
  ];


  
  return (
    <Wrapper>
      <Card className="custom-card">
        <Table
          className={styled.table}
          columns={columns}
          rowKey={(record) => record?.id}
          dataSource={data?.data}
          pagination={tablePagination}
          expandable={{
            expandedRowRender: (record) => (
              <p style={{ margin: 0 }}>{record.description}</p>
            ),
            rowExpandable: (record) => record.description.length > 100,
            expandedRowClassName: "custom-expanded-row",
          }}
          loading={loading}
          onChange={handleTableChange}
          scroll={{ x: 800 }}
          size="small"
        />
        </Card>
      </Wrapper>

  );
};

export default ResponsableList;
