import {
  Alert,
  Button,
  Card,
  Col,
  Form,
  Input,
  Radio,
  Row,
  Select,
  Space,
  message,
} from "antd";
import { useEffect, useMemo, useState } from "react";

import { BrazilianStates } from "../../util/estadosMock";
import { Country } from "../../util/country";
import InputMask from "react-input-mask";
import PhoneInput from "react-phone-input-2";
import { SearchOutlined } from "@ant-design/icons";
import USStates from "../../util/USStates";
import Wrapper from "../../components/layout";
import cepPromise from "cep-promise";
import pt from "react-phone-input-2/lang/pt.json";
import styled from "./styled.module.scss";
import { useRest } from "../../services/http";
import { validate } from "gerador-validador-cpf";

const ProspectClient = () => {
  const {
    post: prospectClient,
    data,
    status,
    loading,
    message: prospectMessage,
  } = useRest();
  const [form] = Form.useForm();
  const [formValues, setFormValues] = useState({ country: "Brasil" });
  const [optionsResponsable, setOptionsResponsable] = useState([]);
  const [optionsService, setOptionsService] = useState([]);

  const {
    get: fullServiceList,
    data: fullServiceListData,
    loading: fullServiceListLoading,
  } = useRest();
  const {
    get: fullResponsableList,
    data: fullDataResponsableList,
    loading: responsableListLoading,
  } = useRest();

  useEffect(() => {
    fullResponsableList("fullResponsableList");
    fullServiceList("fullServiceList");
  }, []);

  useEffect(() => {
    let responsableArr = fullDataResponsableList?.map((item) => (
      <Select.Option key={item.id}>{item.description}</Select.Option>
    ));

    setOptionsResponsable(responsableArr);
  }, [fullDataResponsableList]);

  useEffect(() => {
    let serviceArr = fullServiceListData?.map((item) => (
      <Select.Option key={item.id}>{item.title}</Select.Option>
    ));

    setOptionsService(serviceArr);
  }, [fullServiceListData]);

  const submit = () => {
    prospectClient("prospectClient", formValues);
  };

  useEffect(() => {
    if (status?.success) {
      message.success("Prospecção criada com sucesso!");
      setFormValues({});
      form.resetFields();
    } else if (status.error) {
      message.error("Preencha todos os campos corretamente!");
    }
  }, [status, form]);

  const errorMessage = useMemo(() => {
    if (data) {
      if (Object.values(data)?.length > 0) {
        return Object.values(data).map((data) => (
          <>
            {data}
            <br />
          </>
        ));
      } else {
        return prospectMessage;
      }
    }
  }, [data, prospectMessage]);

  const getAddress = async () => {
    try {
      const address = await cepPromise(formValues.cep);
      setFormValues({
        ...formValues,
        uf: address.state,
        city: address.city,
        street: address.street,
      });
      form.setFieldValue("uf", address.state);
      form.setFieldValue("city", address.city);
      form.setFieldValue("street", address.street);
    } catch (e) {
      message.error("CEP não encontrado!");
    }
  };

  const isBrasil = useMemo(() => {
    return formValues.country === "Brasil";
  }, [formValues]);

  const UFOptions = BrazilianStates.states.map((item) => (
    <Select.Option key={item?.uf}>{item.name}</Select.Option>
  ));

  const CountryOptions = Country.map((item) => (
    <Select.Option key={item.nome_pais}>{item.nome_pais}</Select.Option>
  ));
  const UFIndex = BrazilianStates.states.findIndex(
    (x) => x?.uf === formValues?.uf
  );
  const CityOptions = BrazilianStates?.states[UFIndex]?.citys.map((item) => (
    <Select.Option key={item}>{item}</Select.Option>
  ));

  const [cityBirthOptions, setCityBirthOptions] = useState([]);

  const UFOfBirthIndex = BrazilianStates.states.findIndex(
    (x) => x?.uf === formValues?.uf_of_birth
  );

  useEffect(() => {
    if (UFOfBirthIndex !== -1) {
      const newCityOptions = BrazilianStates.states[UFOfBirthIndex].citys.map(
        (city) => <Select.Option key={city}>{city}</Select.Option>
      );

      setCityBirthOptions(newCityOptions);
    }
  }, [UFOfBirthIndex]);

  return (
    <Wrapper>
      {status.error && (
        <>
          <Alert
            message="Dados incorretos:"
            description={errorMessage}
            type="error"
          />
          <br />
        </>
      )}
      <Card>
        <Form form={form} layout="vertical">
          <Row justify="space-between" gutter={{ sm: 16, xs: 10 }}>
            <Col xs={24} sm={12} flex="center">
              <Form.Item
                name="name"
                label="Nome completo"
                onChange={(e) =>
                  setFormValues({ ...formValues, name: e.target.value })
                }
                rules={[
                  {
                    required: true,
                    message: "Campo obrigatório!",
                  },
                ]}
              >
                <Input
                  size="small"
                  placeholder="Nome completo"
                  maxLength={100}
                  name="name"
                  label="name"
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} flex="center">
              <Form.Item
                name="email"
                label="E-mail"
                onChange={(e) =>
                  setFormValues({ ...formValues, email: e.target.value })
                }
                rules={[
                  {
                    required: true,
                    message: "Campo obrigatório!",
                  },
                  {
                    pattern: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g,
                    message: "Email inválido!",
                  },
                ]}
              >
                <Input
                  placeholder="E-mail"
                  maxLength={100}
                  name="email"
                  label="email"
                  size="small"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row justify="space-between" gutter={{ sm: 16, xs: 10 }}>
            <Col xs={24} sm={8} flex="center">
              <Form.Item
                label="Data de nascimento"
                name="birthdate"
                initialValue={formValues.birthdate}
                onChange={(e) =>
                  setFormValues({ ...formValues, birthdate: e.target.value })
                }
              >
                <Input type="date" size="small" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={8} flex="center">
              <Form.Item
                label="CPF"
                name="cpf"
                rules={[
                  {
                    validator(_, value) {
                      if (value && !validate(value)) {
                        return Promise.reject(new Error("CPF inválido!"));
                      }
                    },
                  },
                ]}
              >
                <InputMask
                  mask="999.999.999-99"
                  onChange={(e) =>
                    setFormValues({
                      ...formValues,
                      cpf: e.target.value.replace(/[^\d]/g, ""),
                    })
                  }
                >
                  <Input size="small" />
                </InputMask>
              </Form.Item>
            </Col>
            <Col xs={24} sm={8} flex="center">
              <Form.Item
                label="Telefone"
                name="phone_number"
                initialValue={formValues.phone_number}
                rules={[
                  {
                    required: true,
                    message: "Campo obrigatório!",
                  },
                ]}
              >
                <PhoneInput
                  country={"br"}
                  initialValue={formValues.phone_number}
                  onChange={(value) =>
                    setFormValues({ ...formValues, phone_number: "+" + value })
                  }
                  localization={pt}
                  enableSearch
                  inputClass={styled.phoneNumber}
                  defaultErrorMessage="Telefone é obrigatório"
                  isValid={(inputNumber) =>
                    inputNumber.length > 0 ? true : false
                  }
                />
              </Form.Item>
            </Col>
          </Row>
          <Row justify="space-between" gutter={{ sm: 16, xs: 10 }}>
            <Col xs={24} sm={12} flex="center">
              <Form.Item
                label="RG"
                name="rg"
                onChange={(e) =>
                  setFormValues({ ...formValues, rg: e.target.value })
                }
                initialValue={formValues.rg}
              >
                <Input size="small" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} flex="center">
              <Form.Item
                label="Órgão Emissor / UF"
                name="org-rg"
                initialValue={formValues.issued_by}
                onChange={(e) =>
                  setFormValues({ ...formValues, issued_by: e.target.value })
                }
              >
                <Input size="small" />
              </Form.Item>
            </Col>
          </Row>
          <Row justify="space-between" gutter={{ sm: 16, xs: 10 }}>
            <Col xs={24} sm={12} flex="center">
              <Form.Item
                label="Estado Civil"
                name="marital_status"
                initialValue={formValues.marital_status}
              >
                <Select
                  showSearch
                  allowClear
                  size="small"
                  placeholder="Estado Civil"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onChange={(value) =>
                    setFormValues({
                      ...formValues,
                      marital_status: value,
                    })
                  }
                >
                  <Select.Option key="SNG">Solteiro(a)</Select.Option>
                  <Select.Option key="MAR">Casado(a)</Select.Option>
                  <Select.Option key="SEP">Separado(a)</Select.Option>
                  <Select.Option key="DIV">Divorciado(a)</Select.Option>
                  <Select.Option key="WID">Viúvo(a)</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} flex="center">
              <Form.Item
                label="País"
                name="country"
                initialValue={formValues.country}
              >
                <Select
                  showSearch
                  allowClear
                  placeholder="País"
                  size="small"
                  optionFilterProp="children"
                  defaultValue={formValues.country}
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                  onChange={(value) =>
                    setFormValues({ ...formValues, country: value })
                  }
                >
                  {CountryOptions}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row justify="space-between" gutter={{ sm: 16, xs: 10 }}>
            <Col xs={24} sm={12} flex="center">
              <Space>
                <Form.Item
                  label={isBrasil ? "CEP" : "Código Postal"}
                  name="cep"
                >
                  <InputMask
                    mask={isBrasil ? "99999-999" : null}
                    onChange={(e) =>
                      setFormValues({
                        ...formValues,
                        cep: e.target.value.replace(/[^\d]/g, ""),
                      })
                    }
                  >
                    <Input size="small" />
                  </InputMask>
                </Form.Item>

                {isBrasil && (
                  <Button
                    type="primary"
                    size="small"
                    icon={<SearchOutlined />}
                    onClick={getAddress}
                  />
                )}
              </Space>
            </Col>

            <Col xs={24} sm={12} flex="center">
              {isBrasil ? (
                <Form.Item label="Estado" name="uf">
                  <Select
                    showSearch
                    allowClear
                    size="small"
                    placeholder="Estado"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .normalize("NFD")
                        .replace(/[\u0300-\u036f]/g, "")
                        .indexOf(
                          input
                            .toLowerCase()
                            .normalize("NFD")
                            .replace(/[\u0300-\u036f]/g, "")
                        ) >= 0
                    }
                    onChange={(value) =>
                      setFormValues({
                        ...formValues,
                        uf: value,
                        city: "",
                      })
                    }
                  >
                    {UFOptions}
                  </Select>
                </Form.Item>
              ) : (
                <Form.Item
                  label="Estado"
                  name="uf"
                  initialValue={formValues.uf}
                  onChange={(e) =>
                    setFormValues({ ...formValues, uf: e.target.value })
                  }
                  rules={[
                    {
                      required: true,
                      message: "Campo obrigatorio!",
                    },
                  ]}
                >
                  <Input size="small" />
                </Form.Item>
              )}
            </Col>
          </Row>
          <Row justify="space-between" gutter={{ sm: 16, xs: 10 }}>
            <Col xs={24} sm={12} flex="center">
              {isBrasil ? (
                <Form.Item label="Cidade" name="city">
                  <Select
                    showSearch
                    allowClear
                    size="small"
                    placeholder="Cidade"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .normalize("NFD")
                        .replace(/[\u0300-\u036f]/g, "")
                        .indexOf(
                          input
                            .toLowerCase()
                            .normalize("NFD")
                            .replace(/[\u0300-\u036f]/g, "")
                        ) >= 0
                    }
                    disabled={!formValues?.uf}
                    onChange={(value) =>
                      setFormValues({ ...formValues, city: value })
                    }
                  >
                    {CityOptions || []}
                  </Select>
                </Form.Item>
              ) : (
                <Form.Item
                  label="Cidade"
                  name="city"
                  initialValue={formValues.city}
                  onChange={(e) =>
                    setFormValues({ ...formValues, city: e.target.value })
                  }
                >
                  <Input size="small" />
                </Form.Item>
              )}
            </Col>

            <Col xs={24} sm={12} flex="center">
              <Form.Item
                label="Rua"
                name="street"
                onChange={(e) =>
                  setFormValues({ ...formValues, street: e.target.value })
                }
              >
                <Input size="small" />
              </Form.Item>
            </Col>
          </Row>
          <Row justify="space-between" gutter={{ sm: 16, xs: 10 }}>
            <Col xs={24} sm={6} flex="center">
              <Form.Item
                label="Número"
                name="street_number"
                onChange={(e) =>
                  setFormValues({
                    ...formValues,
                    street_number: e.target.value,
                  })
                }
              >
                <Input size="small" />
              </Form.Item>
            </Col>

            <Col xs={24} sm={6} flex="center">
              <Form.Item
                label="Complemento"
                name="street_complement"
                onChange={(e) =>
                  setFormValues({
                    ...formValues,
                    street_complement: e.target.value,
                  })
                }
              >
                <Input size="small" />
              </Form.Item>
            </Col>

            <Col xs={24} sm={12} flex="center">
              <Form.Item
                label="Serviço interessado"
                name="service"
                rules={[
                  {
                    required: true,
                    message: "Campo obrigatório!",
                  },
                ]}
              >
                <Select
                  showSearch
                  allowClear
                  placeholder="Selecionar Serviços"
                  optionFilterProp="children"
                  size="small"
                  disabled={fullServiceListLoading}
                  onChange={(value) =>
                    setFormValues({
                      ...formValues,
                      interesting_service_id: value,
                    })
                  }
                  loading={fullServiceListLoading}
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                >
                  {optionsService}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row justify="space-between" gutter={{ sm: 16, xs: 10 }}>
            <Col xs={24} sm={12} flex="center">
              {isBrasil ? (
                <Form.Item label="Estado de nascimento" name="uf_of_birth">
                  <Select
                    showSearch
                    allowClear
                    size="small"
                    placeholder="Estado de nascimento"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .normalize("NFD")
                        .replace(/[\u0300-\u036f]/g, "")
                        .indexOf(
                          input
                            .toLowerCase()
                            .normalize("NFD")
                            .replace(/[\u0300-\u036f]/g, "")
                        ) >= 0
                    }
                    onChange={(value) =>
                      setFormValues({
                        ...formValues,
                        uf_of_birth: value,
                        city: "",
                      })
                    }
                  >
                    {UFOptions}
                  </Select>
                </Form.Item>
              ) : (
                <Form.Item
                  label="Estado de nascimento"
                  name="uf_of_birth"
                  initialValue={formValues.uf_of_birth}
                  onChange={(e) =>
                    setFormValues({
                      ...formValues,
                      uf_of_birth: e.target.value,
                    })
                  }
                  rules={[
                    {
                      required: true,
                      message: "Campo obrigatorio!",
                    },
                  ]}
                >
                  <Input size="small" />
                </Form.Item>
              )}
            </Col>
            <Col xs={24} sm={12} flex="center">
              {isBrasil ? (
                <Form.Item label="Cidade de nascimento" name="city_of_birth">
                  <Select
                    showSearch
                    allowClear
                    size="small"
                    placeholder="Cidade de nascimento"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .normalize("NFD")
                        .replace(/[\u0300-\u036f]/g, "")
                        .indexOf(
                          input
                            .toLowerCase()
                            .normalize("NFD")
                            .replace(/[\u0300-\u036f]/g, "")
                        ) >= 0
                    }
                    disabled={!formValues?.uf_of_birth}
                    onChange={(value) =>
                      setFormValues({ ...formValues, city_of_birth: value })
                    }
                  >
                    {cityBirthOptions || []}
                  </Select>
                </Form.Item>
              ) : (
                <Form.Item
                  label="Cidade de nascimento"
                  name="city_of_birth"
                  initialValue={formValues.city_of_birth}
                  onChange={(e) =>
                    setFormValues({
                      ...formValues,
                      city_of_birth: e.target.value,
                    })
                  }
                  rules={[
                    {
                      required: true,
                      message: "Campo obrigatorio!",
                    },
                  ]}
                >
                  <Input size="small" />
                </Form.Item>
              )}
            </Col>
          </Row>
          <Row justify="space-between" gutter={{ sm: 16, xs: 10 }}>
            <Col xs={24} sm={12} flex="center">
              <Form.Item
                name="where_meet_us"
                label="Onde nos conheceu"
                onChange={(e) =>
                  setFormValues({
                    ...formValues,
                    where_meet_us: e.target.value,
                  })
                }
              >
                <Input
                  placeholder="Onde nos conheceu"
                  maxLength={100}
                  name="where_meet_us"
                  label="where_meet_us"
                  size="small"
                />
              </Form.Item>
            </Col>

            <Col xs={24} sm={12} flex="center">
              <Form.Item
                name="goal"
                label="Objetivo"
                onChange={(e) =>
                  setFormValues({ ...formValues, goal: e.target.value })
                }
              >
                <Input
                  placeholder="Objetivo"
                  maxLength={100}
                  name="goal"
                  label="goal"
                  size="small"
                />
              </Form.Item>
            </Col>
          </Row>

          <Row justify="space-between" gutter={{ sm: 16, xs: 10 }}>
            <Col xs={24} sm={8} flex="center">
              <Form.Item
                label="Método de Pagamento"
                name="payment_method"
                rules={[
                  {
                    required: true,
                    message: "Campo obrigatório!",
                  },
                ]}
                initialValue={formValues.payment_method}
              >
                <Select
                  showSearch
                  label="Método de Pagamento"
                  allowClear
                  size="small"
                  placeholder="Método de Pagamento"
                  name="payment_method"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onChange={(value) =>
                    setFormValues({ ...formValues, payment_method: value })
                  }
                >
                  <Select.Option key="DCC">
                    Dólar - Cartão de Crédito/Débito
                  </Select.Option>
                  <Select.Option key="DTZ">
                    Dólar - Transferência (Zelle)
                  </Select.Option>
                  <Select.Option key="RAV">Real - Á Vista (PIX)</Select.Option>
                  <Select.Option key="RCC">
                    Real - Cartão de Crédito (Parcelados com Juros)
                  </Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={8} flex="center">
              <Form.Item
                label="Proficiência em Inglês"
                name="english_proficiency"
              >
                <Radio.Group
                  onChange={(e) =>
                    setFormValues({
                      ...formValues,
                      english_proficiency: e.target.value,
                    })
                  }
                  defaultValue={false}
                >
                  <Radio value={true}>Sim</Radio>
                  <Radio value={false}>Não</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col xs={24} sm={8} flex="center">
              <Form.Item label="Possui Green Card" name="has_green_card">
                <Radio.Group
                  onChange={(e) =>
                    setFormValues({
                      ...formValues,
                      has_green_card: e.target.value,
                    })
                  }
                  defaultValue={false}
                >
                  <Radio value={true}>Sim</Radio>
                  <Radio value={false}>Não</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>

          <Row justify="space-between" gutter={{ sm: 16, xs: 10 }}>
            <Col xs={24} sm={8} flex="center">
              <Form.Item label="Status" name="status">
                <Select
                  allowClear
                  placeholder="Status"
                  size="small"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                  onChange={(value) =>
                    setFormValues({ ...formValues, status: value })
                  }
                >
                  <Select.Option key="NEW">Novo</Select.Option>
                  <Select.Option key="NEG">Em Negociação</Select.Option>
                  <Select.Option key="WAI">Aguardando Retorno</Select.Option>
                  <Select.Option key="CLO">Encerrado</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={8} flex="center">
              <Form.Item
                label="Responsável"
                name="responsavel"
                rules={[
                  {
                    required: true,
                    message: "Campo obrigatório!",
                  },
                ]}
              >
                <Select
                  showSearch
                  allowClear
                  size="small"
                  placeholder="Selecionar responsável"
                  optionFilterProp="children"
                  disabled={responsableListLoading}
                  onChange={(value) =>
                    setFormValues({ ...formValues, assignee_id: value })
                  }
                  loading={responsableListLoading}
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                >
                  {optionsResponsable}
                </Select>
              </Form.Item>
            </Col>

            <Col xs={24} sm={8} flex="center">
              <Form.Item
                label="Data da Notificação"
                name="notify_date"
                onChange={(e) =>
                  setFormValues({ ...formValues, notify_date: e.target.value })
                }
              >
                <Input type="date" size="small" />
              </Form.Item>
            </Col>
          </Row>
          <Row justify="space-between" gutter={{ sm: 16, xs: 10 }}>
            <Col xs={24} sm={8} flex="center">
              <Form.Item
                name="state_bon"
                label="Board of Nursing"
                rules={[
                  {
                    required: true,
                    message: "Campo obrigatório!",
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Selecione o Estado"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onChange={(value) =>
                    setFormValues({ ...formValues, state_bon: value })
                  }
                >
                  {USStates.map((state) => (
                    <Select.Option key={state.key} value={state.key}>
                      {state.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Space
            size={"middle"}
            align="center"
            className={styled.buttonWrapper}
          >
            <Button
              type="primary"
              htmlType="submit"
              className="hp-btn-outline hp-hover-bg-primary-1"
              loading={loading}
              onClick={submit}
            >
              Criar
            </Button>
          </Space>
        </Form>
      </Card>
    </Wrapper>
  );
};

export default ProspectClient;
