/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useMemo } from "react";
import styled from "./styled.module.scss";
import moment from "moment-timezone";
import Education from "./index";
import { useRest } from "../../../services/http";
import { Popconfirm, message, Modal, Button, Alert } from "antd";
import { Table } from "antd";
import { InstitutionType } from "../../showClient/type";
import { useAuth } from "../../../providers/auth";
import { useParams } from "react-router-dom";
import { getCustomerID } from "../../../util/jwtUtil";

const ListEducations = () => {
  const {
    post,
    put,
    remove,
    data: dataCreate,
    loading,
    status,
    message: messageEducation,
  } = useRest();
  const { get, data } = useRest();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [educationId, setEducationId] = useState(0);
  const [updatedData, setUpdatedData] = useState({ country: "Brasil" });
  const { user } = useAuth();
  let { id } = useParams();
  if (!id) id = getCustomerID(user.access_token);

  const [tablePagination, setTablePagination] = useState({
    current: 1,
    pageSize: 15,
  });

  useEffect(() => {
    get("customerListEducation", {
      page: tablePagination.current,
      id: id,
    });
  }, [tablePagination?.current, status]);

  useEffect(() => {
    if (data && status.success) {
      setTablePagination({
        ...tablePagination,
        pageSize: data?.per_page,
        total: data?.total,
      });
    }
  }, [data]);

  const confirm = (id) => {
    remove("removeCustomerEducation", { id: id });
  };

  const handleTableChange = (pagination) => {
    setTablePagination({ ...tablePagination, current: pagination.current });
  };

  useEffect(() => {
    if (status?.success) {
      setIsModalOpen(false);
      get("customerListEducation", {
        page: tablePagination.current,
        id: id,
      });

      if (educationId) {
        message.success("Ensino atualizado com sucesso!");
      } else {
        message.success("Ensino criado com sucesso!");
      }
      setUpdatedData({ country: "Brasil" });
    } else if (status?.error) {
      message.error("Ocorreu um erro. Tente novamente.");
    }
  }, [status]);

  const errorMessage = useMemo(() => {
    if (dataCreate) {
      if (Object.values(dataCreate)?.length > 0) {
        return Object.values(dataCreate).map((item) => (
          <>
            {item}
            <br />
          </>
        ));
      } else {
        return messageEducation;
      }
    }
  }, [dataCreate]);

  const columns = [
    {
      title: "Tipo do Ensino",
      dataIndex: "education_type",
      key: "education_type",
      render: (education_type) => InstitutionType[education_type],
    },
    {
      title: "Nome da Instituição",
      dataIndex: "institution_name",
      key: "institution_name",
    },
    {
      title: "Data de Início",
      dataIndex: "start_date",
      key: "start_date",
      render: (start_date) => moment(start_date).format("DD/MM/YYYY"),
    },
    {
      title: "Data de Conclusão",
      dataIndex: "end_date",
      key: "end_date",
      render: (end_date) =>
        end_date ? moment(end_date).format("DD/MM/YYYY") : "Não informado",
    },
    {
      title: "Ações",
      dataIndex: "",
      render: (record) => (
        <>
          <a className={styled.icon}>
            <i
              className="ri-edit-fill"
              title="Editar Ensino"
              onClick={() => showModal(record.id)}
            />
          </a>

          <Popconfirm
            title={"Deseja excluir: " + record.institution_name + "?"}
            okText="Confirmar"
            cancelText="Cancelar"
            onConfirm={() => confirm(record.id)}
          >
            <a className={styled.icon}>
              <i
                className={styled.iconDelete + " ri-delete-bin-fill"}
                title="Deletar Ensino"
              />
            </a>
          </Popconfirm>
        </>
      ),
    },
  ];

  const showModal = (id) => {
    setEducationId(id);
    setIsModalOpen(true);
  };

  const handleOk = () => {
    !educationId
      ? post("createCustomerEducation", {
          ...updatedData,
          finished: false,
          customer_id: parseInt(id),
        })
      : put(
          "updateCustomerEducation",
          { id: educationId },
          { ...updatedData, finished: false }
        );
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setUpdatedData({ country: "Brasil" });
  };

  return (
    <>
      <br />
      <Button
        type="primary"
        onClick={() => showModal()}
        className="hp-bg-black-100 hp-border-color-black-100 hp-hover-bg-black-80 hp-hover-border-color-black-80"
      >
        Adicionar
      </Button>
      <br />
      <br />
      <Table
        className={styled.table}
        columns={columns}
        rowKey={(record) => record?.id}
        dataSource={data?.data}
        pagination={tablePagination}
        loading={loading}
        onChange={handleTableChange}
        scroll={{ x: 800 }}
        size={15}
      />
      <Modal
        title={!educationId ? "Adicionar Ensino" : "Editar Ensino"}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        width="80%"
        okText="Confirmar"
      >
        {status.error && (
          <Alert
            message="Dados incorretos:"
            description={errorMessage}
            type="error"
          />
        )}
        <br />
        <Education
          id={educationId}
          defaultData={
            educationId
              ? data?.data?.filter((item) => item.id === educationId)[0]
              : updatedData
          }
          setData={(values) => setUpdatedData(values)}
        />
      </Modal>
    </>
  );
};

export default ListEducations;
