import {
    Button,
    Card,
    Checkbox,
    Col,
    Form,
    Row,
    Select,
    Table,
    Tag
} from "antd";
import { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import Wrapper from "../../components/layout";
import moment from "moment-timezone";
import styled from './styled.module.scss'
import { useForm } from "antd/es/form/Form";
import { useRest } from "../../services/http";

const UnreadInteractions = () => {
    const [form] = Form.useForm();
    const [responsables, setResponsables] = useState([]);
    const [dataReport, setDataReport] = useState();
    const [dinamicColums, setDinamicColums] = useState();

    const { get: fullResponsableList, data: fullDataResponsableList } = useRest();
    const { get: unreadInteractionByAssigneeId, data: unreadInteractionByAssigneeIdData } = useRest();
    const { get: unreadInteraction, data: unreadInteractionData } = useRest();

    useEffect(() => {
        fullResponsableList("fullResponsableList");
    }, []);

    useEffect(() => {
        if (fullDataResponsableList) {
            const data = fullDataResponsableList?.map((item) => (
                <Select.Option key={item.id}>{item.description}</Select.Option>
            ));
            setResponsables(data);
        }
    }, [fullDataResponsableList]);

    const columns = [
        {
            title: 'Nome do cliente',
            dataIndex: 'name_of_customer',
            key: 'name_of_customer',
            width: 250,
        },
        {
            title: 'Responsável',
            dataIndex: 'responsable',
            key: 'responsable',
            width: 250,
        },
        {
            title: 'Serviço',
            dataIndex: 'service',
            key: 'service',
            width: 250,
        },
        {
            title: 'Etapa',
            dataIndex: 'step',
            key: 'step',
            width: 250,
        },
        {
            title: 'Data da interação',
            dataIndex: 'created_at',
            key: 'created_at',
            width: 200,
            render: (record) => {
                console.log(record)
                return moment(record).format("DD/MM/YYYY");
            }
        },
        {
            title: 'Interagir',
            dataIndex: 'interact',
            key: 'interact',
            render: (text, record) => (
                <Link to={`/interaction/${record.id}`}>
                    <Button type="primary" size="small">
                        Interagir
                    </Button>
                </Link>
            ),
        },
    ];

    useEffect(() => {

        setDataReport(unreadInteractionByAssigneeIdData);
        console.log("teste", unreadInteractionByAssigneeIdData)
    }, [unreadInteractionByAssigneeIdData]);

    async function handleFilter() {
        await form.validateFields();
        const responsableIds = form.getFieldValue("responsable") || [];
        const assigneeIdsString = responsableIds.join(",");
        const allAssigneesIds = await fullDataResponsableList?.map((item) => item.id);
        const allAssigneesIdsString = allAssigneesIds.join(",");

        if (assigneeIdsString) {
            console.log("não vazio", assigneeIdsString);
            await unreadInteractionByAssigneeId("unreadInteractionByAssigneeId", { assignee_id: assigneeIdsString });
            setDataReport(unreadInteractionByAssigneeIdData);
        } else {
            await unreadInteractionByAssigneeId("unreadInteractionByAssigneeId", { assignee_id: allAssigneesIdsString });
            setDataReport(unreadInteractionByAssigneeIdData);
        }
    }

    return (
        <Wrapper>
            <Card className={styled.card}>
                <Form layout="vertical" autoComplete="off" form={form}>
                    <Row
                        justify="space-between"
                        align="center"
                        gutter={{ sm: 16, xs: 10 }}
                    >
                        <Col xs={24} sm={12}>
                            <Form.Item label="Responsável" name="responsable">
                                <Select
                                    size="small"
                                    showSearch
                                    allowClear
                                    mode="multiple"
                                    placeholder="Selecionar um responsável"
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                    onChange={(value) => {
                                        form.setFieldsValue({ responsable: value });
                                    }}
                                >
                                    {responsables}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12}>
                            <Button
                                type="primary"
                                style={{ marginTop: 23, alignItems: "left" }}
                                onClick={() => handleFilter()}
                            >
                                Filtrar
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Card>

            {
                dataReport &&
                <Card className={styled.card}>
                    <Table
                        size="small"
                        dataSource={dataReport}
                        columns={columns}
                        pagination={false}
                        scroll={{ x: 1300 }}
                        rowKey={(record) => record?.id}
                    />
                </Card>
            }


        </Wrapper>
    )
}

export default UnreadInteractions;