/* eslint-disable react-hooks/exhaustive-deps */
import Wrapper from "../../components/layout";
import {
  Input,
  Form,
  Switch,
  Button,
  Select,
  message,
  Row,
  Col,
  Card,
} from "antd";
import { useRest } from "../../services/http";
import { useState, useEffect } from "react";

const Employee = () => {
  const [form] = Form.useForm();
  const [disabledSave, setDisabledSave] = useState(true);
  const {
    get: fullResponsableList,
    data: fullDataResponsableList,
    loading: responsableListLoading,
  } = useRest();
  const {
    post: createEmployee,
    status: createEmployeeStatus,
    loading: createEmployeeLoading,
    message: createEmployeeMessage,
  } = useRest();

  const {
    get: getRole,
    data: dataRole,
    status: statusRole,
    loading: loadingRole,
  } = useRest();

  const [options, setOptions] = useState([]);
  const [role, setRole] = useState([]);

  const [formState, setFormState] = useState({
    name: "",
    username: "",
    password: "",
    email: "",
    assignee_id: "",
    role_id: 0,
    enabled: true,
  });

  const findValid = () => {
    const isValid =
      formState.name === "" ||
      formState.username === "" ||
      formState.password === "" ||
      formState.password.length < 6 ||
      formState.email === "" ||
      !formState.role_id;
    setDisabledSave(isValid);
  };

  useEffect(() => {
    fullResponsableList("fullResponsableList");
    getRole("roleList", { is_select: 1 });
  }, []);

  useEffect(() => {
    findValid();
  }, [formState]);

  const submit = () => {
    const errors = form
      .getFieldsError()
      .filter((field) => field.errors.length > 0);

    if (errors.length > 0) {
      message.error("Preencha todos os campos corretamente!");
    } else {
      createEmployee("createEmployee", formState);
    }
  };

  useEffect(() => {
    const responsibleArr = fullDataResponsableList?.map((item) => (
      <Select.Option key={item.id}>{item.description}</Select.Option>
    ));
  
    if(fullDataResponsableList && fullDataResponsableList.length > 0){
      responsibleArr.sort((a, b) => {
        if (a.props.children.toLowerCase() < b.props.children.toLowerCase()) {
          return -1;
        }
        if (a.props.children.toLowerCase() > b.props.children.toLowerCase()) {
          return 1;
        }
        return 0;
      });
    }
    setOptions(responsibleArr);
  }, [fullDataResponsableList]);
  

  useEffect(() => {
    const roleArr = dataRole?.map((item) => (
      <Select.Option key={item.id}>{item.title}</Select.Option>
    ));

    setRole(roleArr);
  }, [dataRole]);

  useEffect(() => {
    if (createEmployeeStatus?.success) {
      message.success(createEmployeeMessage);
      form.resetFields();
      setDisabledSave(true);
    } else if (createEmployeeStatus?.error) {
      message.error(createEmployeeMessage);
    }
  }, [createEmployeeStatus]);

  return (
    <Wrapper>
      <Card>
        <Form layout="vertical" form={form} autoComplete="off">
          <Row justify="space-between" gutter={{ sm: 16, xs: 10 }}>
            <Col xs={24} sm={14}>
              <Form.Item
                label="Nome Completo"
                name="nome"
                onChange={(e) =>
                  setFormState({ ...formState, name: e.target.value })
                }
                rules={[
                  {
                    required: true,
                    message: "Campo obrigatório!",
                  },
                  {
                    pattern: /^([\W\w]{2,})+\s+([\W\w\s]{1,})+$/i,
                    message: "Nome completo obrigatório!",
                  },
                ]}
              >
                <Input size="small"/>
              </Form.Item>
            </Col>
            <Col xs={24} sm={5}>
              <Form.Item
                label="Perfil"
                name="perfil"
                rules={[
                  {
                    required: true,
                    message: "Campo obrigatório!",
                  },
                ]}
              >
                <Select
                  showSearch
                  size="small"
                  allowClear
                  placeholder="Selecionar perfil"
                  optionFilterProp="children"
                  disabled={loadingRole}
                  onChange={(value) =>
                    setFormState({ ...formState, role_id: value })
                  }
                  loading={loadingRole}
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                >
                  {role}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={5}>
              <Form.Item label="Responsável" name="responsavel">
                <Select
                  showSearch
                  allowClear
                  size="small"
                  placeholder="Selecionar responsável"
                  optionFilterProp="children"
                  disabled={responsableListLoading}
                  onChange={(value) =>
                    setFormState({ ...formState, assignee_id: value })
                  }
                  loading={responsableListLoading}
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                >
                  {options}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row justify="space-between" gutter={{ sm: 16, xs: 10 }}>
            <Col xs={24} sm={12}>
              <Form.Item
                label="E-mail"
                type="email"
                name="email"
                onChange={(e) =>
                  setFormState({ ...formState, email: e.target.value })
                }
                rules={[
                  {
                    required: true,
                    message: "Campo obrigatório!",
                  },
                  {
                    type: "email",
                    message: "Deve ser um E-mail válido!",
                    pattern: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g,
                  },
                ]}
              >
                <Input size="small"/>
              </Form.Item>
            </Col>
            <Col xs={24} sm={6}>
              <Form.Item
                label="Login"
                name="login"
                onChange={(e) =>
                  setFormState({ ...formState, username: e.target.value })
                }
                rules={[
                  {
                    required: true,
                    message: "Campo obrigatório!",
                  },
                ]}
              >
                <Input size="small"/>
              </Form.Item>
            </Col>
            <Col xs={24} sm={6}>
              <Form.Item
                label="Senha"
                name="senha"
                onChange={(e) =>
                  setFormState({ ...formState, password: e.target.value })
                }
                rules={[
                  {
                    required: true,
                    message: "A senha deve conter ao menos 6 caracteres!",
                    min: 6,
                  },
                ]}
              >
                <Input.Password size="small"/>
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <Form.Item label="Ativo">
          <Switch
            defaultChecked
            onChange={(value) => setFormState({ ...formState, enabled: value })}
          />
        </Form.Item>
        <Button
          type="primary"
          size="large"
          disabled={disabledSave}
          loading={createEmployeeLoading}
          onClick={submit}
        >
          Criar
        </Button>
      </Card>
    </Wrapper>
  );
};

export default Employee;
