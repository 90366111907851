/* eslint-disable react-hooks/exhaustive-deps */
import Wrapper from "../../components/layout";
import {
  Input,
  Form,
  Switch,
  Button,
  Select,
  Spin,
  Row,
  Col,
  Space,
  message,
  Card,
} from "antd";
import { useRest } from "../../services/http";
import { useState, useEffect } from "react";
import moment from "moment-timezone";
import { useParams, useNavigate } from "react-router-dom";

const ViewEmployee = (props) => {
  const params = useParams();
  const { id } = props.id ? props : params;
  const [form] = Form.useForm();
  const [options, setOptions] = useState([]);
  const [role, setRole] = useState([]);
  const [editable, setEditable] = useState(true);
  const navigate = useNavigate();

  const {
    get: fullResponsableList,
    data: fullDataResponsableList,
    loading: responsableListLoading,
  } = useRest();

  const {
    get: viewEmployee,
    loading: viewLoading,
    data: viewEmployeeData,
  } = useRest();

  const {
    put: updateEmployee,
    loading: updateLoading,
    message: updateMessage,
    status: updateStatus,
  } = useRest();

  const { get: getRole, data: dataRole } = useRest();

  const submit = () => {
    const errors = form
      .getFieldsError()
      .filter((field) => field.errors.length > 0);

    if (errors.length > 0) {
      message.error("Preencha todos os campos corretamente!");
    } else {
      updateEmployee("updateEmployee", { id: id }, formState);
    }
  };

  const [formState, setFormState] = useState({
    name: "",
    username: "",
    email: "",
    assignee_id: "",
    role_id: "",
    enabled: true,
    password: "",
  });

  useEffect(() => {
    fullResponsableList("fullResponsableList");
    getRole("roleList", {
      is_select: 1,
    });
  }, []);

  useEffect(() => {
    const data = fullDataResponsableList?.map((item) => (
      <Select.Option key={item.id}>{item.description}</Select.Option>
    ));

      if(fullDataResponsableList && fullDataResponsableList.length > 0){
        data.sort((a, b) => {
          if (a.props.children.toLowerCase() < b.props.children.toLowerCase()) {
            return -1;
          }
          if (a.props.children.toLowerCase() > b.props.children.toLowerCase()) {
            return 1;
          }
          return 0;
        });
      }
    setOptions(data);
  }, [fullDataResponsableList]);

  useEffect(() => {
    const data = dataRole?.map((item) => (
      <Select.Option key={item.id}>{item.title}</Select.Option>
    ));
    setRole(data);
  }, [dataRole]);

  useEffect(() => {
    if (updateStatus?.success) {
      message.success(updateMessage);
      setEditable(false);
      navigate("/employee-list");
    } else if (updateStatus.error) {
      message.error(updateMessage);
    }
  }, [updateStatus]);

  useEffect(() => {
    form.setFieldValue("name", viewEmployeeData?.name);
    form.setFieldValue("login", viewEmployeeData?.username);
    viewEmployeeData?.assignee_id &&
      form.setFieldValue("responsavel", String(viewEmployeeData?.assignee_id));
    viewEmployeeData?.role_id &&
      form.setFieldValue("perfil", String(viewEmployeeData?.role_id));
    form.setFieldValue("email", viewEmployeeData?.email);
    form.setFieldValue(
      "createAt",
      moment(viewEmployeeData?.created_at)
        .tz("America/Sao_Paulo")
        .format("DD/MM/YYYY - HH:mm")
    );
    form.setFieldValue(
      "updateAt",
      moment(viewEmployeeData?.updated_at)
        .tz("America/Sao_Paulo")
        .format("DD/MM/YYYY - HH:mm")
    );

    setFormState({
      name: viewEmployeeData?.name,
      username: viewEmployeeData?.username,
      email: viewEmployeeData?.email,
      enabled: viewEmployeeData?.enabled,
      assignee_id: viewEmployeeData?.assignee_id,
      role_id: viewEmployeeData?.role_id,
    });
  }, [viewEmployeeData]);

  useEffect(() => {
    viewEmployee("viewEmployee", { id });
  }, [id]);

  return (
    <Wrapper>
      <Card>
        {viewLoading ? (
          <Row type="flex" align="middle" justify="center">
            <Col>
              <Spin />
            </Col>
          </Row>
        ) : (
          <Form layout="vertical" form={form}>
            <Row justify="space-between" gutter={{ sm: 16, xs: 10 }}>
              <Col xs={24} sm={14}>
                <Form.Item
                  label="Nome Completo"
                  name="name"
                  onChange={(e) =>
                    setFormState({ ...formState, name: e.target.value })
                  }
                  rules={[
                    {
                      required: true,
                      message: "Campo obrigatório!",
                    },
                    {
                      pattern: /^([\W\w]{2,})+\s+([\W\w\s]{1,})+$/i,
                      message: "Nome completo obrigatório!",
                    },
                  ]}
                >
                  <Input disabled={editable} size="small"/>
                </Form.Item>
              </Col>
              <Col xs={24} sm={5}>
                <Form.Item
                  label="Perfil"
                  name="perfil"
                  rules={[
                    {
                      required: true,
                      message: "Campo obrigatório!",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    allowClear
                    placeholder="Selecionar perfil"
                    size="small"
                    optionFilterProp="children"
                    loading={responsableListLoading}
                    disabled={editable || props?.disableProfile}
                    onChange={(value) =>
                      setFormState({ ...formState, role_id: value })
                    }
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  >
                    {role}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={5}>
                <Form.Item label="Responsável" name="responsavel">
                  <Select
                    showSearch
                    allowClear
                    size="small"
                    placeholder="Selecionar responsável"
                    optionFilterProp="children"
                    loading={responsableListLoading}
                    disabled={editable || props?.disableResponsable}
                    onChange={(value) =>
                      setFormState({ ...formState, assignee_id: value })
                    }
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  >
                    {options}
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Row justify="space-between" gutter={{ sm: 16, xs: 10 }}>
              <Col xs={24} sm={12}>
                <Form.Item
                  label="E-mail"
                  type="email"
                  name="email"
                  onChange={(e) =>
                    setFormState({ ...formState, email: e.target.value })
                  }
                  rules={[
                    {
                      required: true,
                      message: "Campo obrigatório!",
                    },
                    {
                      type: "email",
                      message: "Deve ser um E-mail válido!",
                    },
                  ]}
                >
                  <Input disabled={editable} size="small"/>
                </Form.Item>
              </Col>
              <Col xs={24} sm={6}>
                <Form.Item
                  label="Login"
                  name="login"
                  onChange={(e) =>
                    setFormState({ ...formState, username: e.target.value })
                  }
                  rules={[
                    {
                      required: true,
                      message: "Campo obrigatório!",
                    },
                  ]}
                >
                  <Input disabled={editable}  size="small"/>
                </Form.Item>
              </Col>
              <Col xs={24} sm={6}>
                <Form.Item
                  label="Senha"
                  name="senha"
                  onChange={(e) => {
                    const { value } = e.target;

                    if (value.length >= 6) {
                      setFormState({ ...formState, password: e.target.value });
                    } else {
                      setFormState({ ...formState, password: "" });
                    }
                  }}
                  rules={[
                    {
                      validator(_, value) {
                        if (value && value.length > 0 && value.length < 6) {
                          return Promise.reject(
                            new Error(
                              "A senha deve conter ao menos 6 caracteres!"
                            )
                          );
                        }
                      },
                    },
                  ]}
                >
                  <Input.Password disabled={editable}  size="small"/>
                </Form.Item>
              </Col>
            </Row>

            <Form.Item label="Ativo" name="active">
              <Switch
                disabled={editable}
                onChange={(value) =>
                  setFormState({ ...formState, enabled: value })
                }
                checked={formState.enabled}
              />
            </Form.Item>
            <Space size={"middle"} align="center">
              <Button
                type={editable ? "primary" : "danger"}
                className={
                  editable
                    ? "hp-hover-bg-primary-1"
                    : "hp-text-color-danger-1 hp-border-color-danger-1 hp-hover-bg-danger-1" +
                      " hp-btn-outline"
                }
                onClick={() => setEditable(!editable)}
              >
                {editable ? "Editar" : "Cancelar"}
              </Button>
              {!editable && (
                <Button
                  type="primary"
                  className="hp-btn-outline hp-hover-bg-primary-1"
                  htmlType="submit"
                  onClick={submit}
                  loading={updateLoading}
                >
                  Confirmar Edição
                </Button>
              )}
            </Space>
          </Form>
        )}
      </Card>
    </Wrapper>
  );
};

export default ViewEmployee;
