import {
    Button,
    Card,
    Col,
    Form,
    Input,
    Row,
    message
} from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Wrapper from "../../components/layout";
import { useRest } from "../../services/http";

const ViewAdvantageClubCategory = () => {
    const [form] = Form.useForm();
    const { id } = useParams();
    const [formState, setFormState] = useState();
    const [isDisabled, setIsDisabled] = useState(true);
    const navigate = useNavigate();
    const {
        put: updateAdvantageClubCategory,
        message: updateAdvantageClubCategoryMessage,
        status: updateAdvantageClubCategoryStatus,
    } = useRest();

    const {
        get: viewAdvantageClubCategory,
        data: viewAdvantageClubCategoryData,
    } = useRest();


    useEffect(() => {
        viewAdvantageClubCategory("viewAdvantageClubCategory", { id: id });
    }, [id]);

    const editHandle = () => {
        setIsDisabled(!isDisabled);
    };

    const submit = () => {
        console.log("formState", formState);
        updateAdvantageClubCategory(
            "updateAdvantageClubCategory",
            { id: id },
            formState
        );
    };

    useEffect(() => {
        if (viewAdvantageClubCategoryData?.name) {
            form.setFieldValue("name", viewAdvantageClubCategoryData?.name);
            form.setFieldValue("description", viewAdvantageClubCategoryData?.description);
        }
    }, [viewAdvantageClubCategoryData]);

    useEffect(() => {
        console.log("updateAdvantageClubCategoryStatus", updateAdvantageClubCategoryStatus);
        if (updateAdvantageClubCategoryStatus?.success) {
            message.success(updateAdvantageClubCategoryMessage);
            setIsDisabled(true);
            navigate("/advantage-club-category-list");
        }
        if (updateAdvantageClubCategoryStatus?.error) {
            message.error(updateAdvantageClubCategoryMessage);
        }
    }, [updateAdvantageClubCategoryStatus]);

    return (
        <Wrapper>
                  <Card style={{width:"50%"}}>
                <Form form={form} onFinish={submit} layout="vertical">
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                label="Nome"
                                name="name"
                                
                                onChange={(e) =>
                                    setFormState({ ...formState, name: e.target.value })
                                }
                                rules={[{ required: true, message: "Por favor, insira o nome da categoria!" }]}
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                label="Descrição"
                                name="description"
                                onChange={(e) =>
                                    setFormState({ ...formState, description: e.target.value })
                                }
                                rules={[{ required: true, message: "Por favor, insira a descrição da categoria!" }]}
                            >
                                <Input.TextArea />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item>
                        <Button
                            type={isDisabled ? "primary" : "danger"}
                            className={
                                isDisabled
                                    ? "hp-hover-bg-primary-1"
                                    : "hp-text-color-danger-1 hp-border-color-danger-1 hp-hover-bg-danger-1" +
                                    " hp-btn-outline"
                            }
                            onClick={editHandle}
                        >
                            {isDisabled ? "Editar" : "Cancelar"}
                        </Button>

                        {!isDisabled && (
                            <Button
                                type="primary"
                                className="hp-btn-outline hp-hover-bg-primary-1"
                                loading={updateAdvantageClubCategoryStatus === 200}
                                htmlType="submit"
                            >
                                Confirmar Edição
                            </Button>
                        )}
                    </Form.Item>
                </Form>
            </Card>
        </Wrapper>
    );
};

export default ViewAdvantageClubCategory;
