import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Space,
  Switch,
  message,
} from "antd";
import { Edit, Trash } from "iconsax-react";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

/* eslint-disable react-hooks/exhaustive-deps */
import Wrapper from "../../components/layout";
import { arrayMoveImmutable } from "array-move";
import styled from "./styled.module.scss";
import { useRest } from "../../services/http";

const { TextArea } = Input;

export const ViewService = () => {
  const [form] = Form.useForm();
  const [steps, setSteps] = useState([]);
  const [isDisabled, setIsDisabled] = useState(true);
  const [disableSteps, setDisableSteps] = useState(true);

  //Gatti - 2022-20-08
  //handle data steps edit
  const [hideStepViewModal, setHideStepViewModal] = useState(false); //hide steps
  const [disableEmailModal, setDisableEmailModal] = useState(false);
  const [disableInteractionsModal, setDisableInteractionsModal] = useState(false);
  const [disableAttachmentModal, setDisableAttachmentModal] = useState(false);

  //handle data steps view
  const [hideStepView, setHideStepView] = useState(false); //hide steps
  const [disableEmail, setDisableEmail] = useState(false);
  const [disableInteractions, setDisableInteractions] = useState(false);
  const [disableAttachment, setDisableAttachment] = useState(false);


  //End Gatti - 2022-20-08

  const [optionsInstruction, setOptionsInstruction] = useState([]);
  const { id } = useParams();
  const { get: viewService, data: viewData } = useRest();
  const {
    put: updateService,
    status,
    loading: updateLoading,
    data: updateData,
    message: updateMessage,
  } = useRest();
  const navigate = useNavigate();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setHideStepView(false);
    setDisableEmail(false);
    setDisableInteractions(false);
    setDisableAttachment(false);
    setIsModalOpen(false);
  };

  const { get: fullInstructionsList, data: fullInstructionsListData } =
    useRest();

  const { put: updateStep } = useRest();

  const [formValues, setFormValues] = useState({ is_commercialized: true });

  useEffect(() => {
    fullInstructionsList("fullInstructionsList");
  }, []);

  useEffect(() => {
    let instructionArr = fullInstructionsListData?.map((item) => (
      <Select.Option key={item.id}>{item.title}</Select.Option>
    ));

    setOptionsInstruction(instructionArr);
  }, [fullInstructionsListData]);

  useEffect(() => {
    const newOrderWithID = steps.map((item, index) => ({
      ...item,
      sort: index,
      id: item?.id,
    }));
    if (JSON.stringify(steps) !== JSON.stringify(newOrderWithID)) {
      setSteps(newOrderWithID);
    }
  }, [setSteps, steps]);

  useEffect(() => {
    form.getFieldsValue(["step"]);
  }, [form]);

  const sortSteps = (steps, oldIndex, newIndex) => {
    const newOrder = arrayMoveImmutable(steps, oldIndex, newIndex);
    const newOrderWithID = newOrder.map((item, index) => ({
      ...item,
      sort: index,
    }));
    return newOrderWithID;
  };

  const removeStep = (index) => {
    const filtered = steps.filter((_, i) => i !== index);
    setSteps(filtered);
  };

  const pushStep = () => {
    setSteps([
      ...steps,
      {
        title: form.getFieldValue("step"),
        description: form.getFieldValue("step_description") || "",
        instructions: form.getFieldValue("instructions") || [],
        hide_step: form.getFieldValue("hide_step") || false,
        disable_email: form.getFieldValue("disable_email") || false,
        disable_interactions: form.getFieldValue("disable_interactions") || false,
        disable_attachment: form.getFieldValue("disable_attachment") || false,
        
      },
    ]);

    form.resetFields(["step", "step_description", "instructions", "hide_step", "disable_email", "disable_interactions", "disable_attachment"]);
    setHideStepView(false);
    setDisableEmail(false);
    setDisableInteractions(false);
    setDisableAttachment(false);
    setDisableSteps(true);
  };

  const editHandle = () => {
    setIsDisabled(!isDisabled);
  };

  //Update Gatti - 2023-20-08
  //handle data steps edit 
  const handleSteps = (value, index) => {
    // Handle editing of steps
    showModal();

    //Gatti - 2022-20-08
    //handle data steps edit
    // Access individual step objects and their properties
    setHideStepViewModal(value.hide_step);
    setDisableEmailModal(value.disable_email);
    setDisableInteractionsModal(value.disable_interactions);
    setDisableAttachmentModal(value.disable_attachment);

    if (value.id) {
      form.setFieldsValue({
        key_modal: value.id,
        service_modal: viewData?.title,
        step_modal: value.title,
        step_description_modal: value.description,
        hide_step: value.hide_step,
        disable_email_modal: value.disable_email,
        disable_interactions_modal: value.disable_interactions,
        disable_attachment_modal: value.disable_attachment,
        instructions_modal: value.instructions.map((item) => item.toString()),
      });
    }
    //End Gatti - 2022-20-08
  };

  const handleOk = async () => {
    //create new object with new data
    const newDataUpdate = {
      key: form.getFieldValue("key_modal"),
      title: form.getFieldValue("step_modal"),
      description: form.getFieldValue("step_description_modal") || "",
      instructions: form.getFieldValue("instructions_modal") || [],
      hide_step: form.getFieldValue("hide_step_modal") || false,
      disable_email: form.getFieldValue("disable_email_modal") || false,
      disable_interactions: form.getFieldValue("disable_interactions_modal") || false,
      disable_attachment: form.getFieldValue("disable_attachment_modal") || false,
    };

    //send request to update instructions in step
    await updateStep("updateStep", { id: newDataUpdate.key }, newDataUpdate);

    //update steps
    await viewService("viewService", { id: id });

    //close modal
    setIsModalOpen(false);
  };
  //End Update Gatti - 2023-20-08

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setSteps(sortSteps(steps, oldIndex, newIndex));
  };

  const getInstructionTitle = (id) => {
    return fullInstructionsListData?.find((item) => item.id == id).title;
  };

  const SortableItem = SortableElement(({ value }) => (
    <div className={styled.wrapSteps}>
      <span>
        Etapa {value.sort + 1} - {value.title}
      </span>
      <span>{value.description}</span>
      {value.instructions && (
        <ul>
          {value.instructions.map((value, index) => (
            <li key={index}>{getInstructionTitle(value)}</li>
          ))}
        </ul>
      )}
    </div>
  ));

  const SortableList = SortableContainer(({ items }) => {
    return (
      <div style={{ position: "relative" }}>
        {isDisabled && (
          <div
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
              zIndex: 1,
            }}
          />
        )}
        <div style={{ cursor: "grab", margin: "20px 0" }}>
          {items.map((value, index) => (
            <div key={`item-${index}`} className={styled.wrapper}>
              <SortableItem index={index} value={value} />
              {!isDisabled && (
                <div>
                  <a style={{ marginRight: "32px" }} onClick={() => handleSteps(value, index)}>
                    <Edit color="green" />
                  </a>

                  <a onClick={() => removeStep(index)}>
                    <Trash color="red" />
                  </a>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    );
  });

  //add steps to service
  const submit = () => {
    console.log(formValues, steps);
    updateService("updateService", { id: id }, { ...formValues, steps: steps });
    setDisableSteps(true);
    setIsDisabled(true);
  };

  useEffect(() => {
    if (status?.success) {
      message.success(updateMessage);
      navigate("/service-list");
    }
    if (status?.error) {
      if (updateData?.steps?.length > 0) {
        message.error(updateData.steps[0]);
      } else {
        message.error(updateMessage);
      }
    }
  }, [status, form, updateMessage]);

  useEffect(() => {
    viewService("viewService", { id: id });
  }, [id]);

  useEffect(() => {
    if (viewData) {
      form.setFieldValue("title", viewData?.title);
      form.setFieldValue(
        "is_commercialized",
        Boolean(viewData?.is_commercialized)
      );
      setFormValues({
        title: viewData?.title,
        is_commercialized: Boolean(viewData?.is_commercialized),
      });
      setSteps(viewData.steps);
    }
  }, [viewData]);

  return (
    <Wrapper>
      <Card>
        <Form form={form} layout="vertical">
          <Row justify="space-between" gutter={{ sm: 16, xs: 10 }}>
            <Col xs={24} sm={24} flex="center">
              <Form.Item
                name="title"
                label="Nome do serviço"
                rules={[
                  {
                    required: true,
                    message: "Campo obrigatorio!",
                  },
                ]}
              >
                <Input
                  placeholder="Nome do serviço"
                  disabled={true}
                  showCount
                  maxLength={50}
                  size="small"
                  name="title"
                  label="title"

                  onChange={(e) =>
                    setFormValues({
                      ...formValues,
                      title: e.target.value,
                    })
                  }
                />
              </Form.Item>
              <Form.Item>
                <Switch
                  checked={formValues?.is_commercialized}
                  disabled={isDisabled}
                  size="small"
                  onChange={(checked) =>
                    setFormValues({
                      ...formValues,
                      is_commercialized: checked,
                    })
                  }
                />{" "}
                Pode ser comercializado?
              </Form.Item>
            </Col>
          </Row>
          <Row justify="space-between" gutter={{ sm: 16, xs: 10 }}>
            <Col xs={24} sm={24} flex="center">
              <Form.Item
                name="step"
                label="Nome da etapa"
                disabled={isDisabled}
                rules={[
                  () => ({
                    validator(_, value) {
                      if (!value && steps === []) {
                        setDisableSteps(true);
                        return Promise.reject();
                      }
                      setDisableSteps(false);
                      return Promise.resolve();
                    },
                  }),
                  {
                    required: true,
                    message: "Campo obrigatorio!",
                  },
                ]}
              >
                <Input
                  placeholder="Nome da etapa"
                  size="small"
                  showCount
                  maxLength={150}
                  name="step"
                  label="step"
                  disabled={isDisabled}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row justify="space-between" gutter={{ sm: 16, xs: 10 }}>
            <Col xs={24} sm={24} flex="center">
              <Form.Item name="step_description" label="Descrição da etapa">
                <TextArea
                  showCount
                  name="step_description"
                  maxLength={100}
                  placeholder="Descrição da etapa"
                  disabled={isDisabled}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row justify="space-between" gutter={{ sm: 16, xs: 10 }}>
            <Col xs={24} sm={24} flex="center">
              <Form.Item name="instructions" label="Instruções">
                <Select
                  name="instructions"
                  mode="multiple"
                  size="small"
                  showSearch
                  allowClear
                  placeholder="Selecionar Instruções"
                  optionFilterProp="children"
                  disabled={isDisabled}
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                >
                  {optionsInstruction}
                </Select>
              </Form.Item>
            </Col>








            <Col xs={24} sm={24} flex="center">
              <Row justify="space-between" gutter={{ sm: 16, xs: 10 }}>
                <Col xs={24} sm={24} flex="center">
                  <Form.Item name="hide_step" label="Bloquear visualização da etapa pelo cliente">
                    <Switch
                      checked={hideStepView}
                      size="small"
                      disabled={isDisabled}
                      onChange={(checked) => setHideStepView(checked)}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row justify="space-between" gutter={{ sm: 16, xs: 10 }}>
                <Col xs={24} sm={24} flex="center">
                  <Form.Item name="disable_email" label="Bloquear envio de e-mail para o cliente">
                    <Switch
                      checked={disableEmail}
                      size="small"
                      disabled={isDisabled}
                      onChange={(checked) => setDisableEmail(checked)}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row justify="space-between" gutter={{ sm: 16, xs: 10 }}>
                <Col xs={24} sm={24} flex="center">
                  <Form.Item name="disable_interactions" label="Bloquear interações do cliente">
                    <Switch
                      checked={disableInteractions}
                      size="small"
                      disabled={isDisabled}
                      onChange={(checked) => setDisableInteractions(checked)}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row justify="space-between" gutter={{ sm: 16, xs: 10 }}>
                <Col xs={24} sm={24} flex="center">
                  <Form.Item name="disable_attachment" label="Bloquear clientes de anexar arquivos">
                    <Switch
                      checked={disableAttachment}
                      disabled={isDisabled}
                      size="small"
                      onChange={(checked) => setDisableAttachment(checked)}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>






          </Row>
          <Row justify="space-between" gutter={{ sm: 16, xs: 10 }}>
            <Col xs={24} sm={24} flex="center">
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                  block
                  type="primary"
                  className={styled.stepButton}
                  disabled={disableSteps}
                  onClick={pushStep}
                >
                  Adicionar etapa
                </Button>
              </div>
            </Col>
          </Row>

          <SortableList
            items={steps}
            onSortEnd={onSortEnd}
            disableAutoscroll={true}
          />

        </Form>

        <Space size={"middle"} align="center" className={styled.buttonWrapper}>
          <Button
            type={isDisabled ? "primary" : "danger"}
            className={
              isDisabled
                ? "hp-hover-bg-primary-1"
                : "hp-text-color-danger-1 hp-border-color-danger-1 hp-hover-bg-danger-1" +
                " hp-btn-outline"
            }
            onClick={editHandle}
          >
            {isDisabled ? "Editar" : "Cancelar"}
          </Button>

          {!isDisabled && (
            <Button
              type="primary"
              className="hp-btn-outline hp-hover-bg-primary-1"
              loading={updateLoading}
              htmlType="submit"
              onClick={submit}
            >
              Confirmar Edição
            </Button>
          )}
        </Space>
      </Card>

      <Modal title="Editar Etapa" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
        <Form form={form} layout="vertical">
          <Row justify="space-between" gutter={{ sm: 16, xs: 10 }}>
            <Col xs={24} sm={24} flex="center">
              <Form.Item
                name="step_modal"
                label="Nome da etapa"
                disabled={isDisabled}
                rules={[
                  () => ({
                    validator(_, value) {
                      if (!value && steps === []) {
                        setDisableSteps(true);
                        return Promise.reject();
                      }
                      setDisableSteps(false);
                      return Promise.resolve();
                    },
                  }),
                  {
                    required: true,
                    message: "Campo obrigatório!",
                  },
                ]}
              >
                <Input
                  placeholder="Nome da etapa"
                  size="small"
                  showCount
                  maxLength={150}
                  disabled={isDisabled}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row justify="space-between" gutter={{ sm: 16, xs: 10 }}>
            <Col xs={24} sm={24} flex="center">
              <Form.Item name="step_description_modal" label="Descrição da etapa">
                <TextArea
                  showCount
                  maxLength={100}
                  placeholder="Descrição da etapa"
                  disabled={isDisabled}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row justify="space-between" gutter={{ sm: 16, xs: 10 }}>
            <Col xs={24} sm={24} flex="center">
              <Form.Item name="instructions_modal" label="Instruções">
                <Select
                  mode="multiple"
                  size="small"
                  showSearch
                  allowClear
                  placeholder="Selecionar Instruções"
                  optionFilterProp="children"
                  disabled={isDisabled}
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                >
                  {optionsInstruction}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row justify="space-between" gutter={{ sm: 16, xs: 10 }}>
            <Col xs={24} sm={24} flex="center">
              <Form.Item name="hide_step_modal" label="Bloquear visualização da etapa pelo cliente">
                <Switch
                  checked={hideStepViewModal}
                  disabled={isDisabled}
                  size="small"
                  onChange={(checked) => setHideStepViewModal(checked)}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row justify="space-between" gutter={{ sm: 16, xs: 10 }}>
            <Col xs={24} sm={24} flex="center">
              <Form.Item name="disable_email_modal" label="Bloquear envio de e-mail para o cliente">
                <Switch
                  checked={disableEmailModal}
                  disabled={isDisabled}
                  size="small"
                  onChange={(checked) => setDisableEmailModal(checked)}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row justify="space-between" gutter={{ sm: 16, xs: 10 }}>
            <Col xs={24} sm={24} flex="center">
              <Form.Item name="disable_interactions_modal" label="Bloquear interações do cliente">
                <Switch
                  checked={disableInteractionsModal}
                  disabled={isDisabled}
                  size="small"
                  onChange={(checked) => setDisableInteractionsModal(checked)}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row justify="space-between" gutter={{ sm: 16, xs: 10 }}>
            <Col xs={24} sm={24} flex="center">
              <Form.Item name="disable_attachment_modal" label="Bloquear clientes de anexar arquivos">
                <Switch
                  checked={disableAttachmentModal}
                  disabled={isDisabled}
                  size="small"
                  onChange={(checked) => setDisableAttachmentModal(checked)}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>

    </Wrapper>
  );
};
export default ViewService;
