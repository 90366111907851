import { Button, Col, Divider, Popover, Row } from "antd";
import { Link, useNavigate } from "react-router-dom";

import styled from "./styled.module.scss";
import { useAuth } from "../../../providers/auth";
import { useRest } from "../../../services/http";

export default function MenuFooter() {
  const { resetUser, user } = useAuth();
  const { post } = useRest();
  const navigate = useNavigate();

  const logout = () => {
    resetUser();
    post("logout");
    navigate("/login");
  };

  return (
    <Row
      className="hp-sidebar-footer hp-bg-color-dark-90"
      align="middle"
      justify="space-between"
    >
      <Divider className="hp-border-color-black-40 hp-border-color-dark-70 hp-mt-0" />

      <Col>
        <Row align="middle" className={styled.wrapUser}>
          <i className={styled.icon + " ri-user-3-line"} />
          <div className="hp-mt-6 hp-mx-6">
            <p style={{ fontWeight: "normal" }}>{user.username}</p>
            <span>{user.isEmployee ? "Funcionário" : "Cliente"}</span>
          </div>
        </Row>
      </Col>
      <Col>
        {user.isEmployee ? (
          <Popover
            content={
              <div className={styled.settingsLink}>
                <Link to="/profile">Perfil</Link>
                <a href="" onClick={() => logout()}>
                  Sair
                </a>
              </div>
            }
            title="Configurações"
            trigger="hover"
          >
            <i className={styled.icon + " ri-settings-4-fill"} />
          </Popover>
        ) : (
          <Button onClick={() => logout()}>Sair</Button>
        )}
      </Col>
    </Row>
  );
}