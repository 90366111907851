import { Button, Card, Col, Row, Statistic, Table } from "antd";
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import { RiArrowRightLine } from "react-icons/ri";
import { StatusType } from "../listFlow/type";
import UpcomingBirthdays from "../../components/upcomingBirthdays";
import Wrapper from "../../components/layout";
import moment from "moment-timezone";
import styled from "./styled.module.scss";
import { useRest } from "../../services/http";

const Dashboard = () => {
  const {
    get: dashboardTotal,
    data: dashboardTotalData,
    status: dashboardTotalStatus,
  } = useRest();

  const {
    get: dashboardFlows,
    loading: dashboardFlowsLoading,
    data: dashboardFlowsData,
  } = useRest();

  const [tablePaginationFlows, setTablePaginationFlows] = useState({
    current: 1,
    pageSize: 15,
  });

  const {
    get: dashboardEmployeeInteractions,
    loading: dashboardEmployeeInteractionsLoading,
    data: dashboardEmployeeInteractionsData,
  } = useRest();

  const [
    tablePaginationEmployeeInteraction,
    setTablePaginationEmployeeInteraction,
  ] = useState({
    current: 1,
    pageSize: 15,
  });

  const {
    get: dashboardLastInteractions,
    loading: dashboardLastInteractionsLoading,
    data: dashboardLastInteractionsData,
  } = useRest();

  const [tablePaginationLastInteractions, setTablePaginationLastInteractions] =
    useState({
      current: 1,
      pageSize: 15,
    });

  useEffect(() => {
    dashboardFlows("dashboardFlows", {
      page: tablePaginationFlows.current,
    });

    console.log("dashboardFlowsData", dashboardFlowsData);
  }, [tablePaginationFlows?.current]);

  useEffect(() => {
    dashboardTotal("dashboardTotal");
  }, []);

  useEffect(() => {
    dashboardEmployeeInteractions("dashboardEmployeeInteractions", {
      page: tablePaginationEmployeeInteraction.current,
    });
    console.log("dashboardEmployeeInteractionsData", dashboardEmployeeInteractionsData);
  }, [tablePaginationEmployeeInteraction?.current]);

  useEffect(() => {
    dashboardLastInteractions("dashboardInteractions", {
      page: tablePaginationLastInteractions.current,
    });

    console.log("dashboardLastInteractionsData", dashboardLastInteractionsData);

  }, [tablePaginationLastInteractions?.current]);

  const columns = [
    {
      title: "Cliente",
      dataIndex: "customer",
      key: "customer",
      sorter: (a, b) => a.customer.localeCompare(b.customer),
      sortDirections: ["descend"],
    },
    {
      title: "Serviço",
      dataIndex: "service_title",
      key: "service_title",
      sorter: (a, b) => a.service_title.localeCompare(b.service_title),
      sortDirections: ["descend"],
    },
    {
      title: "Etapa",
      dataIndex: "step_title",
      key: "step_title",
      sorter: (a, b) => a.step_title.localeCompare(b.step_title),
      sortDirections: ["descend"],
    },
    {
      title: "Prazo",
      dataIndex: "deadline_date",
      key: "deadline_date",
      sorter: (a, b) =>
        String(a.deadline_date)?.localeCompare(String(b.deadline_date)),
      sortDirections: ["descend"],
      render: (record) =>
        record
          ? moment(record).tz("America/Sao_Paulo").format("DD/MM/YYYY")
          : "-",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      sorter: (a, b) => a.status.localeCompare(b.status),
      render: (record) => StatusType[record],
    },
    {
      title: "Ação",
      key: "action",
      dataIndex: "",
      render: (_, record) => (
        <Link to={"/interaction/" + record?.flow_id}>
          <Button icon={<RiArrowRightLine className="remix-icon" />} onClick={() => handleInteractionClick(record)}>
            
          </Button>
        </Link>
      ),
    },
  ];

  const columnsMinhasInteracoes = [
    {
      title: "Cliente",
      dataIndex: "customer",
      key: "customer",
      sorter: (a, b) => a.customer.localeCompare(b.customer),
      sortDirections: ["descend"],
    },
    {
      title: "Serviço",
      dataIndex: "service_title",
      key: "service_title",
      sorter: (a, b) => a.service_title.localeCompare(b.service_title),
      sortDirections: ["descend"],
    },
    {
      title: "Etapa",
      dataIndex: "step_title",
      key: "step_title",
      sorter: (a, b) => a.step_title.localeCompare(b.step_title),
      sortDirections: ["descend"],
    },
    {
      title: "Prazo",
      dataIndex: "deadline_date",
      key: "deadline_date",
      sorter: (a, b) =>
        String(a.deadline_date)?.localeCompare(String(b.deadline_date)),
      sortDirections: ["descend"],
      render: (record) =>
        record
          ? moment(record).tz("America/Sao_Paulo").format("DD/MM/YYYY")
          : "-",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      sorter: (a, b) => a.status.localeCompare(b.status),
      render: (record) => StatusType[record],
    },
    {
      title: "Ação",
      key: "action",
      dataIndex: "",
      render: (_, record) => (
        <Link to={"/interaction/" + record?.flow_id}>
          <Button icon={<RiArrowRightLine className="remix-icon" />} onClick={() => handleInteractionClick(record)}>
            
          </Button>
        </Link>
      ),
    },
  ];

  const columnsLastInteractions = [
    {
      title: "Cliente",
      dataIndex: "customer",
      key: "customer",
      sorter: (a, b) => a.customer.localeCompare(b.customer),
      sortDirections: ["descend"],
    },
    {
      title: "Serviço",
      dataIndex: "service_title",
      key: "service_title",
      sorter: (a, b) => a.service_title.localeCompare(b.service_title),
      sortDirections: ["descend"],
    },
    {
      title: "Etapa",
      dataIndex: "step_title",
      key: "step_title",
      sorter: (a, b) => a.step_title.localeCompare(b.step_title),
      sortDirections: ["descend"],
    },
    {
      title: "Responsável",
      dataIndex: "assignee",
      key: "assignee",
      sorter: (a, b) => a.assignee.localeCompare(b.assignee),
      sortDirections: ["descend"],
    },
    {
      title: "Prazo",
      dataIndex: "deadline_date",
      key: "deadline_date",
      sorter: (a, b) =>
        String(a.deadline_date)?.localeCompare(String(b.deadline_date)),
      sortDirections: ["descend"],
      render: (record) =>
        record
          ? moment(record).tz("America/Sao_Paulo").format("DD/MM/YYYY")
          : "-",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      sorter: (a, b) => a.status.localeCompare(b.status),
      render: (record) => StatusType[record],
    },
    {
      title: "Ação",
      key: "action",
      dataIndex: "",
      render: (_, record) => (

        <Link to={"/interaction/" + record?.flow_id}>
          <Button icon={<RiArrowRightLine className="remix-icon" />} onClick={() => handleInteractionClick(record)}>
            
          </Button>
        </Link>
   
      ),
    },
  ];

  const handleInteractionClick = (record) => {
    // Logic to open the interaction/step based on the record data
    // For example, you can navigate to a new page or show a modal with interaction details
    console.log("Opening interaction for record:", record);
    // Implement your logic here
  };

  const handleTableChangeFlow = (pagination) => {
    setTablePaginationFlows({
      ...tablePaginationFlows,
      current: pagination.current,
    });
  };

  const handleTableChangeEmployeeInteraction = (pagination) => {
    setTablePaginationEmployeeInteraction({
      ...tablePaginationEmployeeInteraction,
      current: pagination.current,
    });
  };

  const handleTableChangeLastInteractions = (pagination) => {
    setTablePaginationLastInteractions({
      ...tablePaginationLastInteractions,
      current: pagination.current,
    });
  };

  return (
    <Wrapper>
      {dashboardTotalStatus.success && (
        <>
          <Row gutter={[8, 8]}>
            <Col xs={24} sm={12} lg={8}>
              <Card style={{ textAlign: "center", width: "100%" }} className={styled.card}>
                <h4>Clientes</h4>
                <Statistic value={dashboardTotalData?.customers} />
                <span>Clientes cadastrados</span>
              </Card>
            </Col>
            <Col xs={24} sm={12} lg={8}>
              <Card style={{ textAlign: "center", width: "100%" }} className={styled.card}>
                <h4>Prospecção de Clientes</h4>
                <Statistic value={dashboardTotalData?.prospect_customers} />
                <span>Prospecção de Clientes cadastradas</span>
              </Card>
            </Col>
          </Row>
        </>
      )}

      <Card className={styled.card}>
        <h4 className="hp-mb-18">Seus Fluxos</h4>
        <Table
          size="small"
          columns={columns}
          rowKey={(record) => record?.id}
          dataSource={dashboardFlowsData?.data}
          // pagination={tablePaginationFlows}
          pagination={false}
          loading={dashboardFlowsLoading}
          onChange={handleTableChangeFlow}
          scroll={{ x: 800 }}
        />
      </Card>
      <Card className={styled.card}>
        <h4 className="hp-mb-18">Suas Interações</h4>
        <Table
          columns={columnsMinhasInteracoes}
          rowKey={(record) => record?.id}
          dataSource={dashboardEmployeeInteractionsData?.data}
          // pagination={tablePaginationEmployeeInteraction}
          pagination={false}
          loading={dashboardEmployeeInteractionsLoading}
          onChange={handleTableChangeEmployeeInteraction}
          scroll={{ x: 800 }}
          size="small"
        />
      </Card>
      <Card className={styled.card}>
        <h4 className="hp-mb-18">Últimas interações</h4>
        <Table
          columns={columnsLastInteractions}
          rowKey={(_, index) => index}
          dataSource={dashboardLastInteractionsData?.data}
          // pagination={tablePaginationLastInteractions}
          pagination={false}
          loading={dashboardLastInteractionsLoading}
          onChange={handleTableChangeLastInteractions}
          scroll={{ x: 800 }}
          size="small"
        />
      </Card>

      <Card className={styled.card}>
        <h4 className="hp-mb-18">Aniversariantes da Semana</h4>
        <UpcomingBirthdays />
      </Card>
    </Wrapper>
  );
};

export default Dashboard;
