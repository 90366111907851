import { Col, Row } from "antd";

import React from "react";
import styles from "./styled.module.scss";

export default function LeftContent() {
  return (
    <Col
      lg={12}
      span={24}
      className={`${styles["hp-left-content"]} hp-p-0 hp-position-relative`} // Certifique-se de que a classe hp-left-content está aplicada aqui
    >
      <Row className="hp-h-100">
        <Col span={24} className="hp-h-100">
          <div className={styles["hp-full-background"]}></div> {/* A imagem de fundo */}
        </Col>
      </Row>
    </Col>
  );
}
