export const HairColor = {
  BLD: "Loiro (BLD)",
  BLK: "Preto (BLK)",
  BLU: "Azul (BLU)",
  BRO: "Marrom (BRO)",
  GRY: "Cinza (GRY)",
  GRN: "Verde (GRN)",
  HAZ: "Cor de Avelã (HAZ)",
  ONG: "Alaranjado (ONG)",
  PLE: "Roxo (PLE)",
  PNK: "Rosa (PNK)",
  RED: "Vermelho (RED)",
  WHI: "Branco (WHI)",
  XXX: "Outro (XXX)",
};

export const EyeColor = {
  BLK: "Preto (BLK)",
  BLU: "Azul (BLU)",
  BRO: "Marrom (BRO)",
  GRY: "Cinza (GRY)",
  GRN: "Verde (GRN)",
  HAZ: "Cor de Avelã (HAZ)",
  MAR: "Castanho avermelhado (MAR)",
};

export const SkinColor = {
  A: "Asiático (A)",
  B: "Negro (B)",
  I: "Indiano (I)",
  W: "Branco (W)",
  U: "Desconhecido (U)",
};

export const StatusCoren = {
  ENA: "Ativo",
  CAN: "Cancelado",
  REV: "Revogado",
};

export const InstitutionType = {
  ELE: "Ensino Fundamental",
  HIG: "Ensino Médio",
  UNI: "Ensino Superior",
};