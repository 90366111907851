import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Row,
  Select,
  Switch,
  message,
} from "antd";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { useEffect, useState } from "react";

import { Trash } from "iconsax-react";
import Wrapper from "../../components/layout";
import { arrayMoveImmutable } from "array-move";
import styled from "./styled.module.scss";
import { useRest } from "../../services/http";

const { TextArea } = Input;

export const CreateService = () => {
  const [form] = Form.useForm();
  const [steps, setSteps] = useState([]);
  const [disableSteps, setDisableSteps] = useState(true);
  const [optionsInstruction, setOptionsInstruction] = useState([]);


  //Gatti - 2022-20-08
  //handle data steps edit
  const [hideStepView, setHideStepView] = useState(false); //hide steps
  const [disableEmail, setDisableEmail] = useState(false);
  const [disableInteractions, setDisableInteractions] = useState(false);
  const [disableAttachment, setDisableAttachment] = useState(false);
  //End Gatti - 2022-20-08

  const {
    post: createService,
    status,
    loading,
    message: createMessage,
  } = useRest();

  const {
    get: fullInstructionsList,
    loading: fullInstructionsListLoading,
    data: fullInstructionsListData,
  } = useRest();

  const [formValues, setFormValues] = useState({ is_commercialized: true });

  useEffect(() => {
    fullInstructionsList("fullInstructionsList");
  }, []);

  useEffect(() => {
    let instructionArr = fullInstructionsListData?.map((item) => (
      <Select.Option key={item.id}>{item.title}</Select.Option>
    ));

    setOptionsInstruction(instructionArr);
  }, [fullInstructionsListData]);

  useEffect(() => {
    const newOrderWithID = steps.map((item, index) => ({
      ...item,
      sort: index,
    }));
    if (JSON.stringify(steps) !== JSON.stringify(newOrderWithID)) {
      setSteps(newOrderWithID);
    }
  }, [setSteps, steps]);

  useEffect(() => {
    form.getFieldsValue(["step"]);
  }, [form]);

  const sortSteps = (steps, oldIndex, newIndex) => {
    const newOrder = arrayMoveImmutable(steps, oldIndex, newIndex);
    const newOrderWithID = newOrder.map((item, index) => ({
      ...item,
      sort: index,
    }));
    return newOrderWithID;
  };

  const removeStep = (index) => {
    const filtered = steps.filter((_, i) => i !== index);
    setSteps(filtered);
  };

  const pushStep = () => {
    setSteps([
      ...steps,
      {
        title: form.getFieldValue("step"),
        description: form.getFieldValue("step_description") || "",
        instructions: form.getFieldValue("instructions") || [],
        hide_step: form.getFieldValue("hide_step") || false,
        disable_email: form.getFieldValue("disable_email") || false,
        disable_interactions: form.getFieldValue("disable_interactions") || false,
        disable_attachment: form.getFieldValue("disable_attachment") || false,
      },
    ]);
      // Reseta os campos de bloqueio após adicionar uma nova etapa
    setHideStepView(false);
    setDisableEmail(false);
    setDisableInteractions(false);
    setDisableAttachment(false);
  
    form.resetFields(["step", "step_description", "instructions"]);
    setDisableSteps(true);
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setSteps(sortSteps(steps, oldIndex, newIndex));
  };

  const getInstructionTitle = (id) => {
    return fullInstructionsListData?.find((item) => item.id == id).title;
  };

  const SortableItem = SortableElement(({ value }) => (
    <div className={styled.wrapSteps}>
      <span>
        Etapa {value.sort + 1} - {value.title}
      </span>
      <span>{value.description}</span>
      <ul>
        {value.instructions.map((value, index) => (
          <li key={index}>{getInstructionTitle(value)}</li>
        ))}
      </ul>
    </div>
  ));

  const SortableList = SortableContainer(({ items }) => {
    return (
      <div style={{ cursor: "grab", margin: "20px 0" }}>
        {items.map((value, index) => (
          <div key={`item-${index}`} className={styled.wrapper}>
            <SortableItem index={index} value={value} />
            <a onClick={() => removeStep(index)}>
              <Trash color="red" />
            </a>
          </div>
        ))}
      </div>
    );
  });

  const submit = () => {
    createService("createService", { ...formValues, steps: steps });
  };

  useEffect(() => {
    if (status?.success) {
      message.success(createMessage);
      form.resetFields();
      setSteps([]);
      setDisableSteps(true);

    }
    if (status?.error) {
      message.error(createMessage);
    }
  }, [status, form, createMessage]);

  return (
    <Wrapper>
      <Card>
        <Form form={form} layout="vertical">
          <Row justify="space-between" gutter={{ sm: 16, xs: 10 }}>
            <Col xs={24} sm={24} flex="center">
              <Form.Item
                name="name"
                label="Nome do serviço"
                rules={[
                  {
                    required: true,
                    message: "Campo obrigatorio!",
                  },
                ]}
              >
                <Input
                  placeholder="Nome do serviço"
                  showCount
                  maxLength={50}
                  size="small"
                  name="name"
                  label="name"
                  onChange={(e) =>
                    setFormValues({
                      ...formValues,
                      title: e.target.value,
                    })
                  }
                />
              </Form.Item>
              <Form.Item>
                <Switch
                  checked={formValues?.is_commercialized}
                  onChange={(checked) =>
                    setFormValues({
                      ...formValues,
                      is_commercialized: checked,
                    })
                  }
                />{" "}
                Pode ser comercializado?
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} flex="center">
              <Form.Item
                name="step"
                label="Nome da etapa"
                rules={[
                  () => ({
                    validator(_, value) {
                      if (!value && steps === []) {
                        setDisableSteps(true);
                        return Promise.reject();
                      }
                      setDisableSteps(false);
                      return Promise.resolve();
                    },
                  }),
                  {
                    required: true,
                    message: "Campo obrigatorio!",
                  },
                ]}
              >
                <Input
                  placeholder="Nome da etapa"
                  showCount
                  size="small"
                  maxLength={150}
                  name="step"
                  label="step"
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} flex="center">
              <Form.Item name="step_description" label="Descrição da etapa">
                <TextArea
                  showCount
                  size="small"
                  name="step_description"
                  maxLength={100}
                  style={{ height: 120 }}
                  placeholder="Descrição da etapa"
                />
              </Form.Item>
            </Col>



          {/* <Row justify="space-between" gutter={{ sm: 16, xs: 10 }}> */}
            <Col xs={24} sm={24} flex="center">
            <Row justify="space-between" gutter={{ sm: 16, xs: 10 }}>
            <Col xs={24} sm={24} flex="center">
              <Form.Item name="instructions" label="Instruções">
                <Select
                  name="instructions"
                  mode="multiple"
                  showSearch
                  size="small"
                  allowClear
                  placeholder="Selecionar Instruções"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                >
                  {optionsInstruction}
                </Select>
              </Form.Item>
              </Col>
            </Row>
            </Col>
          {/* </Row> */}



            <Col xs={24} sm={24} flex="center">
              <Row justify="space-between" gutter={{ sm: 16, xs: 10 }}>
                <Col xs={24} sm={24} flex="center">
                  <Form.Item name="hide_step" label="Bloquear visualização da etapa pelo cliente">
                    <Switch
                      checked={hideStepView}
                      size="small"
                      isDiabled={hideStepView}
                      onChange={(checked) => setHideStepView(checked)}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row justify="space-between" gutter={{ sm: 16, xs: 10 }}>
                <Col xs={24} sm={24} flex="center">
                  <Form.Item name="disable_email" label="Bloquear envio de e-mail para o cliente">
                    <Switch
                      checked={disableEmail}
                      size="small"
                      isDiabled={disableEmail}
                      onChange={(checked) => setDisableEmail(checked)}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row justify="space-between" gutter={{ sm: 16, xs: 10 }}>
                <Col xs={24} sm={24} flex="center">
                  <Form.Item name="disable_interactions" label="Bloquear interações do cliente">
                    <Switch
                      checked={disableInteractions}
                      size="small"
                      isDiabled={disableInteractions}
                      value={disableInteractions}
                      onChange={(checked) => setDisableInteractions(checked)}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row justify="space-between" gutter={{ sm: 16, xs: 10 }}>
                <Col xs={24} sm={24} flex="center">
                  <Form.Item name="disable_attachment" label="Bloquear clientes de anexar arquivos">
                    <Switch
                      checked={disableAttachment}
                      isDiabled={disableAttachment}
                      size="small"
                      onChange={(checked) => setDisableAttachment(checked)}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col xs={24} sm={24} flex="center">
              <Button
                block
                type="primary"
                className={styled.stepButton}
                disabled={disableSteps}
                onClick={pushStep}
              >
                Adicionar etapa
              </Button>
            </Col>
          </Row>
         
<SortableList
items={steps}
onSortEnd={onSortEnd}
disableAutoscroll={true}
/>
        </Form>

        {steps.length > 0 && (
          <Button
            block
            type="primary"
            htmlType="submit"
            className={styled.stepButton}
            onClick={submit}
            loading={loading}
            disabled={!formValues?.title}
          >
            Criar Serviço
          </Button>




        )}
      </Card>
    </Wrapper>
  );
};
export default CreateService;
