import {
    Alert,
    Button,
    Card,
    Col,
    Form,
    Input,
    Row,
    message
} from "antd";
import React, { useEffect, useState } from "react";

import Wrapper from "../../components/layout";
import { useRest } from "../../services/http";

const CreateAdvantageClubCategory = () => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [formState, setFormState] = useState();
    const {
        post: createAdvantageClubCategory,
        data: createAdvantageClubCategoryData,
        message: createAdvantageClubCategoryMessage,
        status: createAdvantageClubCategoryStatus,
    } = useRest();


    const submit = () => {
        console.log("formState", formState);
        createAdvantageClubCategory("createAdvantageClubCategory", formState, createAdvantageClubCategoryData);
    };

    useEffect(() => {
        if (createAdvantageClubCategoryStatus?.success) {
            message.success("Categoria criada com sucesso!");
            form.resetFields();
            setFormState({});
        }
    }, [createAdvantageClubCategoryStatus, form, createAdvantageClubCategoryMessage,]);

    return (
        <Wrapper>
            <Card style={{ width: "50%" }}>
                <Form form={form} layout="vertical">
                    {createAdvantageClubCategoryData?.length < 1 && createAdvantageClubCategoryStatus !== 201 && (
                        <Alert
                            message="Dados incorretos:"
                            description={createAdvantageClubCategoryMessage}
                            type="error"
                        />
                    )}
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                label="Nome"
                                name="name"
                                onChange={(e) =>
                                    setFormState({ ...formState, name: e.target.value })
                                }
                                rules={[{ required: true, message: "Por favor, insira o nome da categoria!" }]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label="Descrição"
                                name="description"
                                onChange={(e) =>
                                    setFormState({ ...formState, description: e.target.value })
                                }
                                rules={[{ required: true, message: "Por favor, insira a descrição da categoria!" }]}
                            >
                                <Input.TextArea />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" loading={loading} onClick={submit}>
                            Criar Categoria
                        </Button>
                    </Form.Item>
                </Form>
            </Card>
        </Wrapper>
    );
};

export default CreateAdvantageClubCategory;
