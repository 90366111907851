import { Button, Card, Col, Modal, Row, Typography } from "antd";
import React, { useState } from "react";

import { Link } from "react-router-dom";
import moment from "moment-timezone";
import styled from "./styled.module.scss";

const { Text } = Typography;

const AdvantageClubCard = ({ data }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedClub, setSelectedClub] = useState(null);

  const handleViewDetails = (club) => {
    setSelectedClub(club);
    setModalVisible(true);
  };

  const handleModalCancel = () => {
    setModalVisible(false);
  };

  return (
    <Row gutter={16}>
      {data.map((record) => (
        <Col span={8} key={record.id}>
          <Card
            className={styled['advantage-club-card']}
            style={{ marginBottom: "16px" }}
            title={record.title}
          >
            <img
              src={record.image_attachment ? process.env.REACT_APP_API_URL_IMG + record.image_attachment.path : ""}
              alt="Imagem da categoria"
              className={styled['card-image']}
            />

            <div className={styled['view-details-link']}>
              <Button onClick={() => handleViewDetails(record)}>
                Ver Detalhes
              </Button>
            </div>

          </Card>
        </Col>
      ))}



      {/* Modal para mostrar os detalhes */}
      <Modal
        title={selectedClub?.title}
        visible={modalVisible}
        onCancel={handleModalCancel}
        footer={null}
      >
        {/* Adicione aqui o conteúdo do modal com os detalhes */}
        {selectedClub && (
          <div>
            {/* <div className={styled["banner-advantage-club"]}>
            <img
              src={selectedClub.image_attachment ? process.env.REACT_APP_API_URL_IMG + selectedClub.image_attachment.path : ""}
              alt="Imagem da categoria"
              className={styled["image-modal"]}
            />
            </div> */}
            <div
              className={styled['card-content']}
              dangerouslySetInnerHTML={{ __html: selectedClub.description }} />
      
          </div>
        )}
      </Modal>



    </Row>
  );
};

export default AdvantageClubCard;
