import { Form, Input, Row, Col, Select, Button, message, Space } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import cepPromise from "cep-promise";
import { useState, useEffect, useMemo } from "react";
import { BrazilianStates } from "../../../enums/brazilianStates";
import { Country } from "../../../enums/country";
import InputMask from "react-input-mask";
import "react-phone-input-2/lib/style.css";

const Address = ({ setData, defaultData, form, setEmpty, convertData }) => {
  const [formValues, setFormValues] = useState({
    country: defaultData.country || convertData?.country || "Brasil",
    cep: defaultData.cep || convertData?.cep || "",
    uf: defaultData.uf || convertData?.uf || "",
    city: defaultData.city || convertData?.city || "",
    street: defaultData.street || convertData?.street || "",
    street_complement:
      defaultData.street_complement || convertData?.street_complement || "",
    street_number:
      defaultData.street_number || convertData?.street_number || "",
  });

  useEffect(() => {
    setEmpty(
      formValues.country === "" ||
        formValues.cep === "" ||
        formValues.uf === "" ||
        formValues.city === "" ||
        formValues.street === ""
    );
    setData(formValues);
  }, [formValues, setData, setEmpty]);

  const getAddress = async () => {
    try {
      const address = await cepPromise(formValues.cep);
      setFormValues({
        ...formValues,
        uf: address.state,
        city: address.city,
        street: address.street,
      });
      form.setFieldValue("uf-address", address.state);
      form.setFieldValue("city-address", address.city);
      form.setFieldValue("street-address", address.street);
    } catch (e) {
      message.error("CEP não encontrado!");
    }
  };

  const CountryOptions = Country.map((item) => (
    <Select.Option key={item.nome_pais}>{item.nome_pais}</Select.Option>
  ));

  const UFIndex = BrazilianStates.states.findIndex(
    (x) => x.uf === formValues.uf
  );

  const UFOptions = BrazilianStates.states.map((item) => (
    <Select.Option key={item.uf}>{item.name}</Select.Option>
  ));

  const CityOptions = BrazilianStates?.states[UFIndex]?.citys.map((item) => (
    <Select.Option key={item}>{item}</Select.Option>
  ));

  const isBrasil = useMemo(() => {
    return formValues.country === "Brasil";
  }, [formValues]);

  return (
    <>
      <Row justify="space-between" gutter={{ sm: 16, xs: 10 }}>
        <Col xs={24} sm={6}>
          <Form.Item
            label="País"
            name="country"
            initialValue={formValues.country}
            rules={[
              {
                required: true,
                message: "Campo obrigatório!",
              },
            ]}
          >
            <Select
              showSearch
              allowClear
              size="small"
              placeholder="País"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
              onChange={(value) =>
                setFormValues({ ...formValues, country: value })
              }
            >
              {CountryOptions}
            </Select>
          </Form.Item>
        </Col>

        <Col xs={24} sm={6}>
          <Form.Item>
            <Space>
              <Form.Item
                label={isBrasil ? "CEP" : "Código Postal"}
                name="cep"
                initialValue={formValues.cep}
                rules={[
                  {
                    required: true,
                    message: "Campo obrigatorio!",
                  },
                ]}
              >
                <InputMask
                  mask={isBrasil ? "99999-999" : null}
                  onChange={(e) =>
                    setFormValues({
                      ...formValues,
                      cep: e.target.value.replace(/[^\d]/g, ""),
                    })
                  }
                >
                  <Input  size="small"/>
                </InputMask>
              </Form.Item>

              {isBrasil && (
                <Button
                  style={{ marginTop: "6px" }}
                  size="small"
                  type="primary"
                  icon={<SearchOutlined />}
                  onClick={getAddress}
                />
              )}
            </Space>
          </Form.Item>
        </Col>
        <Col xs={24} sm={6}>
          {isBrasil ? (
            <Form.Item
              label="Estado"
              name="uf-address"
              initialValue={formValues.uf}
              rules={[
                {
                  required: true,
                  message: "Campo obrigatório!",
                },
              ]}
            >
              <Select
                showSearch
                size="small"
                allowClear
                placeholder="Estado"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children
                    .toLowerCase()
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")
                    .indexOf(
                      input
                        .toLowerCase()
                        .normalize("NFD")
                        .replace(/[\u0300-\u036f]/g, "")
                    ) >= 0
                }
                onChange={(value) =>
                  setFormValues({
                    ...formValues,
                    uf: value,
                    city: "",
                  })
                }
              >
                {UFOptions}
              </Select>
            </Form.Item>
          ) : (
            <Form.Item
              label="Estado"
              name="uf-address"
              initialValue={formValues.uf}
              onChange={(e) =>
                setFormValues({ ...formValues, uf: e.target.value })
              }
              rules={[
                {
                  required: true,
                  message: "Campo obrigatório!",
                },
              ]}
            >
              <Input  size="small"/>
            </Form.Item>
          )}
        </Col>
        <Col xs={24} sm={6}>
          {isBrasil ? (
            <Form.Item
              label="Cidade"
              name="city-address"
              initialValue={formValues.city}
              rules={[
                {
                  required: true,
                  message: "Campo obrigatório!",
                },
              ]}
            >
              <Select
                showSearch
                 size="small"
                allowClear
                placeholder="Cidade"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children
                    .toLowerCase()
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")
                    .indexOf(
                      input
                        .toLowerCase()
                        .normalize("NFD")
                        .replace(/[\u0300-\u036f]/g, "")
                    ) >= 0
                }
                disabled={!formValues.uf}
                onChange={(value) =>
                  setFormValues({ ...formValues, city: value })
                }
              >
                {CityOptions || []}
              </Select>
            </Form.Item>
          ) : (
            <Form.Item
              label="Cidade"
              name="city-address"
              initialValue={formValues.city}
              onChange={(e) =>
                setFormValues({ ...formValues, city: e.target.value })
              }
              rules={[
                {
                  required: true,
                  message: "Campo obrigatório!",
                },
              ]}
            >
              <Input  size="small"/>
            </Form.Item>
          )}
        </Col>
      </Row>
      <Row justify="space-between" gutter={{ sm: 16, xs: 10 }}>
        <Col xs={24} sm={12}>
          <Form.Item
            label="Rua"
            name="street-address"
            initialValue={formValues.street}
            onChange={(e) =>
              setFormValues({ ...formValues, street: e.target.value })
            }
            rules={[
              {
                required: true,
                message: "Campo obrigatório!",
              },
            ]}
          >
            <Input  size="small"/>
          </Form.Item>
        </Col>
        <Col xs={24} sm={4}>
          <Form.Item
            label="Número"
            name="street_number-address"
            initialValue={formValues.street_number}
            onChange={(e) =>
              setFormValues({ ...formValues, street_number: e.target.value })
            }
          >
            <Input  size="small"/>
          </Form.Item>
        </Col>
        <Col xs={24} sm={8}>
          <Form.Item
            label="Complemento"
            name="street_complement-address"
            initialValue={formValues.street_complement}
            onChange={(e) =>
              setFormValues({
                ...formValues,
                street_complement: e.target.value,
              })
            }
          >
            <Input  size="small"/>
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};

export default Address;
