import {
  Button,
  Col,
  Input,
  Pagination,
  Popconfirm,
  Row,
  Spin,
  message,
} from "antd";
import { EyeInvisibleOutlined, EyeOutlined, PushpinFilled, PushpinOutlined } from "@ant-design/icons";
import { Permissions, hasPermission } from "../../router/permissions";
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import { getUserID } from "../../util/jwtUtil";
import moment from "moment-timezone";
import styled from "./styled.module.scss";
import { useAuth } from "../../providers/auth";
import { useParams } from "react-router-dom";
import { useRest } from "../../services/http";

const Messages = ({ update, onUpdate, flowStatus }) => {
  const { user } = useAuth();
  const { id } = useParams();
  const [isChanging, setIsChanging] = useState(null);
  const [newMessage, setNewMessage] = useState("");
  const { get, loading, data } = useRest();

  const { put, remove, status, message: messageInteraction } = useRest();

  const [tablePagination, setTablePagination] = useState({
    current: 1,
    pageSize: 15,
  });

  //Carlos Gatti - 2023-07-11
  //Create a new state to handle the pinned interactions
  const { put: toggleInteraction } = useRest();

  const [pinnedInteractions, setPinnedInteractions] = useState([]);
  //handle pined 
  const handlePinInteraction = async (interactionId) => {

    if (pinnedInteractions.includes(interactionId)) {
      // A interação já está fixada, então vamos removê-la da lista
      setPinnedInteractions(pinnedInteractions.filter((id) => id !== interactionId));
      await toggleInteraction("toggleInteraction", { id: interactionId }, { pinned: !pinnedInteractions.includes(interactionId) });
    } else {
      // A interação não está fixada, vamos adicioná-la à lista
      setPinnedInteractions([...pinnedInteractions, interactionId]);
      await toggleInteraction("toggleInteraction", { id: interactionId }, { pinned: !pinnedInteractions.includes(interactionId) });
    }
    //atualiza as interacoes
    get("flowInteractions", {
      page: tablePagination.current,
      flowId: id,
      pageSize: tablePagination.pageSize,
    });
  };
  //End Carlos Gatti - 2023-07-11


  //Carlos Gatti - 2023-07-25
  //Create a new state to handle the pinned interactions
  const { put: markAsRead, data: dataMarkAsRead } = useRest();

  const handleMarkAsRead = async (interactionId) => {
    // Marca a interação como lida ou não lida
    await markAsRead("markAsRead", { id: interactionId }, { is_read: !data.data.find((item) => item.id === interactionId).is_read });
    // Atualize as interações para exibir a mudança em tempo real
    await get("flowInteractions", {
      page: tablePagination.current,
      flowId: id,
      pageSize: tablePagination.pageSize,
    });

    if (dataMarkAsRead?.data?.is_read) {
      message.success("A interação foi marcada como não lida!");
    } else if (!dataMarkAsRead?.data?.is_read) {
      message.success("A interação foi marcada como lida!");
    }
  };

  const { postID: resendInteraction, status: resendInteractionStatus } =
    useRest();

  const deleteInteraction = (interactionId) => {
    remove("interaction", { id: interactionId });
  };

  useEffect(() => {
    get("flowInteractions", {
      page: tablePagination.current,
      flowId: id,
      pageSize: tablePagination.pageSize,
    });
  }, [tablePagination?.current, update]);

  useEffect(() => {
    if (data?.data?.length > 0) {
      //Carlos Gatti - 2023-07-11
      //Adiciona um novo componente para exibir as interações fixadas
      //Cria um array com as interações fixadas
      const pinnedInteractions = data?.data?.filter((item) => item.pinned).map((item) => item.id);
      setPinnedInteractions(pinnedInteractions);
    }
  }, [data]);

  useEffect(() => {
    if (status.success) {
      message.success(messageInteraction);
      setIsChanging(null);
      setNewMessage("");
      onUpdate();
      get("flowInteractions", {
        page: tablePagination.current,
        flowId: id,
        pageSize: tablePagination.pageSize,
      });

      console.log("status", status, data);

    } else if (status.error) {
      message.error(messageInteraction);
    }
  }, [status]);

  useEffect(() => {
    if (resendInteractionStatus.success) {
      message.success("O cliente foi re-notificado!");
    } else if (resendInteractionStatus.error) {
      message.error(
        "Ocorreu um erro ao re-notificar o cliente. Tente novamente!"
      );
    }
  }, [resendInteractionStatus]);

  const paginationChange = (current) => {
    setTablePagination({ ...tablePagination, current: current });
  };

  const resend = (interactionId) => {
    resendInteraction("resendInteraction", { id: interactionId });
  };

  const canEditOrExclude = (item, permission) => {
    if (
      parseInt(getUserID(user.access_token)) === item.user_id &&
      isChanging !== item.id &&
      hasPermission(user, permission)
    ) {
      if (user.isEmployee) {
        return true;
      } else {
        if (flowStatus === "FIN") {
          return false;
        } else {
          return true;
        }
      }
    }

    return false;
  };

  const canPin = (item) => {
    if (
      parseInt(getUserID(user.access_token)) === item.user_id &&
      isChanging !== item.id &&
      hasPermission(user, Permissions.cadastrar_interacoes)
    ) {

      if (user.isEmployee) {
        return true;
      }

    }

    return false;
  };

  const canMarkAsRead = (item) => {
    // Verifica se o usuário é funcionário, tem permissão, e o autor da mensagem é o cliente
    return (
      user.isEmployee &&
      hasPermission(user, Permissions.cadastrar_interacoes) &&
      parseInt(getUserID(user.access_token)) !== item.user_id &&
      item.is_customer // Garante que o autor é o cliente
    );
  };
  

  const isAssignedToUser = (item) => {
    if (!item.is_assignee) {
      return false;
    }
    return true;
  };

  const isAdmin = (item) => {
    if (item.is_admin) {
      return true;
    }
    return false;
  };

  const changeMessage = (item, message) => {
    if (message) {
      put(
        "updateMessage",
        { id: item.id },
        {
          message: message,
        }
      );
    }

    setNewMessage("");
    setIsChanging(null);
  };

  const isEmployeeOrAdmin = user.isEmployee || user.role === 1;

  return loading ? (
    <Row type="flex" align="middle" justify="center">
      <Col>
        <Spin />
      </Col>
    </Row>
  ) : (
    <>
      <div className={styled.chatWrapper}>
        {data?.data
          ?.filter((item) => pinnedInteractions.includes(item.id))
          .map((item, index) => (
            <div
              className={`${styled.chatCard} ${styled.pinned}`}
              key={index}
            >

              <>
                <div className={styled.heading}>
                  <p>Mensagem em Destaque</p>
                </div>
                <div>

{/* 
                  <div className={styled.cardDescription} >
                    {
                      <a href={`#${item.id}`}>{item.message.replace(/(<([^>]+)>)/gi, "")}</a>
                 
                    }
                  </div> */}

                  <div
                    className={styled.cardDescription}
                    dangerouslySetInnerHTML={{ __html: item.message }}
                  />



                  <div className={styled.cardBottom}>
                    <span className={styled.privateTag}>
                      {Boolean(item.is_private) && "Essa mensagem é privada!"}
                    </span>

                    <span className={styled.date}>
                      Última atualização:
                      <br />
                      {moment(item.updated_at)
                        .tz("America/Sao_Paulo")
                        .format("DD/MM/YYYY - HH:mm")}
                    </span>
                  </div>
                </div>
              </>
            </div>

          ))}
      </div>

      {data?.data?.length > 0 && (
        <div className={styled.chatWrapper}>
          {Object.values(data?.data)?.map((item, index) => (
            <div
              id={item.id}
              className={`
              ${styled.chatCard} 
              ${Boolean(item.is_private) ? styled.private : ""} 
              ${user.isEmployee ? (
                  isEmployeeOrAdmin && item.is_customer && !item.is_read ? styled.messageUnread : ""
                ) : ""
                }
              `}
              key={index}
            >
              <div className={styled.heading}>
                <p>{item.user?.name || "Usuário"}</p>
                <div>


                  {
canMarkAsRead(item) && (
                      <a
                        className={styled.icon}
                        onClick={() => handleMarkAsRead(item.id)}
                      >
                        {data.data[index].is_read ? (
                          <EyeOutlined
                            title="Marcar como não lida"
                          />
                        ) : (
                          <EyeInvisibleOutlined
                            title="Marcar como lida"
                          />
                        )}
                      </a>
                    )
                  }



                  {canPin(item) && (
                    <a
                      className={styled.icon}
                      onClick={() => handlePinInteraction(item.id)}
                    >
                      {data.data[index].pinned ? (
                        <PushpinFilled
                          title="Desafixar interação"
                        />
                      ) : (
                        <PushpinOutlined

                          title="Fixar interação"
                        />
                      )}
                    </a>
                  )}

                  {canEditOrExclude(item, Permissions.cadastrar_interacoes) && (
                    <a
                      className={`${styled.icon} ${styled.pinIcon}`}
                      onClick={() => setIsChanging(item.id)}
                    >
                      <i className="ri-edit-fill" title="Editar interação" />
                    </a>
                  )}
                  {canEditOrExclude(item, Permissions.remover_interacoes) && (
                    <Popconfirm
                      title={
                        "Tem certeza que deseja deletar essa interação?"
                      }
                      onConfirm={() => deleteInteraction(item.id)}
                      okText="Confirmar"
                      cancelText="Cancelar"
                    >
                      <a className={`${styled.icon} ${styled.pinIcon}`}>
                        <i
                          className={styled.iconDelete + " ri-delete-bin-fill"}
                          title="Deletar interação"
                        />
                      </a>
                    </Popconfirm>
                  )}
                  {parseInt(getUserID(user.access_token)) === item.user_id &&
                    user.isEmployee &&
                    !item.is_private &&
                    hasPermission(user, Permissions.cadastrar_interacoes) && (
                      <a
                        className={`${styled.icon} ${styled.pinIcon}`}
                        onClick={() => resend(item.id)}
                      >
                        <i
                          className="ri-mail-send-fill"
                          title="Renotificar Cliente"
                        />
                      </a>
                    )}


                </div>
              </div>
              {isChanging === item.id ? (
                <>
                  {user.isEmployee ? (
                    <CKEditor
                      data={item.message || ""}
                      onChange={(events, editor) => {
                        const data = editor.getData();
                        setNewMessage(data);
                      }}
                      editor={Editor}
                    />
                  ) : (
                    <Input.TextArea
                      defaultValue={item.message || ""}
                      onChange={(e) => setNewMessage(e.target.value)}
                    />
                  )}

                  <br />

                  <Button onClick={() => changeMessage(item, newMessage)}>
                    Atualizar
                  </Button>
                  <br />
                  <br />
                </>
              ) : (
                <>
                  <div
                    className={styled.cardDescription}
                    dangerouslySetInnerHTML={{ __html: item.message }}
                  />

                  <div className={styled.cardBottom}>
                    <span className={styled.privateTag}>
                      {Boolean(item.is_private) && "Essa mensagem é privada!"}
                    </span>


                    {item.read_by && user.isEmployee && (

                      <span className={styled.readInfo}>
                        Lida por: {item.read_by_name} em {moment(item.read_at).format("DD/MM/YYYY - HH:mm")}
                      </span>
                    ) || (
                        <span className={styled.readInfo}>
                        </span>
                      )
                    }


                    <span className={styled.date}>
                      Última atualização:
                      <br />
                      {moment(item.updated_at)
                        .tz("America/Sao_Paulo")
                        .format("DD/MM/YYYY - HH:mm")}
                    </span>
                  </div>
                  {item?.attachments?.length > 0 && (
                    <>
                      <hr />
                      <p style={{ padding: "0 10px" }}>
                        Anexos:{" "}
                        {item.attachments.map((attachment) => (
                          <p key={attachment.alias}>
                            {attachment.alias
                              ? attachment.alias
                              : attachment.name}
                          </p>
                        ))}
                      </p>
                    </>
                  )}
                </>
              )}



            </div>
          ))}

        </div>

      )}

      {data?.last_page !== 1 && (
        <Pagination
          defaultCurrent={tablePagination.current}
          defaultPageSize={tablePagination.pageSize}
          total={data?.total}
          onChange={paginationChange}
        />
      )}
    </>
  );
};

export default Messages;
