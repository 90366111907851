import { Navigate, useLocation, useNavigate } from "react-router-dom";
import {
  checkFirstLogin,
  getCustomerID,
  getName,
  getPermissions,
  getRole,
  getUserName,
} from "../util/jwtUtil";
import { getExpiresDate, isExpired } from "../util/expireDate";

import Unauthorized from "../pages/unauthorized";
import { useAuth } from "../providers/auth";
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useRest } from "../services/http";

const PrivateRoute = ({ allowedPermissions, children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { post: refresh, data, status } = useRest();
  const { user, setUser, resetUser } = useAuth();
  const isAuth = !isExpired(user?.expiresDate);

  useEffect(() => {
    if (isAuth && checkFirstLogin(user?.access_token)) {
      navigate("/change-password");
    }

    if (data?.access_token && status.success) {
      setUser({
        access_token: data?.access_token,
        token_type: data?.token_type,
        expires_in: data?.expires_in,
        expiresDate: getExpiresDate(data?.expires_in),
        role: getRole(data?.access_token),
        username: getUserName(data?.access_token),
        name: getName(data?.access_token),
        customerID: getCustomerID(data?.access_token) || "",
        isEmployee: getRole(data?.access_token) !== 1,
        permissions: getPermissions(data?.access_token),
      });
    }

    if (user.expiresData && isExpired(user.expiresDate) && !status.error) {
      refresh("refresh");
    }

    if (!isAuth && status.error) {
      resetUser();
      navigate("/login", {
        state: { from: location },
      });
    }

    console.log("user", user);

    // Redirecionamento com base no tipo de usuário
    if (isAuth && user?.role) {
      if (user.isEmployee) {
        navigate("/dashboard"); // Todos os funcionários e admin vão para Dashboard
      } else {
        navigate("/flow-list"); // Clientes vão para FlowList
      }
    }

  }, [user, data]);

  const hasPermission = () => {
    return (
      allowedPermissions.length === 0 ||
      allowedPermissions.every((permission) =>
        user?.permissions.includes(permission)
      )
    );
  };

  return !isExpired(user?.expiresDate) && hasPermission() ? (
    children
  ) : !isExpired(user?.expiresDate) ? (
    <Unauthorized />
  ) : (
    <Navigate to="/login" state={{ from: location }} />
  );
};

export default PrivateRoute;
