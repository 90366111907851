/* eslint-disable react-hooks/exhaustive-deps */
import { Form, Input, Row, Col, Select, Button, Space, message } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import cepPromise from "cep-promise";
import { useState, useEffect, useMemo } from "react";
import { BrazilianStates } from "../../../enums/brazilianStates";
import { Country } from "../../../enums/country";
import InputMask from "react-input-mask";
import "react-phone-input-2/lib/style.css";

const Employer = ({ defaultData, setData }) => {
  const [form] = Form.useForm();
  const [formValues, setFormValues] = useState(defaultData);

  useEffect(() => {
    form.setFieldValue("company_name", defaultData?.company_name);
    form.setFieldValue("boss_name", defaultData?.boss_name);
    form.setFieldValue("job_role", defaultData?.job_role);
    form.setFieldValue("country", defaultData?.country);
    form.setFieldValue("cep", defaultData?.cep);
    form.setFieldValue("uf", defaultData?.uf);
    form.setFieldValue("city", defaultData?.city);
    form.setFieldValue("street", defaultData?.street);
    form.setFieldValue("street_number", defaultData?.street_number);
    form.setFieldValue("street_complement", defaultData?.street_complement);
    form.setFieldValue("start_date", defaultData?.start_date);
    form.setFieldValue("end_date", defaultData?.end_date);

    setFormValues(defaultData);
  }, [defaultData]);

  useEffect(() => {
    setData(formValues);
  }, [formValues]);

  const getAddress = async () => {
    try {
      const address = await cepPromise(formValues.cep);
      setFormValues({
        ...formValues,
        uf: address.state,
        city: address.city,
        street: address.street,
      });
      form.setFieldValue("uf", address.state);
      form.setFieldValue("city", address.city);
      form.setFieldValue("street", address.street);
    } catch (e) {
      message.error("CEP não encontrado!");
    }
  };

  const CountryOptions = Country.map((item) => (
    <Select.Option key={item.nome_pais}>{item.nome_pais}</Select.Option>
  ));

  const UFIndex = BrazilianStates.states.findIndex(
    (x) => x?.uf === formValues?.uf
  );

  const UFOptions = BrazilianStates.states.map((item) => (
    <Select.Option key={item?.uf}>{item.name}</Select.Option>
  ));

  const CityOptions = BrazilianStates?.states[UFIndex]?.citys.map((item) => (
    <Select.Option key={item}>{item}</Select.Option>
  ));

  const isBrasil = useMemo(() => {
    return formValues.country === "Brasil";
  }, [formValues]);

  return (
    <Form form={form} layout="vertical">
      <Row justify="space-between" gutter={{ sm: 16, xs: 10 }}>
        <Col xs={24} sm={8}>
          <Form.Item
            label="Nome do Empregador"
            name="company_name"
            onChange={(e) =>
              setFormValues({ ...formValues, company_name: e.target.value })
            }
            rules={[
              {
                required: true,
                message: "Campo obrigatorio!",
              },
            ]}
          >
            <Input size="small"/>
          </Form.Item>
        </Col>
        <Col xs={24} sm={8}>
          <Form.Item
            label="Nome do Chefe"
            name="boss_name"
            onChange={(e) =>
              setFormValues({ ...formValues, boss_name: e.target.value })
            }
            rules={[
              {
                required: true,
                message: "Campo obrigatorio!",
              },
            ]}
          >
            <Input size="small"/>
          </Form.Item>
        </Col>

        <Col xs={24} sm={8}>
          <Form.Item
            label="Cargo"
            name="job_role"
            onChange={(e) =>
              setFormValues({ ...formValues, job_role: e.target.value })
            }
            rules={[
              {
                required: true,
                message: "Campo obrigatorio!",
              },
            ]}
          >
            <Input size="small"/>
          </Form.Item>
        </Col>
      </Row>

      <Row justify="space-between" gutter={{ sm: 16, xs: 10 }}>
        <Col xs={24} sm={6}>
          <Form.Item
            label="País"
            name="country"
            initialValue={formValues.country}
            rules={[
              {
                required: true,
                message: "Campo obrigatorio!",
              },
            ]}
          >
            <Select
              showSearch
              allowClear
              size="small"
              placeholder="País"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
              onChange={(value) =>
                setFormValues({ ...formValues, country: value })
              }
            >
              {CountryOptions}
            </Select>
          </Form.Item>
        </Col>

        <Col xs={24} sm={6}>
          <Form.Item>
            <Space>
              <Form.Item
                label={isBrasil ? "CEP" : "Código Postal"}
                name="cep"
                rules={[
                  {
                    required: true,
                    message: "Campo obrigatorio!",
                  },
                ]}
              >
                <InputMask
                  mask={isBrasil ? "99999-999" : null}
                  onChange={(e) =>
                    setFormValues({
                      ...formValues,
                      cep: e.target.value.replace(/[^\d]/g, ""),
                    })
                  }
                >
                  <Input size="small"/>
                </InputMask>
              </Form.Item>

              {isBrasil && (
                <Button
                  type="primary"
                  icon={<SearchOutlined />}
                  style={{ marginTop: "6px" }}
                  onClick={getAddress}
                />
              )}
            </Space>
          </Form.Item>
        </Col>

        <Col xs={24} sm={6}>
          {isBrasil ? (
            <Form.Item
              label="Estado"
              name="uf"
              rules={[
                {
                  required: true,
                  message: "Campo obrigatorio!",
                },
              ]}
            >
              <Select
                showSearch
                allowClear
                size="small"
                placeholder="Estado"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children
                    .toLowerCase()
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")
                    .indexOf(
                      input
                        .toLowerCase()
                        .normalize("NFD")
                        .replace(/[\u0300-\u036f]/g, "")
                    ) >= 0
                }
                onChange={(value) =>
                  setFormValues({
                    ...formValues,
                    uf: value,
                    city: "",
                  })
                }
              >
                {UFOptions}
              </Select>
            </Form.Item>
          ) : (
            <Form.Item
              label="Estado"
              name="uf"
              initialValue={formValues.uf}
              onChange={(e) =>
                setFormValues({ ...formValues, uf: e.target.value })
              }
              rules={[
                {
                  required: true,
                  message: "Campo obrigatorio!",
                },
              ]}
            >
              <Input size="small"/>
            </Form.Item>
          )}
        </Col>

        <Col xs={24} sm={6}>
          {isBrasil ? (
            <Form.Item
              label="Cidade"
              name="city"
              rules={[
                {
                  required: true,
                  message: "Campo obrigatorio!",
                },
              ]}
            >
              <Select
                showSearch
                allowClear
                size="small"
                placeholder="Cidade"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children
                    .toLowerCase()
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")
                    .indexOf(
                      input
                        .toLowerCase()
                        .normalize("NFD")
                        .replace(/[\u0300-\u036f]/g, "")
                    ) >= 0
                }
                disabled={!formValues?.uf}
                onChange={(value) =>
                  setFormValues({ ...formValues, city: value })
                }
              >
                {CityOptions || []}
              </Select>
            </Form.Item>
          ) : (
            <Form.Item
              label="Cidade"
              name="city"
              initialValue={formValues.city}
              onChange={(e) =>
                setFormValues({ ...formValues, city: e.target.value })
              }
              rules={[
                {
                  required: true,
                  message: "Campo obrigatorio!",
                },
              ]}
            >
              <Input size="small"/>
            </Form.Item>
          )}
        </Col>
      </Row>
      <Row justify="space-between" gutter={{ sm: 16, xs: 10 }}>
        <Col xs={24} sm={12}>
          <Form.Item
            label="Rua"
            name="street"
            onChange={(e) =>
              setFormValues({ ...formValues, street: e.target.value })
            }
            rules={[
              {
                required: true,
                message: "Campo obrigatorio!",
              },
            ]}
          >
            <Input size="small"/>
          </Form.Item>
        </Col>
        <Col xs={24} sm={4}>
          <Form.Item
            label="Número"
            name="street_number"
            onChange={(e) =>
              setFormValues({ ...formValues, street_number: e.target.value })
            }
          >
            <Input size="small"/>
          </Form.Item>
        </Col>
        <Col xs={24} sm={8}>
          <Form.Item
            label="Complemento"
            name="street_complement"
            onChange={(e) =>
              setFormValues({
                ...formValues,
                street_complement: e.target.value,
              })
            }
            rules={[
              {
                message: "Campo obrigatorio!",
              },
            ]}
          >
            <Input size="small"/>
          </Form.Item>
        </Col>
      </Row>
      <Row justify="space-between" gutter={{ sm: 16, xs: 10 }}>
        <Col xs={24} sm={12}>
          <Form.Item
            label="Data de Início"
            name="start_date"
            onChange={(e) =>
              setFormValues({
                ...formValues,
                start_date: e.target.value,
              })
            }
            rules={[
              {
                required: true,
                message: "Campo obrigatorio!",
              },
              {
                validator() {
                  if (
                    formValues.start_date > formValues.end_date &&
                    formValues.end_date
                  ) {
                    return Promise.reject(
                      new Error(
                        "Data de Início não pode ser maior que data de fim"
                      )
                    );
                  }
                },
              },
            ]}
          >
            <Input type="date" size="small"/>
          </Form.Item>
        </Col>
        <Col xs={24} sm={12}>
          <Form.Item
            label="Data de Saída"
            name="end_date"
            onChange={(e) =>
              setFormValues({
                ...formValues,
                end_date: e.target.value,
              })
            }
            rules={[
              {
                validator() {
                  if (
                    formValues.end_date !== "" &&
                    formValues.end_date < formValues.start_date
                  ) {
                    return Promise.reject(
                      new Error(
                        "Data de Saída não pode ser menor que data de inicio"
                      )
                    );
                  }
                },
              },
            ]}
          >
            <Input type="date" size="small"/>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default Employer;
