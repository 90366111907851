import { useLocation, useNavigate } from "react-router-dom";

import axios from "axios";
import url from "./urlMap";
import { useAuth } from "../../providers/auth";
import { useState } from "react";

export const useRest = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const http = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      Authorization: `${"Bearer " + user?.access_token}`,
    },
  });

  const [response, setResponse] = useState({
    data: null,
    message: "",
    status: { success: false, error: false, statusCode: null },
    loading: false,
  });

  const get = async (name, params) => {
    setResponse({ ...response, loading: true });
    try {
      const { data } = await http.get(url[name](params));

      setResponse({
        data: data?.data,
        status: { success: true },
        loading: false,
      });

    } catch (error) {
      if (error?.response?.status === 401) {
        navigate("/login", {
          state: { from: location },
        });
      }
      if (error?.response) {
        const { data } = error?.response;
        setResponse({
          data: data?.data,
          message: data?.message,
          status: {
            success: false,
            error: true,
            statusCode: error.response?.status,
          },
          loading: false,
        });

        return;
      }

      setResponse({
        ...response,
        status: { success: false, error: true },
        loading: false,
      });
    }
  };

  const put = async (name, id, params) => {
    setResponse({ ...response, loading: true });
    try {
      const { data } = await http.put(url[name](id), params);
      
      setResponse({
        data,
        message: data?.message,
        status: { success: true },
        loading: false,
      });
    } catch (error) {
      if (error?.response?.status === 401) {
        navigate("/login", {
          state: { from: location },
        });
      }
      if (error?.response) {
        const { data } = error?.response;
        setResponse({
          data: data?.data,
          message: data?.message,
          status: {
            success: false,
            error: true,
            statusCode: error.response?.status,
          },
          loading: false,
        });

        return;
      }

      setResponse({
        ...response,
        status: { success: false, error: true },
        loading: false,
      });
    }
  };

  const post = async (name, params) => {
    setResponse({ ...response, loading: true });
    try {
      const { data } = await http.post(url[name], params);
      setResponse({
        data: data?.data || data,
        status: { success: true },
        loading: false,
        message: data?.message,
      });
    } catch (error) {
      if (error?.response?.status === 401) {
        navigate("/login", {
          state: { from: location },
        });
      }
      if (error?.response) {
        const { data } = error?.response;
        setResponse({
          data: data?.data,
          message: data?.message,
          status: {
            success: false,
            error: true,
            statusCode: error.response?.status,
          },
          loading: false,
        });

        return;
      }

      setResponse({
        ...response,
        status: { success: false, error: true },
        loading: false,
      });
    }
  };

  const postID = async (name, id, params) => {
    setResponse({ ...response, loading: true });
    try {
      const { data } = await http.post(url[name](id), params);
      setResponse({
        data: data?.data || data,
        status: { success: true },
        loading: false,
        message: data?.message,
      });
    } catch (error) {
      if (error?.response?.status === 401) {
        navigate("/login", {
          state: { from: location },
        });
      }
      if (error?.response) {
        const { data } = error?.response;
        setResponse({
          data: data?.data,
          message: data?.message,
          status: {
            success: false,
            error: true,
            statusCode: error.response?.status,
          },
          loading: false,
        });

        return;
      }

      setResponse({
        ...response,
        status: { success: false, error: true },
        loading: false,
      });
    }
  };

  const remove = async (name, params) => {
    setResponse({ ...response, loading: true });
    try {
      const { data } = await http.delete(url[name](params));
      setResponse({
        data,
        message: data?.message,
        status: { success: true },
        loading: false,
      });
    } catch (error) {
      if (error?.response?.status === 401) {
        navigate("/login", {
          state: { from: location },
        });
      }
      if (error?.response) {
        const { data } = error?.response;
        setResponse({
          data: data?.data,
          message: data?.message,
          status: {
            success: false,
            error: true,
            statusCode: error.response?.status,
          },
          loading: false,
        });

        return;
      }

      setResponse({
        ...response,
        status: { success: false, error: true },
        loading: false,
      });
    }
  };

  return {
    post,
    remove,
    get,
    put,
    postID,
    data: response?.data,
    status: response?.status,
    loading: response?.loading,
    message: response?.message,
  };
};
