export const Permissions = {
  visualizar_responsaveis: "Visualizar Responsáveis",
  cadastrar_responsaveis: "Cadastrar Responsáveis",
  remover_responsaveis: "Remover Responsáveis",

  visualizar_clientes: "Visualizar Clientes",
  cadastrar_clientes: "Cadastrar Clientes",
  remover_clientes: "Remover Clientes",

  visualizar_funcionarios: "Visualizar Funcionários",
  cadastrar_funcionarios: "Cadastrar Funcionários",
  remover_funcionarios: "Remover Funcionários",

  visualizar_instrucoes: "Visualizar Instruções",
  cadastrar_instrucoes: "Cadastrar Instruções",
  remover_instrucoes: "Remover Instruções",

  visualizar_servicos: "Visualizar Serviços",
  cadastrar_servicos: "Cadastrar Serviços",
  remover_servicos: "Remover Serviços",

  visualizar_fluxos: "Visualizar Fluxos",
  cadastrar_fluxos: "Cadastrar Fluxos",
  remover_fluxos: "Remover Fluxos",

  visualizar_perfis: "Visualizar Perfis",
  cadastrar_perfis: "Cadastrar Perfis",
  remover_perfis: "Remover Perfis",

  visualizar_interacoes: "Visualizar Interações",
  cadastrar_interacoes: "Cadastrar Interações",
  remover_interacoes: "Remover Interações",

  visualizar_prospeccoes: "Visualizar Prospecções",
  cadastrar_prospeccoes: "Cadastrar Prospecções",
  remover_prospeccoes: "Remover Prospecções",

  visualizar_dashboard: "Visualizar Dashboard",
  general_flow_report: "Relatório Geral de Fluxos",
  unread_interactions: "Relatório Interações não lidas",

  cadastrar_clube_vantagens: "Cadastrar Clube de Vantagens ",
  visualizar_clube_vantagens: "Visualizar Clube de Vantagens",
  
  cadastrar_clube_vantagens_caterogia: "Cadastrar Categoria de Clube de Vantagens",
  visualizar_clube_vantagens_caterogia: "Visualizar Categoria de Clube de Vantagens",

  visualizar_interacoes_cliente: "Visualizar Interações Cliente",
  cadastrar_interacoes_cliente: "Cadastrar Interações Cliente",
  remover_interacoes_cliente: "Remover Interações Cliente",
};

export const getRoutesPermissions = (route) => {
  switch (route) {
    case "responsable-list":
      return [Permissions.visualizar_responsaveis];
    case "create-responsable":
    case "view-responsable":
      return [Permissions.cadastrar_responsaveis];
    case "client-list":
    case "show-client":
      return [Permissions.visualizar_clientes];
    case "view-client":
    case "create-client":
      return [Permissions.cadastrar_clientes];
    case "employee-list":
      return [Permissions.visualizar_funcionarios];
    case "create-employee":
    case "view-employee":
      return [Permissions.cadastrar_funcionarios];
    case "role-list":
      return [Permissions.visualizar_perfis];
    case "create-role":
    case "view-role":
      return [Permissions.cadastrar_perfis];
    case "service-list":
      return [Permissions.visualizar_servicos];
    case "create-service":
    case "view-service":
      return [Permissions.cadastrar_servicos];
    case "instructions-list":
      return [Permissions.visualizar_instrucoes];
    case "create-instruction":
    case "view-instruction":
      return [Permissions.cadastrar_instrucoes];
    case "prospect-list":
      return [Permissions.visualizar_prospeccoes];
    case "prospect-client":
      return [Permissions.cadastrar_prospeccoes];
    case "flow-list":
      return [Permissions.visualizar_fluxos];
    case "create-flow":
      return [Permissions.cadastrar_fluxos];
    case "interaction":
      return [Permissions.visualizar_fluxos];
    case "interaction-customer":
        return [Permissions.visualizar_interacoes_cliente];
    case "dashboard":
      return [Permissions.visualizar_dashboard];
    case "general-flow-report":
      return [Permissions.general_flow_report];
    case "unread-interactions-report":
      return [Permissions.unread_interactions];
    case "create-advantage-club":
      return [Permissions.cadastrar_clube_vantagens];
    case "advantage-club-list":
      return [Permissions.visualizar_clube_vantagens];
    case "create-club-advantage-category":
      return [Permissions.cadastrar_clube_vantagens_caterogia];
    case "advantage-club-category-list":
      return [Permissions.visualizar_clube_vantagens_caterogia];
    case "view-advantage-club-category":
      return [Permissions.cadastrar_clube_vantagens_caterogia];


    default:
      return [];
  }
};

export const hasPermission = (user, permission) =>
  user?.permissions.includes(permission);
