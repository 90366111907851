/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import Wrapper from "../../components/layout";
import { Form, Input, Button, Checkbox, message, Card } from "antd";
import styled from "./styled.module.scss";
import { useRest } from "../../services/http";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";

const CreateInstruction = () => {
  const [form] = Form.useForm();
  const { post: createInstruction, status, loading, data } = useRest();
  const [formValues, setFormValues] = useState({ enable: true });

  const submit = () => {
    createInstruction("createInstruction", formValues);
  };
  useEffect(() => {
    if (status?.success) {
      message.success("Instrução criada com sucesso!");
      form.resetFields();
      setFormValues({ enable: true });
    }
    if (status?.error) {
      data
        ? message.error("Preencha todos os campos corretamente!")
        : message.error("Ocorreu um erro na criação da instrução!");
    }
  }, [status, form]);

  return (
    <Wrapper>
      <Card>
        <Form form={form} layout="vertical">
          <Form.Item
            name="titulo"
            label="Titulo da instrução"
            rules={[
              {
                required: true,
                message: "Campo obrigatório!",
              },
            ]}
          >
            <Input
              placeholder="Titulo da instrução"
              showCount
              size="small"
              maxLength={50}
              name="title"
              label="title"
              onChange={(e) =>
                setFormValues({
                  ...formValues,
                  title: e.target.value,
                })
              }
            />
          </Form.Item>
          <Form.Item>
            <Checkbox
              checked={formValues?.enable}
              onChange={(e) =>
                setFormValues({
                  ...formValues,
                  enable: e.target.checked,
                })
              }
            >
              Instrução ativa?
            </Checkbox>
          </Form.Item>
          <Form.Item
            name="descricao"
            label="Descrição da instrução"
            rules={[
              {
                required: true,
                message: "Campo obrigatório!",
              },
            ]}
          >
            <CKEditor
              editor={Editor}
              value={formValues?.description}
              onChange={(events, editor) => {
                const data = editor.getData();
                setFormValues({
                  ...formValues,
                  description: data,
                });
              }}
            />
          </Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className={styled.createButton}
            onClick={submit}
            loading={loading}
            disabled={!formValues?.title || !formValues?.description}
          >
            Criar instrução
          </Button>
        </Form>
      </Card>
    </Wrapper>
  );
};

export default CreateInstruction;
