import {
  Alert,
  Button,
  Card,
  Col,
  Form,
  Row,
  Select,
  Space,
  message,
} from "antd";
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";

import USStates from "../../util/USStates";
import Wrapper from "../../components/layout";
import styled from "./styled.module.scss";
import { useRest } from "../../services/http";

const CreateFlux = () => {
  const [form] = Form.useForm();
  const [optionsClient, setOptionsClient] = useState([]);
  const [optionsResponsable, setOptionsResponsable] = useState([]);
  const [optionsService, setOptionsService] = useState([]);
  const [formState, setFormState] = useState({
    customer_id: null,
    assignee_id: null,
    service_id: null,
    state_bon: null,
  });

  const {
    get: fullClientList,
    loading: fullClientListLoading,
    data: fullClientListData,
  } = useRest();
  const {
    get: fullResponsableList,
    data: fullDataResponsableList,
    loading: responsableListLoading,
  } = useRest();
  const {
    get: fullServiceList,
    data: fullServiceListData,
    loading: fullServiceListLoading,
  } = useRest();
  const {
    post: createFlow,
    loading: createFlowLoading,
    data: createFlowData,
    status: createFlowStatus,
    message: createFlowMessage,
  } = useRest();

  const submit = () => {
    // Garantir que os campos obrigatórios estejam preenchidos
    if (!formState.customer_id || !formState.service_id || !formState.state_bon) {
      message.error("Por favor, preencha todos os campos obrigatórios.");
      return;
    }

    console.log("Enviando dados para a API:", formState); // Log para verificar os dados
    
    // Enviar os dados para a API
    createFlow("createFlow", formState).catch((error) => {
      console.error("Erro ao enviar dados para a API:", error);
    }
  )
};

  useEffect(() => {
    fullClientList("fullClientList", { name: "" });
    fullResponsableList("fullResponsableList");
    fullServiceList("fullServiceList");
  }, []);

  useEffect(() => {
    const clientArr = fullClientListData?.map((item) => (
      <Select.Option key={item.id}>{item.user.name}</Select.Option>
    ));
    setOptionsClient(clientArr);
  }, [fullClientListData]);

  useEffect(() => {
    const responsableArr = fullDataResponsableList?.map((item) => (
      <Select.Option key={item.id}>{item.description}</Select.Option>
    ));
    setOptionsResponsable(responsableArr);
  }, [fullDataResponsableList]);

  useEffect(() => {
    const serviceArr = fullServiceListData?.map((item) => (
      <Select.Option key={item.id}>{item.title}</Select.Option>
    ));
    setOptionsService(serviceArr);
  }, [fullServiceListData]);

  useEffect(() => {
    if (createFlowStatus?.success) {
      message.success(createFlowMessage);
      form.resetFields();
      setFormState({
        customer_id: null,
        assignee_id: null,
        service_id: null,
        state_bon: null,
      });
    }
  }, [createFlowStatus, form, createFlowMessage, createFlowData]);

  return (
    <Wrapper>
      <Card>
        <Form form={form} layout="vertical">
          {createFlowData?.length < 1 && createFlowStatus !== 201 && (
            <Alert
              message="Dados incorretos:"
              description={createFlowMessage}
              type="error"
            />
          )}
          <br />
          <Row justify="space-between" gutter={{ sm: 16, xs: 10 }}>
            <Col xs={24} sm={12} flex="center">
              <Form.Item
                label="Cliente"
                name="client"
                rules={[
                  {
                    required: true,
                    message: "Campo obrigatório!",
                  },
                ]}
              >
                <Select
                  size="small"
                  showSearch
                  allowClear
                  placeholder="Selecionar Cliente"
                  optionFilterProp="children"
                  disabled={fullClientListLoading}
                  onChange={(value) =>
                    setFormState({ ...formState, customer_id: value })
                  }
                  loading={fullClientListLoading}
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                >
                  {optionsClient}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} flex="center">
              <Form.Item
                label="Responsável"
                name="responsavel"
              >
            <Select
            size="small"
            showSearch
            allowClear
            placeholder="Selecionar responsável"
            optionFilterProp="children"
            disabled={responsableListLoading}
            onChange={(value) =>
              setFormState({ ...formState, assignee_id: value || null })
            }
            loading={responsableListLoading}
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
          >
            {optionsResponsable}
          </Select>
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            label="Serviços"
            name="service"
            rules={[
              {
                required: true,
                message: "Campo obrigatório!",
              },
            ]}
          >
            <Select
              size="small"
              showSearch
              allowClear
              placeholder="Selecionar Serviços"
              optionFilterProp="children"
              disabled={fullServiceListLoading}
              onChange={(value) =>
                setFormState({ ...formState, service_id: value })
              }
              loading={fullServiceListLoading}
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
            >
              {optionsService}
            </Select>
          </Form.Item>
          <Form.Item
            label="Board of Nursing"
            name="state_bon"
            rules={[
              {
                required: true,
                message: "Campo obrigatório!",
              },
            ]}
          >
            <Select
              size="small"
              showSearch
              allowClear
              placeholder="Selecionar Estado"
              optionFilterProp="children"
              onChange={(value) =>
                setFormState({ ...formState, state_bon: value })
              }
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
            >
              {USStates.map((state) => (
                <Select.Option key={state.key} value={state.key}>
                  {state.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Space
            size={"middle"}
            align="center"
            className={styled.buttonWrapper}
          >
            <Button
              type="primary"
              className="hp-btn-outline hp-hover-bg-primary-1"
              htmlType="submit"
              onClick={submit}
              loading={createFlowLoading}
            >
              Criar fluxo
            </Button>
          </Space>
        </Form>
      </Card>
    </Wrapper>
  );
};

export default CreateFlux;
