import { Col, Input, Modal, Popconfirm, Row, Space, Spin, message } from "antd";
import { Permissions, hasPermission } from "../../router/permissions";
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";

import { ref } from "yup";
import styled from "./styled.module.scss";
import { useAuth } from "../../providers/auth";
import { useParams } from "react-router-dom";
import { useRest } from "../../services/http";

const Attachments = ({ update, onUpdate }) => {
  const { user } = useAuth();
  const { id } = useParams();
  const [attachments, setAttachments] = useState({});
  const [isChanging, setIsChanging] = useState(null);
  const [newName, setNewName] = useState("");

  const {
    get: getAttachments,
    loading: loadingAttachments,
    status: statusGetAttachments,
    data: dataAttachments,
  } = useRest();

  const isPdfMimeType = (mimeType) => {
    return mimeType === "application/pdf";
  };

  const {
    put: renameAttachment,
    remove: deleteAttachment,
    status: statusAttachment,
    message: messageAttachment,
  } = useRest();

  useEffect(() => {
    getAttachments("getAttachments", { id });
  }, [update]);

  const viewPdf = async (filePath) => {
    const data = await fetch(
      `${process.env.REACT_APP_API_URL}${filePath}`,
      {
        headers: {
          Authorization: `${"Bearer " + user?.access_token}`,
        },
      }
    );

    const response = await data.blob();

    const file = new Blob([response], { type: "application/pdf" });

    const fileURL = URL.createObjectURL(file);

    window.open(fileURL);
  };

  useEffect(() => {
    if (statusGetAttachments.success) {
      setAttachments(dataAttachments);
    } else if (statusGetAttachments.error) {
      message.error("Ocorreu um erro ao buscar os anexos. Tente novamente!");
    }
  }, [statusGetAttachments]);

  useEffect(() => {
    if (statusAttachment.success) {
      getAttachments("getAttachments", { id });
      setIsChanging(null);
    } else if (statusAttachment.error) {
      message.error(messageAttachment);
    }
  }, [statusAttachment]);

  const removeAttachment = async (id) => {
    await deleteAttachment("deleteAttachment", { id });
    await getAttachments("getAttachments", { id });
    await onUpdate(); // Isso deve chamar a função passada como prop
  };

  const downloadFile = async (filePath) => {
    const data = await fetch(
      `${process.env.REACT_APP_API_URL}${filePath}`,
      {
        headers: {
          Authorization: `${"Bearer " + user?.access_token}`,
        },
      }
    );
    const response = await data.blob();
    const urlCreator = window.URL || window.webkitURL;
    const imageUrl = urlCreator.createObjectURL(response);

    const a = document.createElement("a");
    a.href = imageUrl;
    a.download = filePath.replace("attachment/", "");
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const changeName = async (id, name) => {
    try {
      // Ensure the id is not undefined
      if (!id || !name) {
        console.error("Invalid parameters: ID or name is missing.");
        return;
      }
  
      // Make the PUT request to rename the attachment
      await renameAttachment("renameAttachment", id, { alias: name });

      message.success("Nome do anexo alterado com sucesso!");
      await getAttachments("getAttachments", { id }); // Recarregar anexos após renomear
      setIsChanging(null); // Sair do modo de edição
      onUpdate(); // Chamar o método para atualizar todos os componentes relevantes
    } catch (error) {
      onUpdate(); 
      message.error("Ocorreu um erro ao renomear o anexo. Tente novamente!");
    }
  };

  return (
    <>
      <h5 className="hp-mt-sm-16 hp-mt-16">Anexos</h5>

      {loadingAttachments ? (
        <Row type="flex" align="middle" justify="center">
          <Col>
            <Spin />
          </Col>
        </Row>
      ) : attachments.length > 0 ? (
        attachments?.map((item) => (
          <div
            style={{
              display: "flex",
              marginLeft: "auto",
              marginRight: "0px",
              maxWidth: "100%",
              marginBottom: "0px",
            }}
          >
            {isChanging !== item.id && (
              <>
                {item.extension === "pdf" ? (
                  <a onClick={() => viewPdf(item.path)} title={item.name}>
                    {item.alias ? item.alias : item.name.substring(0, 25)}
                  </a>
                ) : (
                  <a
                    href="#"
                    onClick={() => downloadFile(item.path)}
                    title={item.name}
                  >
                    {item.alias ? item.alias : item.name.substring(0, 25)}
                  </a>
                )}
              </>
            )}
            <div
              style={{
                display: "flex",
                marginLeft: "auto",
                marginRight: "0px",
                maxWidth: "100%",
                marginBottom: "0px",
              }}
            >
              {isChanging === item.id && user.isEmployee && (
                <>
                  <Space>
                    <Input
                      placeholder={item.name}
                      onChange={(e) => setNewName(e.target.value)}
                    />
                    <a
                      className={styled.icon}
                      onClick={() => changeName(item.id, newName)}
                    >
                      <i
                        className="ri-check-fill"
                        title="Editar nome do anexo"
                      />
                    </a>
                  </Space>
                </>
              )}
              {hasPermission(user, Permissions.cadastrar_interacoes) &&
                user.isEmployee &&
                isChanging !== item.id && (
                  <>
                    {item.extension === "pdf" && (
                      <a
                        className={styled.icon}
                        onClick={() => viewPdf(item.path)}
                        style={{ marginLeft: "15px" }}
                      >
                        <i className="ri-eye-fill" title="Visualizar pdf" />
                      </a>
                    )}

                    <a
                      className={styled.icon}
                      onClick={() => setIsChanging(item.id)}
                    >
                      <i
                        className="ri-edit-fill"
                        title="Editar nome do anexo"
                      />
                    </a>
                  </>
                )}

              {hasPermission(user, Permissions.remover_interacoes) &&
                user.isEmployee && (
                  <>
                    <Popconfirm
                      title={
                        "Tem certeza que deseja deletar o anexo: " + item.name
                      }
                      onConfirm={() => removeAttachment(item.id)}
                      okText="Confirmar"
                      cancelText="Cancelar"
                    >
                      <a className={styled.icon}>
                        <i
                          className={styled.iconDelete + " ri-delete-bin-fill"}
                          title="Deletar anexo"
                        />
                      </a>
                    </Popconfirm>
                    <br />
                    <br />
                  </>
                )}
            </div>
          </div>
        ))
      ) : (
        <p>Não existem anexos</p>
      )}
    </>
  );
};

export default Attachments;
